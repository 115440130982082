import { now, uniqueId } from "lodash";
import { BehaviorSubject } from "rxjs";
import { CalendarEvent } from "./CalendarEvent";
import { EventColor } from "./EventColor";
import { colors } from "./colors";
import { Exercise } from "./Exercise";

export const date2string = (date: Date) => {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};
export const string2date = (str: string) => {
    const [day, month, year] = str.split('.').map((p) => parseInt(p));
    return new Date(year, month - 1, day, 0, 0, 0, 0);
};

class ProgramExercise0 {
    program_id: string
    exercise_id: string
    date: Date
    performed: boolean
    period: number
    color: EventColor
    display_name?: string
    editing_mode: boolean
    constructor(info: {
        program_id: string
        exercise_id: string
        date: Date
        performed: boolean
        editing_mode: boolean
        period: number
        color: EventColor
        display_name?: string
    }) {
        this.program_id = info.program_id
        this.exercise_id = info.exercise_id
        this.date = info.date
        this.performed = info.performed
        this.period = info.period
        this.color = info.color
        this.display_name = info.display_name
        this.editing_mode = info.editing_mode || false
    }
}
export class ProgramManager {
    deleteProgram(pid: string) {
        this.events = this.events.filter(e=>e.meta?.program_id!=pid)
        this.events$.next([...this.events])
        this.all_programs_exercises = this.all_programs_exercises.filter(e => {
            return e.program_id != pid
        })
    }
    autoSelect(dates: Date[]) {
        for (let i = 0; i < dates.length; i++) {
            const d = dates[i]
            d.setHours(0, 0, 0, 0)
            for (let j = 0; j < this.selected_program_exercises_list.length; j++) {

                const pe = this.selected_program_exercises_list[j]
                this.addExercise({ ...pe,program_id:this.selected_program_id }, d)
                
            }
        }
        this.events$.next([...this.events])
        

    }
    getExercisesList() {
        const list = this.all_programs_exercises.filter(e => {
            return e.program_id == this.selected_program_id
        })
        let obj: any = list.reduce((p, c, i) => {
            p[c.exercise_id] = p[c.exercise_id] || { ...c }
            p[c.exercise_id].assigned_dates = p[c.exercise_id].assigned_dates || []
            p[c.exercise_id].assigned_dates.push({ date: date2string(c.date), performed: c.performed, period: c.period })

            return p
        }, {})
        return Object.values(obj).map((v: any) => {
            return {
                program_id: this.selected_program_id,
                exercise_id: v.exercise_id,
                assigned_dates: v.assigned_dates,
                sets: v.sets,
                set_break: v.set_break,
                repetitions: v.repetitions,
                actions: v.actions,
                initial_pose: v.initial_pose,
            }
        })
    }
    private language: string = 'en'
    private all_programs_exercises: ProgramExercise0[] = [];
    private events: CalendarEvent[] = []
    events$: BehaviorSubject<CalendarEvent[]> = new BehaviorSubject([])
    selected_program_id: string;
    constructor() { }
    private _createEvent(data: any): CalendarEvent {
        const evt = {
            id: uniqueId('evt'),
            title: data.title,
            start: data.date,
            allDay: true,
            color: data.color,
            meta: {
                performed: data.performed,
                exercise_id: data.exercise_id,
                program_id: data.program_id,
                period: data.period
            },
        };
        return evt;
    }
    private selected_program_exercises_list: ProgramExercise0[] = []
    initProgram(list: ProgramExercise0[]) {
        if (this.selected_program_exercises_list?.length > 0 && this.selected_program_exercises_list[0].program_id == list[0].program_id
            && this.selected_program_exercises_list[0].exercise_id == list[0].exercise_id
        ) {
            //when already there then just return
            return
        }
        this.selected_program_exercises_list = list
    }
    getEvents() {
        return this.events.filter(e => {
            return e.meta?.program_id == this.selected_program_id
        })
    }
    toggleProgramDateEvents(date: Date) {
        const clickedDateItems = this.all_programs_exercises.filter(e => {
            return e.date.getTime() == date.getTime() && e.program_id == this.selected_program_id
        })
        if (clickedDateItems?.length > 0) {
            this.all_programs_exercises = this.all_programs_exercises.filter(e => {
                return !(e.date.getTime() == date.getTime() && e.program_id == this.selected_program_id)
            })
            this.events = this.events.filter(e => {
                return e.start.getTime() != date.getTime() || e.meta?.program_id != this.selected_program_id
            })
        } else {
            this.selected_program_exercises_list.forEach(pe => {
                this.addExercise({ ...pe }, date)
            })
        }
        this.events$.next([...this.events])
    }
    private _addEvent(evt: CalendarEvent) {
        const index = this.events.findIndex(e => {
            return e.start.getTime() === evt.start.getTime() && e.meta?.period == evt.meta?.period &&
                e.meta?.exercise_id == evt.meta?.exercise_id
        })
        if (index > -1) return

        this.events.push(evt)
    }

    private _addExercise(pe: ProgramExercise0) {
        const index = this.all_programs_exercises.findIndex(e => {
            return e.date.getTime() == pe.date.getTime() && e.exercise_id == pe.exercise_id && e.period == pe.period
        })
        if (index > -1) return
        this.all_programs_exercises.push(pe)
    }
    addExercise(ex: ProgramExercise0, date?: Date) {
        const now = new Date()
        now.setHours(0, 0, 0, 0)

        if (!date) {
            let future_dates: Date[] = []
            this.all_programs_exercises.forEach(e => {
                if (e.program_id == this.selected_program_id && e.date.getTime() >= now.getTime()) {
                    future_dates.push(e.date)
                }
            })
            future_dates.forEach(fdate => {
                fdate.setHours(0, 0, 0, 0)
                ex.date = fdate
                this._addExercise(ex)
                const title = ex?.display_name?.[this.language] ?? ex?.display_name?.['en'];
                const evt = this._createEvent({
                    title,
                    date: fdate, color: ex.color, program_id: this.selected_program_id, exercise_id: ex.exercise_id, period: ex.period
                })
                this._addEvent(evt)
            })
        } else {
            date.setHours(0, 0, 0, 0)
            ex.date = date
            this._addExercise(ex)
            const title = ex?.display_name?.[this.language] ?? ex?.display_name?.['en'];
            const evt = this._createEvent({
                title,
                date: date, color: ex.color, program_id: this.selected_program_id, exercise_id: ex.exercise_id, period: ex.period
            })
            this._addEvent(evt)
        }
    }
    setPrograms(programsData: any[]) {
        this.events = []
        this.all_programs_exercises = []
        const currentTime = new Date();
        currentTime.setHours(0, 0, 0, 0);
        programsData.forEach(p => {
            const program_id = p.program_id
            p.exercises.forEach(ex => {
                const exercise_id = ex.exercise_id
                const title = ex?.display_name?.[this.language] ?? ex?.display_name?.['en'];
                ex.assigned_dates.forEach(ad => {
                    let color: EventColor = colors.yellow
                    let period = ad.period
                    let eventDate = string2date(ad.date.toString())
                    eventDate.setHours(0, 0, 0, 0)
                    if (eventDate <= currentTime) {
                        if (ad.performed) {
                            color = colors.green;
                        } else {
                            if (eventDate < currentTime) {
                                color = colors.red;
                            }
                        }
                    }
                    const pe = new ProgramExercise0({ editing_mode: false, color, date: eventDate, exercise_id: ex.exercise_id, performed: ad.performed, period, program_id, display_name: ex.display_name })
                    this._addExercise(pe)
                    const evt = this._createEvent({
                        title,
                        date: eventDate, color, program_id, exercise_id, period
                    })
                    this._addEvent(evt)
                })
            })
        })
        this.events$.next([...this.events])
    }
    startProgramEditing() {
        const now = new Date()

        this.all_programs_exercises = this.all_programs_exercises.map(e => {
            if (e.program_id == this.selected_program_id) {
                e.editing_mode = true
                if (e.date >= now && !e.performed) {
                    e.color = colors.blue
                }
            }
            return { ...e }
        })
    }
    endProgramEditing() {
        const now = new Date()

        this.all_programs_exercises = this.all_programs_exercises.map(e => {
            if (e.program_id == this.selected_program_id) {
                e.editing_mode = false
                if (e.date >= now && !e.performed) {
                    e.color = colors.yellow
                }
            }
            return { ...e }
        })
    }
    setSelectedProgram(program_id: string) {
        const now = new Date()
        now.setHours(0, 0, 0, 0)

        this.selected_program_id = program_id
        this.selected_program_exercises_list = this.all_programs_exercises.filter(e => {
            return e.program_id == program_id && e.date.getTime() == now.getTime()
        })

    }
}