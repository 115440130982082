

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-form-builder',
  templateUrl: './custom-form-builder.component.html',
  styleUrls: ['./custom-form-builder.component.css'],
})
export class CustomFormBuilderComponent implements OnInit, OnChanges {
  @Input('options') options: any[];
  @Input('inprogress') inprogress: boolean;
  @Input('forUpdate') forUpdate: boolean;
  @Input('forCreate') forCreate: boolean;
  @Input('activeCardIndex') activeCardIndex: number;
  @Input('totalCards') totalCards: number;
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onChangeCard = new EventEmitter<'next' | 'previous'>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();

  defaultObject: any = {}
  remoteValues: any = {}
  constructor() { }
  updatedVersion: any = {};
  scrollToTop() {
    window.scroll({ top: 0, behavior: 'smooth' }); // Add 'smooth' for smooth scrolling
  }
  prepaireDefaultObject() {

    this.defaultObject = {}
    this.options.forEach(row => {
      row.forEach(field => {
        this.defaultObject[field.name] = { type: field.type, validator: field.validator, name: field.name, value: field.value, required: field.required, defaultValue: field.defaultValue }
        this.remoteValues[field.name] = this.remoteValues[field.name] ?? { ...this.defaultObject[field.name] };
      })
    })

  }
  ngOnChanges() {
    this.scrollToTop()
    this.prepaireDefaultObject()
  }


  ngOnInit(): void {
    this.prepaireDefaultObject()
    this.updates = {}

  }
  updates: any = {}
  nestedObject: any = {};


  trackField(index, field) {

  }
  onFieldChange(event, field) {

  }
  onSelectedChange(el) {

  }
  doSubmit() {

    if (this._validateForm()) {
      this.onSubmit.emit(this.updatedVersion);

    } else {
      this.scrollToInvalid()
    }
  }
  showErrors: boolean = false
  _validateForm(): boolean {
    if (this.forCreate) {
      return this.valid()
    }
    if (this.forUpdate) {
      let isValid = true
      for (const key of Object.keys(this.defaultObject)) {
        const field = this.defaultObject[key];
        const updatedField = this.updatedVersion[field.name]
        if (field.type == 'checkbox') {
          field.valid = true
          continue;
        }
        if (field.required && typeof updatedField != 'undefined') {
          if (!this.isEmptyOrNull(updatedField)) {

            if (field.validator) {
              field.valid = field.validator(updatedField)
              if (!field.valid) {
                isValid = false
              }
            } else {
              field.valid = true
            }
          } else {
            isValid = false
          }
        }
      }
      this.showErrors = !isValid
      return isValid;
    }
  }
  valid(): boolean {
    let isValid = true
    for (const key of Object.keys(this.defaultObject)) {
      const field = this.defaultObject[key];
      const updatedField = this.updatedVersion[field.name]
      if (field.required) {
        if (!this.isEmptyOrNull(updatedField)) {

          if (field.validator) {
            field.valid = field.validator(updatedField)
            if (!field.valid) {
              isValid = false
            }
          } else {
            field.valid = true;
            field.valid = true
          }
        } else {
          field.valid = false;
          isValid = false
        }
      }
    }
    this.showErrors = !isValid
    if (!isValid) {
      this.scrollToInvalid()
    }
    return isValid;
  }

  scrollToInvalid() {
    if (this.forCreate) {
      const fields = document.getElementsByClassName('form-field');
      let firstInvalidField = null;
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        for (const key of Object.keys(this.defaultObject)) {
          const fieldData = this.defaultObject[key];
          if (key === field.id && !fieldData.valid) {
            firstInvalidField = field;
            break;
          }
        }
        if (firstInvalidField) {
          break;
        }
      }
      if (!firstInvalidField) {
        this.scrollToTop();
      }
      firstInvalidField.scrollIntoView({ behavior: 'smooth' });
    }
    if (this.forUpdate) {
      this.scrollToTop()
    }
  }

  changeCard(step: 'next' | 'previous' = 'next') {
    if (step == 'next') {
      if (this._validateForm())
        this.onChangeCard.emit(step)
    } else {
      this.onChangeCard.emit(step)
    }

  }
  doCancel() {
    this.onCancel.emit();
  }
  shouldBeDisabled() {
    const somethingChanged = Object.keys(this.updates).length > 0 && Object.values(this.updates).some(v => v == true) == true
    if (this.forUpdate)
      return !somethingChanged

    return false;
  }

  selectedValueChanged(questionId, value) {
    this.updatedVersion[questionId] = value
    this._notifyParent(questionId)
  }
  checkboxValueChanged(questionId, options, field) {
    this.updatedVersion[questionId] = options.map(o => {
      return { name: o.name, value: o.value }
    })
    field.selectedItemsCount = this.updatedVersion[questionId]?.length ?? 0
    this._notifyParent(questionId)
  }
  textValueChanged(questionId, value, f) {
    const val = value.toString().trim();
    this.updatedVersion[questionId] = val
    const field = this.defaultObject[questionId];

    if (field.validator && val) {
      f.valid = field.validator(value)
    } else {
      f.valid = val.length > 0
    }
    this._notifyParent(questionId)
  }
  isEmptyOrNull(val: string | number) {
    if (typeof val === 'number' && val != undefined && val != null) {
      return false;
    }
    try {
      if (!val) return true;
      return val.toString()?.trim()?.length == 0
    } catch (error) {
      return true
    }
  }
  numberValueChanged(questionId, value) {
    this.updatedVersion[questionId] = value
    this._notifyParent(questionId)
  }
  dateValueChanged(questionId, val, f) {
    this.updatedVersion[questionId] = val

    const field = this.defaultObject[questionId];

    if (field.validator && val) {
      f.valid = field.validator(val)
    } else {
      f.valid = val.length > 0
    }
    this._notifyParent(questionId)

  }
  radioValueChanged(questionId, value, field, opt) {
    this.updatedVersion[questionId] = value
    this._notifyParent(questionId)
    for (const o of field.options) {
      if (o.child) {
        for (let i = o.child?.questionIndex; i < o.child?.questionIndex + o.child?.linkedQuestionsCount; i++) {
          this.options[i][0].show = false
        }
      }
    }
    if (typeof opt.child != 'undefined') {
      if (opt.child) {
        for (let i = opt.child?.questionIndex; i < opt.child?.questionIndex + opt.child?.linkedQuestionsCount; i++) {
          this.options[i][0].show = opt.child.linkedValue == value;
          //console.log('this.options[i][0].show = ',i,this.options[i][0].show)
        }
      }

    }
  }
  _notifyParent(qid: string) {
    this.onChange.emit(this.updatedVersion)
    this._checkForChanges(qid)
  }
  _checkForChanges(qid: string) {
    if (this.defaultObject[qid].type == 'checkbox') {
      this.updates[qid] = JSON.stringify(this.updatedVersion[qid]) != JSON.stringify(this.remoteValues[qid].value)

      return;
    }
    this.updates[qid] = (this.updatedVersion[qid] != this.remoteValues[qid].value)


  }
}
