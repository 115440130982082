import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';

import { GeneralService } from '@services/general/general.service';
import { Pm2Service } from '@services/program-manager/pm2.service';

import { ToastrService } from 'ngx-toastr';
import { ExerciseService } from '../../../services/exercise/exercise.service';
type TabOptions = 0 | 1 | 2;
@Component({
  selector: 'app-create-exercise-program',
  templateUrl: './create-exercise-program.component.html',
  styleUrls: ['./create-exercise-program.component.css'],
})
export class CreateExerciseProgramComponent implements OnInit {
  @Input() user: any;
  @Input() patientUid: string;
  @Input() assignedExercises: any[] = [];
  @Output() closeProgramEvent = new EventEmitter<string>();
  countAfterFilter = (period: number) => this.chosenExercises.filter(e => e.periods[period]).length

  public allExercises: any[];
  public allTemplates: any[];
  public dataLoaded: boolean = false;
  public copiedDataLoaded: boolean = false;
  public templatesLoaded: boolean = false;

  public exerciseSelection: boolean = true;
  public programCreation: boolean = false;
  public allChosenExercises = [];
  public chosenExercises: any[] = []

  public tempChosenExercisesInExercises = [];
  public tempChosenExercisesInTemplates = [];
  public tempChosenTemplates = [];
  tabs = [
    { label: "createExerciseProgram.morning" },
    { label: "createExerciseProgram.afternoon" },
    { label: "createExerciseProgram.evening" },
  ];
  public exerciseFilterOptions = [
    'none',
    'breathing',
    'lower_body',
    'stretch',
    'sit',
    'stand',
    'supine',
  ];
  public exerciseFilterSelected: string = 'none';
  public searchExerciseText: string = '';
  public programCreated: boolean = false;

  // modal for new template name
  public isModalOpen = false;
  public newTemplateName = '';

  // tabs
  selectedTab: TabOptions = 0;

  // templates
  public templatesTab: boolean = false;
  public exercisesTab: boolean = false;

  public templatesOpen: boolean = true;
  public templateSelected: boolean = false;
  public programTemplates: any[] = [];
  public extendedTemplates: any[] = [];
  public searchExerciseTemplate: string = '';
  public templateDeletionIndex: number = -1;

  // pagination
  public exercisePage: number = 1;
  public exercisePageSize: number = 3;
  public chosenExercisesPage: number = 1;
  public chosenExercisesPageSize: number = 5;
  public templatePage: number = 1;
  public templatePageSize: number = 5;

  model: NgbDateStruct;
  date: { year: number; month: number };
  public selectedDates: NgbDateStruct[] = [];
  public calendarComponent: any;
  public language: string;
  dataArr: string[] = [];
  public newAddedEvents: any[] = [];
  public gifUrls: any[] = [];
  selected_template_available_exercises: any[];
  constructor(
    private exerciseService: ExerciseService,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,

    private generalService: GeneralService,
    private pm: Pm2Service,
    private ngZone: NgZone
  ) {
    this.language = this.translate.currentLang;
    this.user = this.auth.getAuthenticatedUser();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
    });
    this.route.data.subscribe(({ data }) => {

      //this.processAssignedExercises(data.allExercisePrograms);
      this.pm.setPrograms(data.allExercisePrograms);

      this.allExercises = data.allExercises;
      this.allExercises.forEach((ex) => {
        ex.ai_enabled = true;
      });
      this.programTemplates = data.programTemplates;
      this.extendedTemplates = structuredClone(data.programTemplates).reverse();
      this.pm.setSelectedProgram("new")
    });
  }
  // processAssignedExercises(assignedExercises: any[]) {
  //   for (const program of assignedExercises) {
  //     if (program?.exercises && !program?.deleted) {
  //       for (const exercise of program.exercises) {
  //         buildCalendarEvents(
  //           exercise,
  //           this.language,
  //           new Date(),
  //           [],
  //           this.assignedExercises,
  //           [],
  //           []
  //         );
  //         //addAlldates(exercise.assigned_dates, this.allExercisesDates, this.todayExercises, this.translate);
  //       }
  //     }
  //   }
  // }
  ngOnInit(): void {
    this.generalService.getAllGifs().subscribe((urls) => {
      this.gifUrls = urls;
      this.gifUrls.forEach((urlPromise) => {
        urlPromise.then((url) => {
          this.dataArr.push(url);
        });
      });
      this.ngZone.run(() => {
        this.dataLoaded = true;
        this.templatesLoaded = true;
      });
    });
    this.route.paramMap.subscribe((p) => {
      this.patientUid = p.get('id');
    });

    this.sub_events_listener = this.pm.all_events$.subscribe((events) => {

      this.assignedExercises = events;

    });
  }
  tempEvents: any[] = []

  onDayClicked(date) {

    if (!date) return




    this.pm.toggleEvent(date)


  }
  sub_events_listener: any;
  getGifLinks(exerciseName: string): string | undefined {
    if (exerciseName === 'sit2stand_test') {
      exerciseName = 'squat.gif';
    }
    if (exerciseName === 'diaphragm') {
      exerciseName = 'breath.gif';
    }
    if (exerciseName === 'lip') {
      exerciseName = 'breath.gif';
    }
    return this.dataArr.find((link) => link.includes(exerciseName));
  }

  // CRUD methods

  createProgram(calendarComponent) {
    this.programCreated = true;
    const list = this.pm.getExercisesList()



    const requestData = {
      patient_uid: this.patientUid,
      assigned_exercises: list.map(e => {
        delete e.program_id
        return { ...e }
      }),
    };


    //console.log({ requestData })
    //return
    this.exerciseService.createExerciseProgram(requestData).subscribe(
      (data) => {
        this.translate
          .get('toastr.exerciseProgramCreationSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      (error) => {
        this.translate
          .get('toastr.exerciseProgramCreationFail')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
      () => {
        this.closeProgramCreation(true);
        this.pm.deleteProgram("new")
      }
    );
  }

  getErrorField(exercise: any): string {
    if (
      (exercise.sets || exercise.sets === 0) &&
      (exercise.sets <= 0 || exercise.sets > 10)
    ) {
      return 'sets';
    }
    if (exercise.set_break?.duration && exercise.set_break.duration < 25) {
      return 'break';
    }
    if (exercise.category == 'breathing') {
      if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
        return 'repetitions';
      }
      let field = '';
      exercise.actions.forEach((action) => {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.duration) > 10
        ) {
          field = action.name;
        }
      });
      if (field) {
        return field;
      }
    }
    if (exercise.category == 'stretch') {
      if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
        return 'repetitions';
      }
      let field = '';
      exercise.actions.forEach((action) => {
        if ('count' in action) {
          if (
            !action.count ||
            parseInt(action.count) <= 0 ||
            parseInt(action.count) > 30
          ) {
            field = 'count';
          }
        }
        if ('duration' in action) {
          if (
            !action.duration ||
            parseInt(action.duration) <= 0 ||
            parseInt(action.count) > 600
          ) {
            field = 'duration';
          }
        }
      });
      if (field) {
        return field;
      }
    }
    if (exercise.category == 'lower_body') {
      if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
        return 'repetitions';
      }
      let field = '';
      exercise.actions.forEach((action) => {
        if ('count' in action) {
          if (
            !action.count ||
            parseInt(action.count) <= 0 ||
            parseInt(action.count) > 30
          ) {
            field = 'count';
          }
        }
        if ('duration' in action) {
          if (
            !action.duration ||
            parseInt(action.duration) <= 0 ||
            parseInt(action.duration) > 600
          ) {
            field = 'duration';
          }
        }
      });
      if (field) {
        return field;
      }
    }
    if (exercise.category == 'breathing_time') {
      return 'duration';
    }
    if (exercise.category == 'relaxation') {
      return 'duration';
    }
    return '';
  }

  checkExerciseInputValidation(exercise) {
    let valid = true;
    if (
      (exercise.sets || exercise.sets === 0) &&
      (exercise.sets <= 0 || exercise.sets > 10)
    ) {
      return false;
    }
    if (exercise.set_break?.duration && exercise.set_break.duration < 25) {
      return false;
    }
    if (exercise.category == 'breathing') {
      valid = this.checkBreathingInputValidation(exercise);
    }
    if (exercise.category == 'stretch') {
      valid = this.checkStretchInputValidation(exercise);
    }
    if (exercise.category == 'lower_body') {
      valid = this.checkLowerBodyInputValidation(exercise);
    }
    if (exercise.category == 'breathing_time') {
      valid = exercise.actions[1].duration >= 15;
    }
    if (exercise.category == 'relaxation') {
      valid = exercise.actions[0].duration >= 15;
    }
    return valid;
  }

  checkStretchInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.count) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }

  checkLowerBodyInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.duration) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }
  onInnerDivClick(event: Event): void {
    event.stopPropagation();
  }

  checkBreathingInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    var valid = true;
    exercise.actions.forEach((action) => {
      if (action.name != "hold") {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.duration) > 10
        ) {
          valid = false;
        }
      }else{
        if (
          action.duration==undefined ||
          parseInt(action.duration) < 0 ||
          parseInt(action.duration) > 10
        ) {
          valid = false;
        }
      }
    });

    return valid;
  }



  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(
  //     this.chosenExercises,
  //     event.previousIndex,
  //     event.currentIndex
  //   );
  // }

  // onBackButton(dialog) {
  //   if (this.chosenExercises.length > 0) this.openModal(dialog);
  //   else this.closeProgramCreation();
  // }

  openModal(content, component?) {
    this.modalService.open(content, { size: 'lg' });
    if (component) {
      this.calendarComponent = component;
    }
  }

  closeProgramCreation(reload = false) {
    //this.storeService.getPrograms(this.patientUid,true).subscribe(_=>{

    this.router.navigate([this.patientUid], {
      relativeTo: this.route.parent,
      state: { reload },
    });
    //})
  }

  select(e) {
    this.selectedDates.push(e);
  }

  isSelected(date) {
    const objectPosition = this.selectedDates.indexOf(date);
    if (objectPosition === -1) {
      return false;
    }
    return true;
  }

  selectedDatesEmpty(calendarComponent) {
    return this.pm.getEvents().length == 0

  }

  changeDateString(date: string) {
    const newDate = new Date(Date.parse(date));

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return day + '.' + month + '.' + year;
  }

  clearDates() {
    this.pm.deleteProgram("new")
  }

  backToExerciseSelection(calendarComponent) {
    this.programCreation = false;
    this.exerciseSelection = true;
    this.pm.deleteProgram("new")
  }

  storeTemporaryExercises(data) {
    this.assignedExercises = data.events;
    this.newAddedEvents = data.newAddedEvents;
  }

  onChangingStretchDuration(exercise) {
    if (exercise.actions[3] && 'duration' in exercise.actions[3]) {
      exercise.actions[3].duration = exercise.actions[1].duration;
    }
  }

  next() {
    var valid = true;
    this.allChosenExercises = [
      ...this.chosenExercises
    ];

    this.allChosenExercises.forEach((chosenExercise) => {
      var checkValidation = this.checkExerciseInputValidation(chosenExercise);
      if (!checkValidation) {
        valid = false;
        const errorField = this.getErrorField(chosenExercise);
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (
              chosenExercise.display_name &&
              chosenExercise.display_name[this.language]
            ) {
              this.translate
                .get('toastr.exerciseFieldValidationError')
                .subscribe((fieldErr: string) => {
                  this.translate
                    .get(`createExerciseProgram.${errorField}`)
                    .subscribe((fieldName: string) => {
                      this.toastr.error(
                        chosenExercise.display_name[this.language] +
                        ' ' +
                        res.substring(res.indexOf(' ') + 1) +
                        '. \n' +
                        fieldErr +
                        ' ' +
                        fieldName
                      );
                    });
                });
            } else {
              this.toastr.error(res);
            }
          });
      }
    });

    if (valid) {
      this.exerciseSelection = false;
      this.programCreation = true;
      this.chosenExercises = this.chosenExercises.map(e => ({ ...e, exercise_id: e._id, program_id: 'new' }))

      this.pm.initProgram(this.chosenExercises)
    }
  }

  getExerciseGif(event, exercise) {
    event.target.src = '../../../assets/images/Breathment Logo_rev_white.png';
  }

  checkKeyExists(key: string, obj: any) {
    return key in obj;
  }

  toggleTemplate(template) {
    template.expanded = !template.expanded;
    this.refreshSelectedTemplateExercisesList(template);
  }
  refreshSelectedTemplateExercisesList(template) {
    this.selected_template_available_exercises = this.allExercises.filter(
      (e) => {
        if (template.exercise_program.find((ex) => ex._id == e._id))
          return false;
        return true;
      }
    );
  }

  onExerciseCheckboxChange(event: any, exercise: any) {

    const index = this.chosenExercises.findIndex(e => e._id == exercise._id)
    if (index > -1) {
      this.chosenExercises.splice(index, 1)
    } else {
      this.chosenExercises.push({ ...exercise, periods: [false, false, false] })
    }
  }

  onTemplateCheckboxChange(event: any, template: any) {
    if (event.target.checked) {

      template.exercise_program.forEach((exercise: any) => {

        if (
          !this.chosenExercises.some(
            (e) => e._id === exercise._id
          )
        ) {
          //this.tempChosenExercisesInTemplates.push(exercise);

          const index = this.chosenExercises.findIndex(e => e._id == exercise._id)
          if (index > -1) {
            this.chosenExercises.splice(index, 1)
          } else {
            this.chosenExercises.push({ ...exercise, periods: [false, false, false] })
          }
        }
      });
    } else {
      // this.tempChosenTemplates = this.tempChosenTemplates.filter(
      //   (id) => id !== template._id
      // );

      template.exercise_program.forEach((exercise: any) => {
        this.chosenExercises =
          this.chosenExercises.filter(
            (e) => e._id !== exercise._id
          );
      });
    }
  }

  unselectAllTemplates() {
    this.tempChosenTemplates = [];
    this.tempChosenExercisesInTemplates = [];
  }

  unselectAllExersies() {
    this.tempChosenExercisesInExercises = [];

    this.isModalOpen = false;
  }

  isExerciseSelected(exerciseId: string): boolean {
    return this.tempChosenExercisesInExercises.some(
      (e) => e._id === exerciseId
    );
  }

  addTemplate() {
    const template = {
      _id: null,
      template_name: '',
      exercise_program: [],
      expanded: true,
      edited: false,
      added: true,
    };
    this.extendedTemplates.unshift(template);
    this.refreshSelectedTemplateExercisesList(template);
  }

  revertTemplate(index) {
    this.extendedTemplates[index] = structuredClone(
      this.programTemplates[index]
    );
    this.extendedTemplates[index].expanded = true;
    this.extendedTemplates[index].edited = false;
    this.refreshSelectedTemplateExercisesList(this.extendedTemplates[index]);
  }

  saveTemplate(template, index) {
    let valid = true;
    template.exercise_program.forEach((chosenExercise) => {
      const checkValidation = this.checkExerciseInputValidation(chosenExercise);
      if (!checkValidation) {
        valid = false;
        const errorField = this.getErrorField(chosenExercise);
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (
              chosenExercise.display_name &&
              chosenExercise.display_name[this.language]
            ) {
              this.translate
                .get('toastr.exerciseFieldValidationError')
                .subscribe((fieldErr: string) => {
                  this.translate
                    .get(`createExerciseProgram.${errorField}`)
                    .subscribe((fieldName: string) => {
                      this.toastr.error(
                        chosenExercise.display_name[this.language] +
                        ' ' +
                        res.substring(res.indexOf(' ') + 1) +
                        '. \n' +
                        fieldErr +
                        ' ' +
                        fieldName
                      );
                    });
                });
            } else {
              this.toastr.error(res);
            }
          });
      }
    });

    if (!valid) return;

    if (template._id) {
      this.exerciseService
        .updateProgramTemplate({
          template_name: template.template_name,
          template_id: template._id,
          exercise_program: template.exercise_program,
        })
        .subscribe({
          error: (e) => {
            this.translate
              .get('toastr.templates.updateError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
          complete: () => {
            template.edited = false;
            template.added = false;
            this.programTemplates[index] = structuredClone(template);
            this.translate
              .get('toastr.templates.updateSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          },
        });
    } else {
      this.exerciseService
        .createProgramTemplate({
          template_name: template.template_name,
          exercise_program: template.exercise_program,
        })
        .subscribe({
          next: (data) => {
            template._id = data['data'] ?? '';
          },
          error: (e) => {
            this.translate
              .get('toastr.templates.createError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
          complete: () => {
            template.edited = false;
            template.added = false;
            this.programTemplates.push(structuredClone(template));
            this.translate
              .get('toastr.templates.createSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          },
        });
    }
  }

  deleteTemplate(index) {
    if (this.extendedTemplates[index]._id) {
      this.exerciseService
        .deleteProgramTemplate({
          template_id: this.extendedTemplates[index]._id,
        })
        .subscribe({
          error: (e) => {
            this.translate
              .get('toastr.templates.deleteError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          },
          complete: () => {
            this.programTemplates.splice(index, 1);
            this.extendedTemplates.splice(index, 1);
            this.translate
              .get('toastr.templates.deleteSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          },
        });
    } else {
      this.extendedTemplates.splice(index, 1);
    }
  }

  removeTemplateExercise(template, index) {
    template.exercise_program.splice(index, 1);
    template.edited = true;
    this.refreshSelectedTemplateExercisesList(template);
  }

  addTemplateExercise(template, exerciseOption) {
    template.exercise_program.push(structuredClone(exerciseOption));
    this.refreshSelectedTemplateExercisesList(template);
  }

  addTemplateExerciseAndSave(template, exerciseOption, i) {
    template.exercise_program.push(structuredClone(exerciseOption));
    this.refreshSelectedTemplateExercisesList(template);
    this.saveTemplate(template, i);
  }

  programHasExercise(exerciseProgram, exercise) {
    return exerciseProgram.some((ex) => ex._id === exercise._id);
  }

  updateExerciseHoldDuration($event, exercise) {
    if (exercise.actions.length > 3) {
      exercise.actions[3].duration = Number($event.target.value);
    }
  }

  selectTab(tab: TabOptions): void {
    this.selectedTab = tab;

    this.chosenExercisesPage = 1;
  }

  hasExercisesForSelectedTab(timeOfDayStr): boolean {
    const timeOfDay =
      timeOfDayStr === 'Morning' ? 0 : timeOfDayStr === 'Afternoon' ? 1 : 2;

    return this.chosenExercises[timeOfDay].length > 0;
  }

  getExercisesForSelectedTab(period: number): any[] {

    return this.chosenExercises.filter(ce => ce.periods[period])
  }

  paginatedTemplates() {
    const start = (this.templatePage - 1) * this.templatePageSize;
    const end = start + this.templatePageSize;
    return this.extendedTemplates.slice(start, end);
  }

  paginatedChosenExercises(period: number): any[] {

    const start = (this.chosenExercisesPage - 1) * this.chosenExercisesPageSize;
    const end = start + this.chosenExercisesPageSize;
    return this.chosenExercises.filter(ce => ce.periods[period]).slice(start, end);
  }

  test(temp: any): void {
    //console.log(temp);
  }

  addExercisesToPermanentList(period: string) {

    let valid = true;
    const invalidExercises = [];

    // const tempChosenExercises = this.templatesTab
    //   ? this.tempChosenExercisesInTemplates
    //   : this.exercisesTab
    //     ? this.tempChosenExercisesInExercises
    //     : [];

    this.chosenExercises.forEach((exercise) => {
      const checkValidation = this.checkExerciseInputValidation(exercise);
      if (!checkValidation) {
        valid = false;
        invalidExercises.push([exercise]);
      }
    });

    if (valid) {
      this.chosenExercises.forEach((exercise) => {

        const existingExercise = this.chosenExercises.find(x => x._id === exercise._id);

        if (existingExercise) {
          existingExercise.periods[period] = true;
        } else {
          const newExercise = {
            ...exercise,
            periods: [false, false, false],
          };
          newExercise.periods[period] = true;
          this.chosenExercises.push(newExercise);
        }
      });
    } else {
      invalidExercises.forEach((exercise) => {
        const errorField = this.getErrorField(exercise);
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (exercise.display_name && exercise.display_name[this.language]) {
              this.translate
                .get('toastr.exerciseFieldValidationError')
                .subscribe((fieldErr: string) => {
                  this.translate
                    .get(`createExerciseProgram.${errorField}`)
                    .subscribe((fieldName: string) => {
                      this.toastr.error(
                        `${exercise.display_name[this.language]
                        } ${res.substring(
                          res.indexOf(' ') + 1
                        )}.\n${fieldErr} ${fieldName}`
                      );
                    });
                });
            } else {
              this.toastr.error(res);
            }
          });
      });
    }

    // console.log(this.chosenExercises);
  }
  totalCount() {
    return this.countAfterFilter(0) + this.countAfterFilter(1) + this.countAfterFilter(2)
  }
  onAutoSelectDone(dates: Date[]) {
    if (!Array.isArray(dates) || dates?.length == 0) return
    dates = dates.map(d => {
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      d.setMilliseconds(0)
      return d;
    })

    this.pm.autoSelect(dates);

  }
  deleteFromPermanentList(exercise: any) {

    const e = this.chosenExercises.find(e => e._id == exercise._id)
    if (!e) return

    e.periods[this.selectedTab] = false

  }

  addNewTemplate() {
    this.isModalOpen = !this.isModalOpen;
  }

  saveNewTemplate() {
    const template = {
      _id: null,
      template_name: this.newTemplateName,
      exercise_program: [...this.tempChosenExercisesInExercises],
      expanded: false,
      edited: false,
      added: false,
    };

    this.saveTemplate(template, this.programTemplates.length);
    this.extendedTemplates.unshift(template);

    this.isModalOpen = false;
    this.newTemplateName = '';
  }
}
