<app-navbar></app-navbar>
<div class="container mt-4">
    <div class="d-flex align-items-center">
        <h2> {{ 'notifications.title' | translate }}</h2>
        <i class="fa fa-bell fa-lg" aria-hidden="true"></i>
    </div>
    <hr>
    <div class="d-flex w-100 justify-content-center align-items-center">
        <p class="text-xl mt-5">{{ 'notifications.none' | translate }}</p>
    </div>
</div>

