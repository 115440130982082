<div class="container-fluid p-0 m-0" style="background-color: #111111">
  <div *ngIf="!dataLoaded" class="spinner-container" >
    <div  class=" spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
  <span class="top-right pr-5 pt-5">
    <i class="fas fa-times" style="font-size: 40px; color: #d8f3ff; cursor: pointer" (click)="closeExerciseView()"></i>
  </span>

  <div [ngClass]="{ 'd-none': !displayAITrainerInstructionVideo }" class="instruction-video-display">
    <video autoplay playsinline controls src="{{ videoInstructionUrl }}"
      style="height: 70vh; width: auto; object-fit: contain" id="main-instruction-video" #mainInstructionVideo></video>
  </div>
  <!-- <video  class="exercise-display mb-0" autoplay playsinline #cameraVideo>
    <source [src]="longTermVideo" type="video/mp4" />
  </video> -->
  <canvas id="c1" style="display: none" #c1></canvas>

  <div *ngIf="exerciseFinished && !serverError" style="height: 100vh; width: 100%; top: 0px; position: fixed">
    <video autoplay muted loop style="position: relative; height: 100%; min-width: 100%; width: auto">
      <source src="../../../assets/videos/congratulations_video.mp4" type="video/mp4" />
    </video>
  </div>

  <div class="top-left">
    <div *ngIf="exerciseStarted && showTimer" style="height: 200px; width: auto" [style.left.px]="timerPositionLeft"
      [style.top.px]="timerPositionTop" [style.position]="'relative'">
      <circle-progress [percent]="countdownPercentage" [maxPercent]="100" [innerStrokeColor]="'transparent'"
        [subtitleFontSize]="80" [subtitle]="this.countdownNumber ? this.countdownNumber : ''"
        [subtitleColor]="'#384f6b'" [outerStrokeColor]="'#384f6b'" [outerStrokeWidth]="15" [startFromZero]="false"
        [clockwise]="true" [renderOnClick]="false"></circle-progress>
    </div>
  </div>

  <div  *ngIf="dataLoaded" class="center-middle">
    <div *ngIf="beforeExerciseDisplay">
      <div class="card w-100 border-0 p-5 shadow-lg rounded">
        <div class="row">
        
          <div  class="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center">
            <img *ngIf="gifUrl" alt="Image placeholder"
            [src]="gifUrl"
            style="height: 150px;" >
            <img *ngIf="!gifUrl" alt="Image placeholder"
            [src]="'../../../assets/Logo/Breathment Logo_rev_white.jpg'"
            style="height: 150px;" id="navbar-logo">
          </div>
          
      
          <div class="col-lg-9 col-sm-12 my-auto">
            <h3 class="text-center">{{ exercise.display_name[language] ?? exercise.display_name['en'] }}</h3>
            <div class="d-flex justify-content-center align-items-center">
              <button *ngIf="!firstExercise" class="btn btn-outline-primary"
                (click)="playInstructionVideo('ai-trainer')">
                {{ "exercise.aiTrainerIntro" | translate }}
              </button>
              <button class="btn btn-outline-primary" (click)="playInstructionVideo('exercise')">
                {{ "patient.startExercise" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="countdown" style="height: 250px; width: auto">
      <circle-progress [percent]="countdownPercentage" [innerStrokeColor]="'transparent'"
        [class]="'rounded-circle bg-translucent-secondary'" [maxPercent]="100" [subtitleFontSize]="80"
        [subtitle]="this.countdownNumber ? this.countdownNumber : ''" [subtitleColor]="'#384f6b'"
        [outerStrokeColor]="'#384f6b'" [outerStrokeWidth]="15" [startFromZero]="false" [clockwise]="true"
        [renderOnClick]="false"></circle-progress>
    </div>
    <div *ngIf="exerciseFinished && !serverError">
      <div class="card background-primary w-100 border-0 p-5 shadow-lg rounded">
        <h1 class="text-white text-center mb-3"
          style="font-family: 'Outfit', sans-serif; font-size: 75px; font-weight: 500;">
          {{ "exercise.greatWork" | translate }}
        </h1>

        <div class="d-flex justify-content-center align-items-center">
          <img alt="Image placeholder" [src]="'../../../assets/Logo/Breathment_Logo_rev_white.png'"
            style="height: 180px" id="navbar-logo" />
          <div class="pl-3">
            <div *ngFor="let feedback of exerciseResults" class="pl-5">
              <h2 *ngIf="feedback.unit === 'count'" class="text-white text-center">
                <span *ngIf="feedback.total">
                  {{ "exercise.correctCount" | translate }}:
                  {{ feedback.value }} <br />
                  {{ "exercise." + feedback.display_name | translate }}:
                  {{ feedback.total }}
                </span>
                <span *ngIf="!feedback.total">
                  {{ "exercise." + feedback.display_name | translate }}:
                  {{ feedback.value }}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="serverError">
      <div class="card background-primary w-100 border-0 p-5 shadow-lg rounded">
        <h1 class="text-white text-center mb-3" style="font-size: 75px">
          {{ "exercise.aiTrainerNotAvailable" | translate }}
        </h1>

        <div class="d-flex justify-content-center align-items-center">
          <img alt="Image placeholder" [src]="'../../../assets/Logo/Breathment_Logo_rev_white.png'"
            style="height: 180px" id="navbar-logo" />
          <div class="pl-3">
            <h2 class="text-white text-center">
              {{ "exercise.aiTrainerNotAvailableText" | translate }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <audio  id="mp3AudioPlayer">
    <source type="audio/mp3" />
  </audio>
  <audio  id="beepAudioPlayer" >
    <source type="audio/mp4" />
  </audio>

  <div *ngIf="exerciseStarted" class="silhouette-position">
    <video *ngIf="exerciseStarted"  class="exercise-display mb-0" autoplay playsinline #cameraVideo style="width: 100%; height: 100%;">
      <source [src]="longTermVideo" type="video/mp4" />
    </video>
  </div>

  <div *ngIf="
      !displayAITrainerInstructionVideo &&
      !exerciseFinished &&
      !beforeExerciseDisplay
    " class="feedback-background">
    <img src="../../../assets/images/exercise-icons/feedback-background.png" alt="Image placeholder" id="feedback-background"
      style="height: 100%" />
    <div class="feedback-icons-center">
      <img *ngIf="poseWarning" src="../../../assets/images/exercise-icons/warning-icon.png" alt="empty"
        class="feedback-icon d-block mx-auto pb-3" />
      <!-- <h3 *ngIf="poseWarning" class="text-center" style="color: #7b3939">
        {{ "exercise.incorrectPose" | translate }}
      </h3> -->
      <div *ngIf="!poseWarning">
        <div *ngFor="let feedback of exerciseResults">
          <h3 *ngIf="feedback.unit === 'count'" class="text-white text-center">
            {{ "exercise." + feedback.display_name | translate }}: <br />
            <!-- {{ exercise.repetitions - (feedback.total ? feedback.total : feedback.value) }}
            <span *ngIf="feedback.action_count"
              >/ {{ feedback.action_count }}</span
            > -->
            {{getRemainingRound(feedback)}}
          </h3>

          <hr *ngIf="exerciseResults.length > 1 || exerciseWarnings.length > 0" />
        </div>
      </div>
      <div *ngIf="!poseWarning">
        <div *ngFor="let warning of exerciseWarnings">
          <div *ngIf="warning.display && !poseWarning">
            <h3 *ngIf="!showWarningImage" class="text-white text-center">
              {{ "exercise." + warning.warning.display_name | translate }}
              <span *ngIf="warning.warning.unit === 'direction'">
                <h3 class="text-white">
                  {{ "generalTerms." + warning.warning.value | translate }}
                </h3>
              </span>
            </h3>
            <!--        <h2 *ngIf="!'../../../assets/images/exercise-icons/{{warning.display_name}}.png'" class="text-white text-center">{{ 'exercise.' + warning.display_name | translate}}</h2>-->
            <img *ngIf="showWarningImage" alt="Image placeholder" [src]="
                '../../../assets/images/exercise-icons/' +
                warning.warning.display_name +
                '.png'
              " (error)="getWarningImage($event, exercise)" id="navbar-logo"
              class="d-block mx-auto pb-3 feedback-icon" />
            <!--        <img *ngIf="'../../../assets/images/exercise-icons/{{warning.display_name}}.png'" src="../../../assets/images/exercise-icons/{{warning.display_name}}.png" alt="empty" class="d-block mx-auto pb-3 feedback-icon">-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-center">
    <!--    <h4 *ngIf="exerciseFinished" class="background-primary p-1">{{ 'exercise.exerciseCompleted' | translate }}</h4>-->
    <!--    <div class="text-center d-none d-lg-block">-->
    <!--      <h4 *ngIf="exerciseStarted && !countdown && !displayAITrainerInstructionVideo && !exerciseFinished && !exercise.actions[instructionCounter%exercise.actions.length].display_half" class="background-primary p-1">{{exercise.actions[instructionCounter%exercise.actions.length].instruction[language]}}</h4>-->
    <!--      <h4 *ngIf="!exerciseStarted && !countdown && !exerciseFinished && !displayAITrainerInstructionVideo && !initialPose && !beforeExerciseDisplay" class="background-danger p-1">{{ 'exercise.initialPoseError' | translate }}</h4>-->
    <!--      <h4 *ngIf="!exerciseStarted && !countdown && !displayAITrainerInstructionVideo && !exerciseFinished && initialPose && !startGesture" class="background-primary p-1">{{ 'exercise.raiseArmStart' | translate }}</h4>-->
    <!--      &lt;!&ndash;      <h4 *ngIf="!exerciseStarted && !countdown && !exerciseFinished && !displayAITrainerInstructionVideo && !initialPose" class="background-danger p-1">{{ 'exercise.initialPoseError' | translate }}</h4>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <button *ngIf="!exerciseStarted && !countdown && !exerciseFinished && !displayAITrainerInstructionVideo" class="btn btn-white" style="color: #374f6b;" [disabled]="!initialPose" (click)="startCountdown()">Start</button>&ndash;&gt;-->
    <!--    </div>-->
    <button *ngIf="displayAITrainerInstructionVideo" class="btn text-white" style="background-color: #374f6b"
      (click)="nextClicked()" (dblclick)="preventDoubleClick()">
      {{ "generalTerms.next" | translate }}
    </button>

    <!--    <button *ngIf="!exerciseStarted && !countdown && !exerciseFinished" class="btn text-white" style="background-color: #374f6b;" (click)="initWebcam()">{{ 'exercise.start' | translate }}</button>-->
  </div>
</div>