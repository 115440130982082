import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PersonalInfoModel } from 'src/app/schemas/personalInformation.model';
import { Constants } from "../../constants";
import { AuthService } from '../../services/auth.service';
import { GeneralService } from "../../services/general/general.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],

})
export class LoginComponent implements OnInit, AfterViewChecked, OnDestroy {
  public email: any = null;
  public password: any = null;
  public resetPasswordEmail: any = null;
  private history: string[] = [];

  authError: any;
  currentUser: any;

  emailValidationGroup: UntypedFormGroup;
  public personalInfoModel: PersonalInfoModel = new PersonalInfoModel();
  public checkEmail: boolean = false;
  public emailSent: boolean = false;
  public emailNotFound: boolean = false;
  public loggingIn: boolean = false;
  public dataLoaded: boolean = false;
  onAuthChanged: Subscription;
  //Patterns
  emailPattern: string = Constants.emailPattern;
  unsub: any;
  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,

    private location: Location,
    private modalService: NgbModal,
    private generalService: GeneralService,
    public translate: TranslateService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {

    this.dataLoaded = false;
    if (this.translate.store.translations[this.translate.currentLang]) {
      this.ngZone.run(() => {
        this.dataLoaded = true;
      })
    } else {
      this.translate.onLangChange.subscribe((_) => {
        this.ngZone.run(() => {
          this.dataLoaded = true;
        })
      });
    }




  }
  ngOnDestroy(): void {

  }

  ngAfterViewChecked(): void {
  }
  ready = false;
  ngOnInit(): void {


    this.auth.resetLoggingIn();
    this.auth.eventLoggingIn$.subscribe(data => {
      this.loggingIn = data;
    });

    this.auth.resetAuthError();
    this.auth.eventAuthError$.subscribe(data => {
      this.authError = data;
    });

    this.onAuthChanged = this.auth.authenticatedUser.subscribe((user: any) => {

      if (user) {
        if (this.onAuthChanged) this.onAuthChanged.unsubscribe()

        this.router.navigate([`/dashboard`, user.role])
      }
      // this.ngZone.run(() => {
      //   this.dataLoaded = true;
      // })

    }, err => {

    }, () => { })
  }

  login() {

    this.onAuthChanged = this.auth.authenticatedUser.subscribe((user: any) => {
      if (user) {
        if (this.onAuthChanged) this.onAuthChanged.unsubscribe()
        this.router.navigate([`/dashboard`, user.role])
      }

    }, err => {

    }, () => { })
    this.auth.signIn(this.email, this.password);
  }

  logout() {
    this.auth.logout();
  }

  resetPassword() {
    this.checkEmail = true;
    if (this.emailValidationGroup.valid) {
      this.personalInfoModel.email = this.emailValidationGroup.get('email').value;
      const requestData = {
        email: this.personalInfoModel.email
      }
      this.generalService.sendPasswordResetEmail(requestData).subscribe(() => {
        this.emailSent = true;
      },
        err => {
          if ((typeof err.error === 'string') && err.error.includes('emailNotFound')) {
            this.emailNotFound = true;
          }
        });
    }
  }

  back() {
    this.location.back();
  }

  openModal(content) {
    this.emailSent = false;
    this.initializeFormGroup();
    this.modalService.open(content);
  }

  initializeFormGroup() {
    this.emailValidationGroup = new UntypedFormGroup({
      email: new UntypedFormControl(this.personalInfoModel.email, [
        Validators.required,
        Validators.pattern(this.emailPattern)
      ])
    });
  }

  clearVariables() {
    this.checkEmail = false
    this.resetPasswordEmail = null;
    this.emailNotFound = false;
    this.emailSent = false;
  }

}
