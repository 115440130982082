import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslateService } from "@ngx-translate/core";


import { JwtHelperService } from '@auth0/angular-jwt';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserService } from "./user.service";

import { BehaviorSubject, Observable, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

import { ToastrService } from 'ngx-toastr';

import { DataService } from './data.service';
import { StoreService } from './store.service';
import { IAuthenticatedUser, IUserProfile } from '../resolvers';
import { map, tap } from 'rxjs/operators';
import { delayX, msdelay } from '@helpers/helper';

//import {AddUserAction} from '../store/actions/user.action';
const PERIODIC_REFRESH_TOKEN = 10 * 60 * 1000;
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  onRefreshTokenFailed() {
    this.router.navigate(['/login'])
  }
  onLanguageChanged(locale: 'en' | 'de' | 'tr' | 'it') {
    if (this.me?.locale) this.me = { ...this.me, locale }
    if (this._authenticatedUser?.value) { this._authenticatedUser.next({ ...this._authenticatedUser.value, locale }) }
  }

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
  public baseUrl: string;
  initialized: boolean = false;

  // private user;
  public get authenticatedUser() {
    if (!!this._authenticatedUser.getValue()?.id)
      return this._authenticatedUser;

    const usr = JSON.parse(localStorage.getItem('__auth')) as IAuthenticatedUser
    this._authenticatedUser.next(usr);

    return this._authenticatedUser;
  }
  private _authenticatedUser = new BehaviorSubject<IAuthenticatedUser>(null);
  private authError = new BehaviorSubject<string>('');
  eventAuthError$ = this.authError.asObservable();

  private loggingIn = new BehaviorSubject<boolean>(false);
  eventLoggingIn$ = this.loggingIn.asObservable();

  private loggingOut = new BehaviorSubject<boolean>(false);
  eventLoggingOut$ = this.loggingOut.asObservable();

  public tempRefreshToken: string;
  private currentPassword: string;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    @Inject(HttpClient) protected http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private injector: Injector,
    private _ngZone: NgZone
  ) {


    this.baseUrl = environment.baseCpuUrl;
    try {


      this.renewAccessToken()

    } catch (error) {
      this._authenticatedUser.next(null)
    }
    setTimeout(() => {
      this.initialized = true
    }, 400)
    const _this = this;
    let ts = 0
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        if (Date.now() - ts > (30 * 60 * 1000)) {
          _this.renewAccessToken()
        }
      } else {

        ts = Date.now()
      }
    });

  }
  getUserFromStorage(): IAuthenticatedUser | undefined {
    try {
      return JSON.parse(localStorage.getItem('__auth')) as IAuthenticatedUser
    } catch (error) {
      return undefined;
    }
  }
  getAuthenticatedUser(): IAuthenticatedUser | undefined {
    return this.authenticatedUser?.value;
  }
  setCurrentPassword(password: string) {
    this.currentPassword = password;
  }

  getCurrentPassword(): string {
    return this.currentPassword;
  }

  resetAuthError() {
    this.authError.next('');
  }

  resetLoggingIn() {
    this.loggingIn.next(false);
  }

  resetLoggingOut() {
    this.loggingOut.next(false);
  }

  signIn(email, password) {
    this.loggingIn.next(true);
    const tokenParams = new URLSearchParams({
      grant_type: "password",
      client_id: environment.KEYCLOAK_CLIENT_ID,
      client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
      username: email,
      password: password
    });

    this.http.post(`${environment.baseKeycloakUrl}token`, tokenParams.toString(), this.options).subscribe(userCredentials => {
      if (userCredentials) {
        const tmpUser = this.extractTokenData((userCredentials as any)?.access_token)
        if (tmpUser) tmpUser.refresh_token = (userCredentials as any)?.refresh_token;
        if (tmpUser) {
          tmpUser.ts = Date.now();
          localStorage.setItem('__auth', JSON.stringify(tmpUser))
          this._authenticatedUser.next(tmpUser)
        }

      }
    },
      error => {
        this.loggingIn.next(false);
        if (error.error.error === 'invalid_grant') {
          this.authError.next(error);
        } else {
          this.translate.get('toastr.loginError').subscribe((res: string) => {
            this.toastr.error(res);
          });
        }
      });
  }

  public checkPassword(tokenParams: any) {
    return this.http.post(`${environment.baseKeycloakUrl}token`, tokenParams.toString(), this.options).pipe();
  }

  public sendResetUserPasswordEmail(email: string) {
    this.afAuth.sendPasswordResetEmail(email).then(() => {
      this.translate.get('toastr.emailSentSuccess').subscribe((res: string) => {
        this.toastr.success(res);
      });
    },
      (error) => {
        this.translate.get('toastr.emailSentError').subscribe((res: string) => {
          this.toastr.error(res);
        });
      });
  }

  public confirmPasswordReset(code, password) {
    this.afAuth.confirmPasswordReset(code, password).then(() => {
      this.logout();
    })
      .catch(err => {
        this.translate.get('toastr.emailSentError').subscribe((res: string) => {
          this.toastr.error(res);
        });
      });
  }
  public changeEmail(requestData) {
    return this.http.post(this.baseUrl + 'change_email', requestData).pipe();
  }
  extractTokenData(access_token: string): IAuthenticatedUser | undefined {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(access_token);
    const user: IAuthenticatedUser = {
      access_token: access_token
    } as IAuthenticatedUser;

    try {
      user.id = decodedToken._id;
      if (decodedToken.roles.includes('patient')) {
        user.role = 'patient';
      } else if (decodedToken.roles.includes('physiotherapist')) {
        user.role = 'physiotherapist';
      }

    } catch (error) {
      return undefined
    }
    user.locale = decodedToken.locale
    localStorage.setItem('language', user.locale)
    return user;

  }

  me: IUserProfile;
  me$: BehaviorSubject<IUserProfile> = new BehaviorSubject(null);
  last_request_ts = Date.now()
  public async getUserDetails(request_new_token = false) {
    return new Promise(async (resolve, rej) => {
      //if (this.me) return resolve(this.me);
      this.injector.get(UserService).getUserDetails({ id_token: this.authenticatedUser?.value?.access_token }).subscribe((data: any) => {
        if (data) {
          this.me = {
            ...data,
            role: this.authenticatedUser?.value?.role,
            locale: data.language || data?.personal_information?.language || 'en',
            id: data['_id']
          } as IUserProfile;
          this.translate.use(this.me.locale)
          this.last_request_ts = Date.now()
          this.me$.next(this.me)
          resolve(this.me);
        } else {
          this._authenticatedUser.next(undefined)
          this.last_request_ts = Date.now()
          resolve(null);
        }
      },
        err => {
          this._authenticatedUser.next(null)
          this.loggingIn.next(false);
          resolve(null);
          this.logout();
        });
    })

  }

  logout() {

    this.me = null;
    this.injector.get(StoreService).onLogout();
    this.loggingOut.next(true);
    const params = new URLSearchParams({
      client_id: environment.KEYCLOAK_CLIENT_ID,
      client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
      refresh_token: this._authenticatedUser?.value?.refresh_token,
    });
    localStorage.removeItem('__auth');
    this.http.post(`${environment.baseKeycloakUrl}logout`, params.toString(), this.options).subscribe(response => {

    },
      (error) => {

        // this.loggingOut.next(false);
        // this.translate.get('toastr.logoutError').subscribe((res: string) => {
        //   this.toastr.error(res);
        // });
      }, () => {
        this._authenticatedUser.next(null)
        this.injector.get(DataService).clearDashboardData();
        this.injector.get(UserService).onLogout();


        this._authenticatedUser.next(null)
        this.router.navigate(['login'])
          .finally(() => {
            this.loggingOut.next(false);
          });
      });
  }
  private _isRefreshingToken: BehaviorSubject<boolean> = new BehaviorSubject(false)
  //private refreshing = this._isRefreshingToken.asObservable()
  refreshToken(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('__auth'))?.refresh_token;
    const authParams = new URLSearchParams({
      grant_type: "refresh_token",
      client_id: environment.KEYCLOAK_CLIENT_ID,
      client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
      refresh_token: token
    });
    return this.http.post(`${environment.baseKeycloakUrl}token`, authParams.toString(), this.options)
      .pipe(tap(result => {
        try {
          const __auth = JSON.parse(localStorage.getItem('__auth'))
          localStorage.setItem('__auth', JSON.stringify({ ...__auth, access_token: result.access_token, refresh_token: result.refresh_token }));
        } catch (error) {

        }
      }))
  }
  async renewAccessToken(redirect?: boolean): Promise<IAuthenticatedUser | undefined> {
    if (redirect) {
      this.router.navigate(['login'])
      return undefined
    }
    if (this._isRefreshingToken.getValue() == true) return undefined
    this._isRefreshingToken.next(true)
    return new Promise<IAuthenticatedUser | undefined>((resolve, reject) => {

      const token = JSON.parse(localStorage.getItem('__auth'))?.refresh_token;

      if (!token) {
        this._isRefreshingToken.next(false)
        return;
      }
      const authParams = new URLSearchParams({
        grant_type: "refresh_token",
        client_id: environment.KEYCLOAK_CLIENT_ID,
        client_secret: environment.KEYCLOAK_CLIENT_SECRET_KEY,
        refresh_token: token
      });
      this.loggingOut.next(true);
      this.http.post(`${environment.baseKeycloakUrl}token`, authParams.toString(), this.options).subscribe(async response => {
        if (response["refresh_token"] && response["access_token"]) {
          const tmpUser = this.extractTokenData((response as any)?.access_token)
          if (tmpUser) tmpUser.refresh_token = (response as any)?.refresh_token;
          if (tmpUser) {
            tmpUser.ts = Date.now();
            localStorage.setItem('__auth', JSON.stringify(tmpUser))
          }
          const newAuthUser = {
            ...tmpUser,
            refresh_token: response["refresh_token"],
            access_token: response["access_token"],
            ts: Date.now()
          }
          localStorage.setItem('__auth', JSON.stringify(newAuthUser));
          this._authenticatedUser.next(newAuthUser);
          this._isRefreshingToken.next(false)
          await this.getUserDetails()
          resolve(newAuthUser);
        } else {
          this._authenticatedUser.next(null)
          this._isRefreshingToken.next(false)
          resolve(null)

        }
      }, (err) => {
        this._authenticatedUser.next(null)
        localStorage.removeItem('__auth');
        this._isRefreshingToken.next(false)
        this.injector.get(DataService).clearDashboardData();
        this.injector.get(UserService).onLogout();
        this.router.navigate(['login'])
          .finally(() => {
            this.loggingOut.next(false);
          });
        resolve(null)

      });
    })
  }
  isDetailsRequest = false;
  //last_refresh_token_call:BehaviorSubject<any> = new BehaviorSubject(null)
  async isAuthenticated(): Promise<boolean> {

    const tmpUser = this.getUserFromStorage();

    if (!tmpUser) {

      return false;
    }

    if (!this._authenticatedUser.value)
      this._authenticatedUser.next(tmpUser)

    if (this._isRefreshingToken.getValue() == true) {
      while (this._isRefreshingToken.getValue() == true) {
        await this.sleep(100)
      }
      return this.isAuthenticated()
    }
    return true;
  }
  sleep = (ms: number): Promise<any | undefined> => {
    return new Promise((res) => {
      setTimeout(() => { res(1) }, ms)
    })
  }
}
