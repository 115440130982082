import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { DatePipe } from '@angular/common';

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { UserService } from '@services/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ExerciseService } from '@services/exercise/exercise.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';

// Models
import { TreatmentSummary } from '@schemas/exercise.model';
import { CalendarEvent } from 'angular-calendar';
import { PersonalInfoModel } from '@schemas/personalInformation.model';
import { Constants } from '../../../constants';
import { birthdateValidation } from 'src/app/shared/custom-validators/birthdateValidation';

import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, BaseChartDirective } from 'ng2-charts';
import { newTaskModel } from '@schemas/newTask.model';
import { DataService } from '@services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  calculateAge,
  colors,
  buildCalendarEvents,
  addAlldates,
} from '@helpers/helper';
//import { PerformanceOverviewComponent } from './performance-overview/performance-overview.component';
import * as moment from 'moment';
import { GeneralService } from '@services/general/general.service';
import { StoreService } from '@services/store.service';

import { Subject } from 'rxjs';
import { IUserProfile } from 'src/app/resolvers';
import { IExercise } from '@services/program-manager/IExercise';
import { Exercise } from '@services/program-manager/Exercise';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-patient-home',
  templateUrl: './patient-home.component.html',
  styleUrls: ['./patient-home.component.css'],
})
export class PatientHomeComponent
  implements OnDestroy, OnInit, AfterViewChecked {
  @Input() patient: any;
  @Input() data: any;
  @Input() role: string;
  physiotherapistExercises?: Array<Exercise>;
  @Output() goBackEvent? = new EventEmitter<string>();
  @Output() remainingExercise = new EventEmitter<boolean>();
  @ViewChild('calendarComponent') calendarComponent;
  @ViewChild('programsSection') programsSection: ElementRef;
  @ViewChild('mainCalendar') mainCalendar: ElementRef;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  //@ViewChild(PerformanceOverviewComponent, { static: false }) private perfOverviewComponent: PerformanceOverviewComponent;

  personalInformationGroup: UntypedFormGroup;
  public personalInfoModel: PersonalInfoModel = new PersonalInfoModel();

  //Patterns
  alphabetPattern: string = Constants.alphabetPattern;
  emailPattern: string = Constants.emailPattern;

  //screen size
  public screenWidth: any;
  public screenHeight: any;
  public smallScreen: boolean;
  currentDate: moment.Moment;
  public repetitions: number = -1;
  public count: number = -1;

  public exercisesList: Array<IExercise>;
  public availableExercises: Array<Exercise> = [];
  public chosenExercise = {} as Exercise;
  public chosenProgram: any;
  public newDates: any[] = [];
  public inhaleDuration: number = null;
  public exhaleDuration: number = null;
  public exercise;
  public clinic = false;
  public rehabilitationCenter = false;
  public previousOperation = false;
  public exercisesForDay: any[] = [];
  public exerciseFromDay: Boolean = false;

  public ageNumber;
  public ageBirthdate;
  public age;

  // Alert
  public assignedExercisesExist = false;

  private _dataLoaded = false;
  set dataLoaded(value: boolean) {
    this._dataLoaded = value;
  }
  get dataLoaded() {
    return this._dataLoaded;
  }
  public exerciseMode = false;
  public createProgramMode: boolean = false;
  public inhale: number = 0;
  public exhale: number = 0;
  public overall: number = 0;
  public posture: number = 0;
  public shoulder: number = 0;
  public exerciseDetails = {
    inhale_duration: null,
    exhale_duration: null,
    sets: null,
    display: false,
  };
  public exerciseCategory: string;
  public breathingBarChartData: any = null;

  // Calendar
  events: CalendarEvent[] = [];
  calendarEvents: CalendarEvent[] = [];
  singleProgramEvents: CalendarEvent[] = [];
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  public emotion: any[] = [];
  public exerciseDay: any;
  public exerciseDayIndex: number;
  public chosenEvent: any;
  public beforeEvaluationColor: any;
  public afterEvaluationColor: any;
  public eventType: string;
  public titleDate: string;
  public exerciseResults: string;
  public breathingPlotData: any;
  public exerciseResultsError: boolean;
  public exerciseResultsLoaded: boolean = false;

  public exerciseDetailsDate: string;

  public locale: string;

  // patient details
  public patientDetails: any;
  public activeTab: number = 0;
  public activeProgramTab: number = 0;
  public activeResultTab: number = 1;
  public savingPatientDetails: boolean = false;
  public detailsEdited: boolean = false;

  // today's exercises
  public todayExercises: any[] = [];
  public todayAllExercises: any[] = [];
  public displayExerciseLimit: number = 4;

  // previous day's exercises
  public remainingPreviousExercises: any[] = [];

  public exercisePrograms: any[] = [];
  public exerciseProgramsCopy: any[] = [];
  public allExercisePrograms: any[] = [];

  // all programs
  public allExercisesDates: any[] = [];
  public largestDate: any = '';
  public smallestDate: any = '';
  public fullDuration: any;
  public remaningduration: any;
  public diffAllDays: number = 0;
  public diffFromToday: number = 0;
  public doneDays: number = 0;
  public donePercentage: number = 0;
  public subtitle = '';

  // treatment summary
  public activeTreatmentSummary: TreatmentSummary;
  public performanceOverview: any;

  public multiSelect: Boolean = true;
  public editMode: Boolean[] = [];
  public assignedDates: Date[][] = [];

  public chosenDate: any;
  public deletedExercisesList: any[] = [];
  public tempExercises: any[] = [];

  public collapsed: boolean[] = [];
  public todayExercisesCollapsed: boolean[] = [];
  public previousExercisesCollapsed: boolean[] = [];
  public programSmallestDate: Date[] = [];
  public programLargestDate: Date[] = [];

  public programCalendarDates: any[][] = [];

  public edit = false;
  public tempActiveTab = 0;

  public countDisplayNames: string[] = [];
  public countValues: number[] = [];
  public countTotal: number[] = [];

  public countUnitDisplayNames: string[] = [];
  public countUnitValues: number[] = [];
  public countUnitMeanValue: number;

  public degreeMeanValue: number[] = [];
  public degreeMaxValue: number[] = [];
  public degreeDisplayName: number[] = [];

  public percentValue: number[] = [];
  public percentDisplayName: number[] = [];

  public problemDisplayName: String[] = [];
  public problemCountValue: number[] = [];
  public problemTotalValue: number[] = [];

  public problemDisplayNameNotNull: String[] = [];
  public problemCountValueNotNull: number[] = [];
  public problemTotalValueNotNull: number[] = [];

  public aiTrainerDisabled: boolean = false;

  view: any[] = [400, 400];
  legend: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#E44D25'],
  };

  single: any[] = [];
  DegreesArrays: any[][] = [];
  labelsArray: string[] = [];

  public physicalExercise: boolean;
  dataSources = [];

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  public maximumDegree: String = '';
  public averageDegree: String = '';
  public formattedDate: String = '';
  public myTasks: any = [];
  public addNewTask: boolean = false;
  public newTaskFormGroup: UntypedFormGroup;
  public newTaskModel: newTaskModel = new newTaskModel();
  public deletedTask: any;
  public updatedTask: any;
  public copyOfEditTask: newTaskModel;
  public dataArr: string[] = [];

  public addNewTaskAction: boolean;

  public isCollapsed = false;
  public completedTasks: number = 0;
  public exerciseToStart: any;
  public paramValue = '';
  public gifUrls: any[] = [];

  displayedColumns: string[] = ['instruction', 'FER', 'FERsec'];
  dataSource = this.emotion;

  public appointments: any[] = [];
  public appointmentDateSelected: boolean = false;
  public selectedAppointmentSlot: any = null;
  public newAppointmentNote: string = '';
  public selectedAppointment: any = null;
  public appointmentNoteEdited: boolean = false;

  public todayDate: string = new Date().toISOString().split('T')[0];

  public adherence: any = null;
  public currentRoute: any;
  patientId: string;
  me: IUserProfile;
  constructor(
    private userService: UserService,
    private exerciseService: ExerciseService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private generalService: GeneralService,
    private ngZone: NgZone
  ) {
    this.me = this.authService.me;
    this.currentDate = moment();
    this.locale = this.authService.getAuthenticatedUser().locale;
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((value) => {
      this.translate.use(value.lang);
      this.locale = value.lang;
    });
    this.role = this.me.role;

    this.patientId = this.me.id;

    this.translate.use(this.locale);

    this.intializeNewTasksForm();
    this.translate
      .get('patientDetails.maximumDegree')
      .subscribe((res: string) => {
        this.maximumDegree = res;
      });
    this.translate
      .get('patientDetails.averageDegree')
      .subscribe((res: string) => {
        this.averageDegree = res;
      });

    // })
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngAfterViewChecked(): void { }

  ngOnInit(): void {
    this.patient = this.data.userDetails;
    this.patientDetails = this.data.userDetails;
    this.myTasks = this.data.tasks['data'];
    this.intializeCompletedTasks();
    this.appointments = this.data.appointments;
    this.exetractPatientDetails();
    this.allExercisePrograms = this.data.allExercisePrograms;
    this.activeTreatmentSummary = this.data.activeTreatmentDetails as any;
    this.processPatientDetails(
      this.data.userDetails,
      this.data.allExercisePrograms
    );
    this.ngZone.run(() => {
      this.dataLoaded = true;
    });

    this.generalService.getAllGifs().subscribe((urls) => {
      this.gifUrls = urls;
      this.gifUrls.forEach((urlPromise) => {
        urlPromise.then((url) => {
          this.dataArr.push(url);
        });
      });
    });
    this.currentRoute = this.router.url;
    const originalDateMoment = this.currentDate;
    const originalDate = originalDateMoment.toDate();
    this.formattedDate = moment(originalDate).format('D.M.YYYY');
  }

  getGifLinks(exerciseName: string): string | undefined {
    if (exerciseName === 'sit2stand_test') {
      exerciseName = 'squat.gif';
    }
    if (exerciseName === 'diaphragm') {
      exerciseName = 'breath.gif';
    }
    if (exerciseName === 'lip') {
      exerciseName = 'breath.gif';
    }
    return this.dataArr.find((link) => link.includes(exerciseName));
  }

  // quickView() {
  //   this.route.queryParams.subscribe(params => {
  //     this.paramValue = params['param'];
  //     if (this.paramValue === 'survey') {
  //       this.goToSurvey();
  //     }
  //     else if (this.paramValue === 'activity') {
  //       this.goToActivity();
  //     }
  //     else if (this.paramValue === 'performanceOverview') {
  //       this.goToPerfOverview();
  //     }
  //     else if (this.paramValue === 'exercisePrograms') {
  //       this.scrollToPrograms();
  //     }
  //   })
  // }

  inputValidator(event: any, maxValue: number) {
    const pattern = /^[0-9]*$/;
    this.removeLeadingZero(event);
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      this.removeLeadingZero(event);
    }
    if (event.target.value > maxValue) {
      event.target.value = Math.floor(event.target.value / 10);
    }
  }

  removeLeadingZero(event) {
    if (event.target.value.startsWith('0')) {
      event.target.value = event.target.value.replace('0', '');
      return;
    }
  }

  checkEmptyValue(event) {
    if (event.target.value === '') event.target.value = 1;
  }

  confirmUpdateExercise(content, program?, component?) {
    for (const e of program.exercises) {
      e.sets = e.sets ?? 1;
      e.repetitions = e.repetitions ?? 1;
      for (const a of e.actions) {
        if ('duration' in a) {
          a.duration = a.duration ?? 1;
        }
      }
    }
    this.openModal(content, program, component);
  }

  triggerUpdateExercise(source?: boolean) {
    setTimeout(() => {
      this.triggerUpdateExercise(source);
    }, 1500);
  }

  getAllTasks() {
    const requestData = {
      patient_uid: this.patientId,
    };
    this.userService.getUserTasks(requestData).subscribe((data) => {
      this.myTasks = data['data'];
      this.intializeCompletedTasks();
    });
  }

  intializeCompletedTasks() {
    this.completedTasks = 0;
    this.myTasks.forEach((task) => {
      if (task.completed) {
        this.completedTasks++;
      }
    });
  }

  // CRUD functions

  public initializeCollapsableArray() {
    for (let i = 0; i < this.exercisePrograms.length; i++) {
      this.collapsed[i] = true;
    }
  }
  processPatientDetails(details: any, exercises: any) {
    if (typeof details.personal_information.age == 'string') {
      this.ageNumber = calculateAge(details.personal_information.age);
      this.ageBirthdate = details.personal_information.age;
      this.age = this.ageNumber;
    } else {
      this.ageNumber = details.personal_information.age;
    }
    this.age = this.ageNumber;
    this.personalInfoModel.age = this.age;
    this.personalInfoModel.firstName = details.personal_information.first_name;
    this.personalInfoModel.lastName = details.personal_information.last_name;
    this.personalInfoModel.email = details.personal_information.email;
    this.initializePersonalInfoForm();
    if (details.disease_history?.previous_illness) {
      if (details.disease_history.previous_treatment === 'Clinic') {
        this.clinic = true;
      } else if (
        details.disease_history.previous_treatment === 'Rehabilitation Center'
      ) {
        this.rehabilitationCenter = true;
      } else if (
        details.disease_history.previous_treatment === 'Previous Operation'
      ) {
        this.previousOperation = true;
      }
    }
    this.events = [];
    this.exercisePrograms = [];
    this.allExercisePrograms = [];
    this.assignedDates = [];

    this.viewDate.setHours(0, 0, 0, 0);

    this.allExercisePrograms = exercises;
    this.changeProgramTab(0);

    for (const program of this.allExercisePrograms) {
      for (const exercise of program.exercises) {
        buildCalendarEvents(
          exercise,
          this.locale,
          this.viewDate,
          this.remainingPreviousExercises,
          this.events,
          this.todayAllExercises,
          this.todayExercises
        );
        const r = addAlldates(
          exercise.assigned_dates,
          this.allExercisesDates,
          this.todayExercises,
          this.translate,
          this.activeTreatmentSummary
        );
        for (const k of Object.keys(r)) {
          this[k] = r[k];
        }
      }
      //}
    }
    this.processAverageDuration(this.todayExercises);
    this.processAverageDuration(this.remainingPreviousExercises);
    this.calendarEvents = this.events;
    if (this.role === 'patient') {
      this.createCollapsableExercises();
    }
    this.ngZone.run(() => {
      this.dataLoaded = true;
    });
  }

  processAverageDuration(exerciseList) {
    exerciseList.forEach((ex) => {
      if (
        ex.exercise.name !== 'sit2stand_test' &&
        ex.exercise.name !== 'squat'
      ) {
        let tempDuration = 0;
        let breakTime = 0;
        ex.exercise.actions.forEach((action) => {
          if (action.duration) {
            if (action.name === 'break') {
              if (action.display_half) {
                breakTime =
                  action.duration + action.audio_length_milliseconds / 1000;
              } else {
                breakTime =
                  ex.exercise.repetitions *
                  (action.duration + action.audio_length_milliseconds / 1000);
              }
            } else {
              tempDuration += action.audio_length_milliseconds / 1000;
              tempDuration += action.duration;
            }
          } else {
            tempDuration += 4;
          }
          tempDuration += 1;
        });
        let avgDuration =
          Math.round((tempDuration * ex.exercise.repetitions + breakTime) / 5) *
          5;
        if (ex.exercise.sets) {
          avgDuration =
            (avgDuration + ex.exercise.set_break.duration) * ex.exercise.sets;
        }
        ex.avgDuration =
          avgDuration > 59
            ? Math.floor(avgDuration / 60) + 'm ' + (avgDuration % 60) + 's'
            : (avgDuration % 60) + 's';
      }
    });
  }

  public exetractPatientDetails() {
    if (typeof this.patientDetails.personal_information.age == 'string') {
      this.ageNumber = calculateAge(
        this.patientDetails.personal_information.age
      );
      this.ageBirthdate = this.patientDetails.personal_information.age;
      this.age = this.ageNumber;
    } else {
      this.ageNumber = this.patientDetails.personal_information.age;
    }
    this.age = this.ageNumber;
    this.personalInfoModel.age = this.age;
    this.personalInfoModel.firstName =
      this.patientDetails.personal_information.first_name;
    this.personalInfoModel.lastName =
      this.patientDetails.personal_information.last_name;
    this.personalInfoModel.email =
      this.patientDetails.personal_information.email;
    this.initializePersonalInfoForm();
    if (this.patientDetails.disease_history?.previous_illness) {
      if (this.patientDetails.disease_history.previous_treatment === 'Clinic') {
        this.clinic = true;
      } else if (
        this.patientDetails.disease_history.previous_treatment ===
        'Rehabilitation Center'
      ) {
        this.rehabilitationCenter = true;
      } else if (
        this.patientDetails.disease_history.previous_treatment ===
        'Previous Operation'
      ) {
        this.previousOperation = true;
      }
    }
    // },
    //   err => {
    //     this.authService.logout();
    //   },
    //   () => {
    //     this.getAllExercisePrograms();
    //   });
  }

  public getAllExercisePrograms() {
    this.events = [];
    this.exercisePrograms = [];
    this.allExercisePrograms = [];
    this.assignedDates = [];

    this.viewDate.setHours(0, 0, 0, 0);

    this.storeService.getPrograms(this.patientId, true).subscribe(
      (data) => {
        this.allExercisePrograms = data;
        this.changeProgramTab(0);
        this.allExercisePrograms.forEach((program) => {
          //if (!program.deleted) {
          program.exercises.forEach((exercise) => {
            buildCalendarEvents(
              exercise,
              this.locale,
              this.viewDate,
              this.remainingPreviousExercises,
              this.events,
              this.todayAllExercises,
              this.todayExercises
            );
            addAlldates(
              exercise.assigned_dates,
              this.allExercisesDates,
              this.todayExercises,
              this.translate,
              this.activeTreatmentSummary
            );
          });
          // }
        });
      },
      (error) => { },
      () => {
        this.calendarEvents = this.events;
        if (this.role === 'patient') {
          this.createCollapsableExercises();
        }
        this.ngZone.run(() => {
          this.dataLoaded = true;
        });
        setTimeout(() => {
          this.programsSection.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 750);
      }
    );
  }

  initializeEditableMode(programs: any[]) {
    programs.forEach(() => {
      this.editMode.push(false);
    });
  }

  addAssignedDatesPerProgram(programs) {
    let programAssignedDates = [];
    programs.forEach((program) => {
      program.exercises[0].assigned_dates.forEach((exerciseDate) => {
        const date = exerciseDate.date.split('.');
        const assignDate = new Date(date[2], date[1] - 1, date[0]);
        if (!programAssignedDates.includes(assignDate)) {
          programAssignedDates.push(assignDate);
        }
      });
      this.assignedDates.push(programAssignedDates);
      programAssignedDates = [];
    });
  }
  findSmallestAndLargestDatePerProgram(index) {
    let date: string;
    let smallestDate: Date;
    let largestDate: Date;

    this.exercisePrograms[index].exercises.forEach((exercise) => {
      if (exercise.assigned_dates.length > 0) {
        const dates = exercise.assigned_dates.map((date) => {
          let tempDate = date.date.split('.');
          return new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
        });
        if (!largestDate) {
          largestDate = new Date(Math.max.apply(null, dates));
        } else {
          let tempLargestDate = new Date(Math.max.apply(null, dates));
          if (tempLargestDate > largestDate) {
            largestDate = tempLargestDate;
          }
        }
        if (!smallestDate) {
          smallestDate = new Date(Math.min.apply(null, dates));
        } else {
          let tempSmallestDate = new Date(Math.min.apply(null, dates));
          if (tempSmallestDate < smallestDate) {
            smallestDate = tempSmallestDate;
          }
        }
      }
    }); 
    this.programSmallestDate[index] = smallestDate;
    this.programLargestDate[index] = largestDate;
  }

  public getExercises() {
    this.exercisesList = [];
    const requestData = {
      patient_uid: this.patientId,
    };

    this.exerciseService.getExercises(requestData).subscribe(
      (data) => {
        this.exercisesList = data['data'];
        this.patient.exerciseList = this.exercisesList;
        // this.createCalendarEvents();
      },
      (error) => { },
      () => {
        // this.dataLoaded = true;
      }
    );
  }

  exercise_options: any = {
    sets_count: 1,
    repetitions_count: 1,
    inhale_duration: 4,
    exhale_duration: 4,
    duration: 60,
  };
  updated = true;
  temp_new_added_exercises = [];

  navigateTo(path) {
    this.createProgramMode = true;
    this.router.navigate([path], { relativeTo: this.route });
  }
  getExerciseDetails(exercise, content, fromDay?) {
    this.exerciseResultsLoaded = false;
    const handleSessionResult = (returnData) => {
      try {

        // if (exercise.title === 'Bauchatmung' || exercise.title === 'Lippenbremse') {
        this.repetitions = -1;
        this.count = -1;
        if (returnData['data'].sets) {
          this.repetitions = returnData['data'].sets;
        }
        if (returnData['data'].squat) {
          this.count = returnData['data'].squat.value;
        }

        this.emotion = returnData['data']['emotion'];
        const onlyBreathing =
          exercise.id === 'DHUvBfErdmlpqrK93eQj' ||
          exercise.id === 'v7el9zWmmflMksUoogEd';

        if (exercise.id === 'MeXF3gb9PRJg9befzQDl') {
          this.exerciseCategory = 'abdominal';
          this.physicalExercise = true;
        } else if (
          exercise.id === 'DHUvBfErdmlpqrK93eQj' ||
          exercise.id === 'v7el9zWmmflMksUoogEd'
        ) {
          this.exerciseCategory = 'breathing';
          this.physicalExercise = false;
        } else {
          this.exerciseCategory = 'physical';
          this.physicalExercise = true;
        }

        if (
          this.exerciseCategory === 'breathing' ||
          this.exerciseCategory === 'abdominal'
        ) {
          const sessionData = returnData['data'];
          this.inhale =
            sessionData.breathing_analysis.percz_analy_inhale.value;
          this.exhale =
            sessionData.breathing_analysis.percz_analy_exhale.value;
          this.overall =
            sessionData.breathing_analysis.percz_analy_combined.value;
          this.posture = null;
          this.shoulder = null;
          if (this.exerciseCategory == 'breathing') {
            this.posture = sessionData.posture_analysis.percz_analy.value;
            this.shoulder = sessionData.shoulder_analysis.value;
            const angleData =
              sessionData?.posture_analysis?.interval_count?.value ?? [];
            const totalAngleInstances: number = Object.values(
              angleData
            ).reduce((t: number, n: number) => t + n) as number;
            const angleRanges = Object.keys(angleData).sort((a, b) => {
              if (Number(a.split('-')[1]) < Number(b.split('-')[0]))
                return -1;
              if (Number(a.split('-')[10]) > Number(b.split('-')[0]))
                return 1;
              return 0;
            });
            this.breathingBarChartData = {
              type: 'bar',
              data: {
                labels: angleRanges,
                datasets: [
                  {
                    label: 'Posture Histogram',
                    data: Object.values(angleData).map(
                      (instances: number) =>
                        Math.round((instances / totalAngleInstances) * 100)
                    ),
                  },
                ],
              },
              options: {
                scales: {
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: '%',
                      },
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: 'Angle Range (°)',
                      },
                    },
                  ],
                },
              },
            };
            this.translate
              .get('patientDetails.postureHistogram')
              .subscribe((res: string) => {
                this.breathingBarChartData.data.datasets[0].label = res;
              });
            this.translate
              .get('patientDetails.angleRange')
              .subscribe((res: string) => {
                this.breathingBarChartData.options.scales.xAxes[0].scaleLabel.labelString =
                  res;
              });
          }

          if ('confidence_mean' in sessionData) {
            this.countUnitMeanValue =
              sessionData.confidence_mean.substring(1).split(',')[0] * 100;
            this.countUnitMeanValue = Math.round(this.countUnitMeanValue);
          }

          if (
            sessionData.inhale_duration &&
            sessionData.exhale_duration &&
            sessionData.sets
          ) {
            this.exerciseDetails.inhale_duration =
              sessionData.inhale_duration;
            this.exerciseDetails.exhale_duration =
              sessionData.exhale_duration;
            this.exerciseDetails.sets = sessionData.sets;
            this.exerciseDetails.display = true;
          } else {
            this.exerciseDetails.display = false;
          }
        }

        if (this.physicalExercise) {
          this.aiTrainerDisabled = false;
          this.countDisplayNames = [];
          this.countTotal = [];
          this.countValues = [];
          this.countUnitDisplayNames = [];
          this.countUnitValues = [];
          this.countUnitMeanValue = 0;
          this.degreeDisplayName = [];
          this.degreeMaxValue = [];
          this.degreeMeanValue = [];
          this.dataSources = [];
          this.percentDisplayName = [];
          this.percentValue = [];
          this.problemCountValue = [];
          this.problemDisplayName = [];
          this.problemTotalValue = [];
          this.problemCountValueNotNull = [];
          this.problemDisplayNameNotNull = [];
          this.problemTotalValueNotNull = [];
          this.pieChartLabels = [];
          this.pieChartData = [];
          this.physicalExercise = true;
          Object.entries(returnData['data']).forEach(([key, value]) => {
            if (key === 'problem') {
              Object.entries(value).forEach(([key2, value2]) => {
                this.problemCountValue.push(value2.value);
                this.problemDisplayName.push(value2.display_name);
                this.problemTotalValue.push(value2.total);
                this.pieChartLabels.push(value2.display_name);
                this.pieChartData.push(value2.value);
                if (value2.value > 0) {
                  this.problemCountValueNotNull.push(value2.value);
                  this.problemDisplayNameNotNull.push(value2.display_name);
                  this.problemTotalValueNotNull.push(value2.total);
                }
              });
            }
          });
          for (let key in returnData['data']) {
            let value = returnData['data'][key];
            if (value.unit === 'count' && value.total) {
              this.countDisplayNames.push(value.display_name);
              this.countTotal.push(value.total);
              this.countValues.push(value.value);
            }
            if (key === 'confidence_mean') {
              this.countUnitMeanValue =
                value.substring(1).split(',')[0] * 100;
              this.countUnitMeanValue = Math.round(this.countUnitMeanValue);
            }
            if (value.unit === 'count' && !value.total) {
              this.countUnitDisplayNames.push(value.display_name);
              this.countUnitValues.push(value.value);
            }
            if (value.unit === 'degree') {
              this.degreeDisplayName.push(value.display_name);
              this.degreeMaxValue.push(value.max);
              this.degreeMeanValue.push(value.avg);
              this.preprocessData();
              this.evaluatingText();
              this.preprocessData2();
            }
            if (value.unit === 'percent') {
              this.percentDisplayName.push(value.display_name);
              this.percentValue.push(Math.round(value.value));
            }
          }
          if (
            returnData['data'] &&
            returnData['data']['ai_enabled'] === false
          ) {
            this.aiTrainerDisabled = true;
          }
        }

        this.exerciseResultsError = false;
      } catch (error) {

      }
    }
    if (this.role === 'physiotherapist') {
      this.exerciseFromDay = fromDay;
      this.activeResultTab = 1;
      this.exercise = exercise;
      const day = exercise.start.getDate();
      const month = exercise.start.getMonth() + 1;
      const year = exercise.start.getFullYear();
      const stringDate: string = day + '.' + month + '.' + year;
      this.exerciseDetailsDate = stringDate;

      this.titleDate = stringDate;

      const requestData = {
        patient_uid: this.patientId,
        exercise_id: exercise.id,
        date: stringDate,
      };

      if (exercise.performed) {
        this.exerciseResults = 'exists';

        this.exerciseService.getSessionResults(requestData).subscribe(
          (returnData) => {
            handleSessionResult(returnData)
          },
          (error) => {
            this.exerciseResultsError = true;
          },
          () => {
            this.exerciseResultsLoaded = true;
          }
        );
        if (
          exercise.id === 'MeXF3gb9PRJg9befzQDl' ||
          exercise.id === 'DHUvBfErdmlpqrK93eQj' ||
          exercise.id === 'v7el9zWmmflMksUoogEd'
        ) {
          this.exerciseService.getBreathingPlot(requestData).subscribe(
            (data) => {
              const obj = JSON.parse(data['data']);
              obj.timestamp = this.removeBreakGap(obj.timestamp);
              this.breathingPlotData = obj;
            },
            (error) => {
              this.breathingPlotData = null;
            }
          );
        } else {
          this.breathingPlotData = null;
        }
      } else if (exercise.color === colors.red) {
        this.exerciseResults = 'not-performed';
      } else if (exercise.color === colors.yellow) {
        if (exercise.start > new Date()) {
          this.exerciseResults = 'future-date';
        } else {
          this.exerciseResults = 'today-not-performed';
        }
      }
      this.modalService.open(content, { size: 'lg' });
    } else if (this.role === 'patient') {
      if (exercise.start.toDateString() === this.viewDate.toDateString()) {
        let found = this.todayExercises.find(
          (element) =>
            element.exercise.display_name[this.locale] === exercise.title
        );
        if (found && !found.performed) {
          this.goBack(found);
        }
      } else {
        const previousDate = new Date();
        previousDate.setDate(this.viewDate.getDate() - 1);

        if (exercise.start.toDateString() === previousDate.toDateString()) {
          let found = this.remainingPreviousExercises.find(
            (element) => element.exercise.display_name === exercise.title
          );
          if (found) {
            this.goBack(found);
          }
        }
      }
    }
  }

  getDayDetails({ date, events }, content) {
    if (this.role !== 'physiotherapist') return;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const stringDate: string = day + '.' + month + '.' + year;
    this.titleDate = stringDate;
    if (events) {
      this.exercisesForDay = events.toSorted((a, b) => {
        if (a.performed && !b.performed) return -1;
        if (!a.performed && b.performed) return 1;
        return 0;
      });
    }
    this.modalService.open(content, { size: 'lg' });
  }

  exerciseTextColor(performed) {
    switch (performed) {
      case true:
        return { color: '#155e4b' };
      case false:
        return { color: '#823335' };
      default:
        return { color: '#9b8007' };
    }
  }

  handleExerciseFromDay(exercise, exerciseContent, dayContent) {
    dayContent.dismiss('exercise click');
    this.getExerciseDetails(exercise, exerciseContent, true);
  }

  handleBackToDay(date, exerciseContent, dayContent) {
    exerciseContent.dismiss('back to day');
    this.getDayDetails({ date, events: null }, dayContent);
  }

  removeBreakGap(ts: number[]): number[] {
    let gapSize = 2;
    let started = false;
    return ts.reduce((acc: number[], current: number) => {
      if (
        Math.floor(current) - Math.floor(acc[acc.length - 1]) ===
        gapSize + 1
      ) {
        gapSize++;
      }
      if (
        acc.length > 0 &&
        (Math.floor(current) - Math.floor(acc[acc.length - 1]) == gapSize ||
          started)
      ) {
        acc.push(current - (gapSize - 1));
        started = true;
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
  }

  updateExerciseProgram(fromDelete?: boolean) {
    let deleteProgram = true;
    if (this.calendarComponent.selectedDates.length !== 0) {
      deleteProgram = false;
    }

    if (this.chosenProgram.exercises.length === 0 && deleteProgram) {
      this.deleteExerciseProgram();
    } else {
      if (this.deletedExercisesList.length > 0 && !deleteProgram) {
        this.deletedExercisesList.forEach((exercise) => {
          if (this.chosenProgram.exercises.indexOf(exercise) < 0) {
            this.chosenProgram.exercises.push(exercise);
          }
        });
      }
      const tempDates: string[] = [];
      this.calendarComponent.selectedDates.forEach((date) => {
        date = new Date(date);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const stringDate: string = day + '.' + month + '.' + year;

        tempDates.push(stringDate);
      });

      this.chosenProgram.exercises.forEach((exercise) => {
        exercise.assigned_dates = exercise.assigned_dates.filter((ex) => {
          const [stringDay, stringMonth, stringYear] = ex.date.split('.');
          const tmp = new Date(
            stringYear + '/' + stringMonth + '/' + stringDay
          );
          if (tmp < this.viewDate || ex.performed) {
            return true;
          }
          return false;
        });

        if (!exercise.deleted) {
          tempDates.forEach((tempDate) => {
            let obj = {
              date: tempDate,
              performed: false,
            };
            let sameDates = false;

            this.exercisePrograms.forEach((program) => {
              if (program.program_id !== this.chosenProgram.program_id) {
                program.exercises.forEach((ex) => {
                  if (
                    !ex.deleted &&
                    ex.name === exercise.name &&
                    ex.assigned_dates.some((ob) => ob.date === obj.date)
                  ) {
                    sameDates = true;
                  }
                });
              }
            });
            const exists = exercise.assigned_dates.some(
              (ob) => ob.date === obj.date
            );

            if (!exists && !sameDates) {
              exercise.assigned_dates.push(obj);
            }
          });
        }
      });

      const requestData = {
        patient_uid: this.patientId,
        program_id: this.chosenProgram.program_id,
        exercises: this.chosenProgram.exercises,
      };

      this.exerciseService.updateExerciseProgram(requestData).subscribe(
        (data) => { },
        (error) => {
          if (!fromDelete) {
            this.translate
              .get('toastr.updateExerciseProgramError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          } else {
            this.translate
              .get('toastr.deleteExerciseProgramError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
          }
        },
        () => {
          if (!fromDelete) {
            this.translate
              .get('toastr.updateExerciseProgramSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          } else {
            this.translate
              .get('toastr.deleteExerciseProgramSuccess')
              .subscribe((res: string) => {
                this.toastr.success(res);
              });
          }
          this.storeService.refreshData$.next(true);
          this.ngZone.run(() => {
            this.dataLoaded = false;
          });
          this.clearVariables();

          this.getAllExercisePrograms();
        }
      );
    }
  }

  deleteExerciseProgram() {
    let deleteProgram = true;
    this.chosenProgram.exercises = this.chosenProgram.exercises.map((e) => ({
      ...e,
      deleted: true,
    }));

    // const flattened = (this.chosenProgram.exercises as []).reduce((prev: any[], curr: any, index) => {
    //   return [...prev, ...curr.assigned_dates]
    // }, []);
    // deleteProgram = !(flattened.some(e => e.performed))
    // this.chosenProgram.exercises.forEach(exercise => {
    //   exercise.deleted = true;
    // });

    const dates = this.chosenProgram.exercises[0].assigned_dates.map((d) => {
      return { ...d, date: new Date(d.date.split('.').reverse().join('.')) };
    });
    const sortedDates = dates.sort((a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });
    if (sortedDates[0].date < this.viewDate) {
      deleteProgram = false;
    }

    if (deleteProgram) {
      const onError = (error) => {
        this.translate
          .get('toastr.deleteExerciseProgramError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      };
      const onCompleted = () => {
        this.translate
          .get('toastr.deleteExerciseProgramSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.ngZone.run(() => {
          this.dataLoaded = false;
        });
        this.clearVariables();
        this.storeService.refreshData$.next(true);
        this.getAllExercisePrograms();
      };
      this.exerciseService
        .deleteExerciseProgram({
          patient_uid: this.patientId,
          program_id: this.chosenProgram.program_id,
        })
        .subscribe((data) => { }, onError, onCompleted);
    } else {
      this.storeService.refreshData$.next(true);
      this.calendarComponent.selectedDates = [];
      this.updateExerciseProgram(true);
    }
    this.todayExercises = [];
    this.activeTreatmentSummary.days_left = 0;
  }

  // Helper functions

  goBack(exercise?) {
    if (exercise) {
      if (
        exercise.date === this.smallestDate &&
        this.todayExercises.length === this.todayAllExercises.length
      ) {
        exercise.first = true;
      } else {
        exercise.first = false;
      }
      this.goBackEvent.emit(exercise);
    } else {
      this.goBackEvent.emit();
    }
    this.router.navigate(['dashboard', this.me.role]);
  }

  openRemoveExerciseModal(content, exerciseProgram, exercise) {
    this.chosenProgram = exerciseProgram;
    this.chosenExercise = exercise;
    this.modalService.open(content);
  }

  openEditExerciseModal(content, exercise) {
    this.chosenExercise = exercise;
    if (this.chosenExercise.category === 'diaphragm') {
      this.inhaleDuration =
        this.patient.exerciseParameters.diaphragmaticBreathing.inhaleDuration;
      this.exhaleDuration =
        this.patient.exerciseParameters.diaphragmaticBreathing.exhaleDuration;
    } else if (this.chosenExercise.category === 'lip') {
      this.inhaleDuration =
        this.patient.exerciseParameters.lipBreathing.inhaleDuration;
      this.exhaleDuration =
        this.patient.exerciseParameters.lipBreathing.exhaleDuration;
    }
    this.modalService.open(content);
  }
  getBookedDates(editedProgram) {
    const booked_days = [];

    for (const ex of editedProgram.exercises) {
      for (const ad of ex.assigned_dates) {
        if (booked_days.indexOf(ad.date) < 0) {
          const adAsDate = new Date(ad.date.split('.').reverse().join('.'));
          if (adAsDate >= this.viewDate) booked_days.push(ad.date);
        }
      }
    }
    //----
    return booked_days;
  }
  openAssignModal(editedProgram, content) {
    //exerciseProgramsCopy
    this.chosenProgram = editedProgram;
    const booked_days = this.getBookedDates(editedProgram);
    //booked_days should be applied as assigned_dates for the new added exercise

    this.availableExercises = [];
    this.availableExercises.push.apply(
      this.availableExercises,
      this.exercisesList
    );
    for (const pro of this.exerciseProgramsCopy) {
      for (const ex of pro.exercises) {
        for (const assigned_date of ex.assigned_dates) {
          if (booked_days.indexOf(assigned_date.date) > -1) {
            //since the ex is in this date then execlude it from available exercises

            const index = this.availableExercises.findIndex((exercise) => {
              return exercise.exercise_id == ex.exercise_id;
            });
            if (index > -1) this.availableExercises.splice(index, 1);
            break;
          }
        }
      }
    }

    //-------
    //this.getAvailableExercises();
    this.modalService.open(content);
  }

  // getAvailableExercises() {
  //   //this.physiotherapistExercises.forEach(exercise => {
  //   //if (!(this.patient.exerciseList.find(ex => ex === exercise.id))) {
  //   this.availableExercises.push.apply(this.availableExercises, this.exercisesList);
  //   //}
  //   //});
  //   if (this.availableExercises.length === 0) {
  //     this.assignedExercisesExist = true;
  //   }
  // }

  clearChosenExercise() {
    this.chosenExercise = {} as Exercise;
    this.availableExercises = [];
    this.assignedExercisesExist = false;
    this.inhaleDuration = null;
    this.exhaleDuration = null;
  }

  createProgramCalendarEvents(exerciseProgram: any, index: number = -1) {
    const results = [];
    exerciseProgram.exercises.forEach((exercise) => {
      exercise.assigned_dates.forEach((assigned) => {
        try {
          const stringDay = assigned.date.split('.')[0];
          const stringMonth = assigned.date.split('.')[1];
          const stringYear = assigned.date.split('.')[2];

          const date = new Date(
            stringYear + '/' + stringMonth + '/' + stringDay
          );
          let currentTime = new Date();
          date.setHours(0, 0, 0, 0);
          currentTime.setHours(0, 0, 0, 0);
          let color = colors.yellow;
          let performed: boolean;

          if (date <= currentTime) {
            if (assigned.performed) {
              color = colors.green;
              performed = true;
            } else {
              if (date < currentTime) {
                color = colors.red;
                performed = false;
              }
            }
          }

          const exerciseEvent = {
            start: date,
            title: exercise.display_name
              ? exercise.display_name
              : exercise.name,
            color: color,
            allDay: true,
            id: exercise.id,
            performed: performed,
          };
          results.push(exerciseEvent);
        } catch (err) { }
      });
    });
    if (index > -1) {
      this.programCalendarDates[index] = results;
    } else this.programCalendarDates.push(results);
  }

  handleEvent(content, action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modalService.open(content, { size: 'lg' });
    this.chosenEvent = this.patient.exercisePerformance.find(
      (ex) => ex.date.toDate().toDateString() === event.start.toDateString()
    );
    if (
      event.title === 'Lippenbremse' ||
      event.title === 'Büzük Dudak Nefesi Egzersizi'
    ) {
      this.eventType = 'lip';
    }
    if (event.title === 'Bauchatmung' || event.title === 'Diyafram Egzersizi') {
      this.eventType = 'diaphragm';
    }
    this.titleDate = this.chosenEvent.date.toDate().toDateString().slice(0, 10);

    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  customEvaluationClass(condition) {
    if (condition < 60) {
      return 'badge badge-success';
    } else if (60 <= condition && condition < 80) {
      return 'badge badge-warning';
    } else if (condition >= 80) {
      return 'badge badge-danger';
    }
  }

  // input should be month + 1 (real number of month)
  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  openModal(content, program?, component?) {
    if (program) {
      this.chosenProgram = program;
    }
    if (component) {
      this.calendarComponent = component;
      // this.newDates = this.tempExercises;
    }
    return this.modalService.open(content, { size: 'lg' });
  }

  closeCreateProgramMode() {
    this.createProgramMode = false;
    this.ngZone.run(() => {
      this.dataLoaded = false;
    });
    this.exetractPatientDetails();
  }

  startExerciseClicked(content, exercise) {
    this.exerciseToStart = exercise;
    this.chosenExercise = exercise.exercise;

    this.openModal(content);
    // this.exerciseMode = true;
  }

  checkTodayExercises(parentCall?) {
    let result = true;
    if (this.todayExercises.length > 0) {
      this.todayExercises.forEach((exercise) => {
        if (!exercise.performed) {
          result = false;
        }
      });
    }

    //Emit the results from Today Exercises
    this.remainingExercise.emit(result);
    if (parentCall) {
      return result;
    }

    if (this.remainingPreviousExercises.length > 0) {
      result = false;
    }
    return result;
  }

  deleteExercise(programIndex, exerciseIndex) {
    this.deletedExercisesList.push(
      this.exercisePrograms[programIndex].exercises[exerciseIndex]
    );
    this.exercisePrograms[programIndex].exercises.splice(exerciseIndex, 1);
  }

  activeEditMode(index, content?) {
    if (this.editMode[index]) {
      this.openModal(content);
    } else {
      this.editMode[index] = true;
    }
  }

  cancelEditProgram() {
    //new for add new single exercise
    if (this.temp_new_added_exercises.length > 0) {
      this.chosenProgram.exercises.splice(
        this.chosenProgram.exercises.length -
        this.temp_new_added_exercises.length -
        1,
        this.temp_new_added_exercises.length
      );

      this.temp_new_added_exercises = [];
      const founded = this.allExercisePrograms.find(
        (p) => p.program_id == this.chosenProgram.program_id
      );

      if (founded) {
        founded.exercises = [...this.chosenProgram.exercises];
      }
      this.events = [];
      this.todayAllExercises = [];
      this.todayExercises = [];
      this.remainingPreviousExercises = [];
      for (const program of this.allExercisePrograms) {
        for (const exercise of program.exercises) {
          buildCalendarEvents(
            exercise,
            this.locale,
            this.viewDate,
            this.remainingPreviousExercises,
            this.events,
            this.todayAllExercises,
            this.todayExercises
          );
          const r = addAlldates(
            exercise.assigned_dates,
            this.allExercisesDates,
            this.todayExercises,
            this.translate,
            this.activeTreatmentSummary
          );
          for (const k of Object.keys(r)) {
            this[k] = r[k];
          }
        }
        //}
      }
      this.calendarEvents = this.events;
      this.programCalendarDates = [];
      this.exercisePrograms.forEach((program, index) => {
        this.createProgramCalendarEvents(program, index);
        if (program.program_id == this.chosenProgram.program_id) {
          this.ngZone.run(() => {
            this.updated = false;
          });
          const tempEvents = this.calendarEvents;
          this.singleProgramEvents = [];
          this.singleProgramEvents = tempEvents;
          this.programCalendarDates[index].forEach((element) => {
            this.singleProgramEvents.forEach((value) => {
              if (value.start.toDateString() === element.start.toDateString()) {
                if (
                  element.title === value.title ||
                  element.title[this.locale] === value.title ||
                  element.title === value.title[this.locale] ||
                  element.title[this.locale] === value.title[this.locale]
                ) {
                  if (
                    value.color !== colors.green &&
                    value.color !== colors.red
                  ) {
                    value.color = colors.blue;
                  }
                }
              }
            });
          });
          this.ngZone.run(() => {
            setTimeout(() => {
              this.updated = true;
            }, 100);
          });
        }
      });
    }
    //end
    this.chosenProgram = null;
    this.newDates = [];
    this.tempExercises = [];
    this.deletedExercisesList = [];
    this.editMode.forEach((value, index) => {
      this.editMode[index] = false;
    });

    this.exerciseProgramsCopy = structuredClone(this.exercisePrograms);
  }

  deleteProgramModal(content, program) {
    this.chosenProgram = program;
    this.modalService.open(content, { size: 'lg' });
  }

  getProgressBarColor(value: number) {
    if (value < 30) {
      return '#833234';
    } else if (value < 70) {
      return '#E19F20';
    } else {
      return '#008A5D';
    }
  }

  clearVariables() {
    this.chosenProgram = null;
    this.programCalendarDates = [];
    this.newDates = [];
    this.tempExercises = [];
    this.deletedExercisesList = [];
    this.editMode.forEach((value, index) => {
      this.editMode[index] = false;
    });
  }

  changeProgramTab(num: number) {
    this.activeProgramTab = num;
    if (this.activeProgramTab === 0) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog) => prog.active === true
      );
    } else if (this.activeProgramTab === 1) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog) => !prog.active && !prog.deleted
      );
    } else if (this.activeProgramTab === 2) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog) => prog.deleted
      );
    }

    this.initializeCollapsableArray();
    this.initializeEditableMode(this.exercisePrograms);
    this.addAssignedDatesPerProgram(this.exercisePrograms);
    this.exercisePrograms.forEach((program, index) => {
      this.findSmallestAndLargestDatePerProgram(index);
      this.createProgramCalendarEvents(program);
    });
    // setTimeout(() => {
    //   this.programsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }, 10);
    this.exerciseProgramsCopy = structuredClone(this.exercisePrograms);
  }

  editModeOn() {
    this.edit = true;
    if (typeof this.patientDetails.personal_information.age == 'string') {
      this.age = this.changeDateFormat(
        this.patientDetails.personal_information.age
      );
      this.personalInformationGroup.get('age').setValue(this.age);
    }
  }

  initializePersonalInfoForm() {
    this.personalInformationGroup = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.personalInfoModel.firstName, [
        Validators.required,
        Validators.pattern(this.alphabetPattern),
      ]),
      lastName: new UntypedFormControl(this.personalInfoModel.lastName, [
        Validators.required,
        Validators.pattern(this.alphabetPattern),
      ]),
      age: new UntypedFormControl(this.age, [
        Validators.required,
        birthdateValidation(),
      ]),
      email: new UntypedFormControl(this.personalInfoModel.email, [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
    });
  }

  openSaveModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  saveEditPersonalInfo() {
    const requestData = {
      personal_information: {
        age: this.changeDateFormatToBackend(
          this.personalInformationGroup.get('age').value
        ),
        email: this.personalInformationGroup.get('email').value,
        first_name: this.personalInformationGroup.get('firstName').value,
        last_name: this.personalInformationGroup.get('lastName').value,
      },
      patient_uid: this.patientId,
    };

    this.savingPatientDetails = true;
    this.userService.editPersonalInfo(requestData).subscribe({
      error: (e) => {
        this.translate
          .get('toastr.patientDetailsError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
        this.savingPatientDetails = false;
      },
      complete: () => {
        this.age = calculateAge(this.patientDetails.personal_information.age);
        Object.assign(
          this.patientDetails.personal_information,
          requestData.personal_information
        );
        this.edit = false;
        this.savingPatientDetails = false;
        this.detailsEdited = false;
      },
    });
  }

  updateEditPersonalFormControls() {
    if (typeof this.patientDetails.personal_information.age == 'string') {
      this.age = calculateAge(this.patientDetails.personal_information.age);
      this.personalInformationGroup.get('age').setValue(this.age);
    }
    this.personalInformationGroup
      .get('email')
      .setValue(this.patientDetails.personal_information.email);
    this.personalInformationGroup
      .get('firstName')
      .setValue(this.patientDetails.personal_information.first_name);
    this.personalInformationGroup
      .get('lastName')
      .setValue(this.patientDetails.personal_information.last_name);
  }

  cancelEditPersonalInfo() {
    this.edit = false;
    this.detailsEdited = false;
    this.activeTab = this.tempActiveTab;
    this.updateEditPersonalFormControls();
  }

  changeDateFormatToBackend(date) {
    // from 1990-12-12 to 12.12.1990
    var splittedDate = date.split('-');
    var newDate =
      parseInt(splittedDate[2]) +
      '.' +
      parseInt(splittedDate[1]) +
      '.' +
      parseInt(splittedDate[0]);
    return newDate;
  }

  changeDateFormat(date) {
    //2020-01-11 year-month-day
    // from 12.12.1990 to 1990-12-12
    var splittedDate = date.split('.');
    var monthInt = parseInt(splittedDate[1]);
    var DayInt = parseInt(splittedDate[0]);
    var monthString;
    var DayString;
    if (monthInt < 10) {
      monthString = '0' + monthInt;
    } else {
      monthString = '' + monthInt;
    }
    if (DayInt < 10) {
      DayString = '0' + DayInt;
    } else {
      DayString = '' + DayInt;
    }
    var newDate = splittedDate[2] + '-' + monthString + '-' + DayString;
    return newDate;
  }

  changeActiveTab(content, activeTab) {
    this.tempActiveTab = activeTab;
    if (this.edit) {
      this.modalService.open(content, { size: 'lg' });
    } else {
      this.activeTab = activeTab;
    }
  }

  //   reloadCurrentRoute() {
  //     let currentUrl = this.router.url;
  //     this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
  //         this.router.navigate([currentUrl]);
  //     });
  // }

  reloadCurrentPage() {
    this.ngOnInit();
  }

  convertToPercentage(number, total) {
    var percentage = (number * 100) / total;
    return this.getDotColor(percentage);
  }

  getDotColor(percentage) {
    if (percentage >= 0 && percentage < 40) {
      return '#823335';
    }
    if (percentage >= 40 && percentage < 70) {
      return '#f58602';
    } else {
      return '#2FC19A';
    }
  }

  preprocessData2() {
    this.dataSources = [];
    for (let i = 0; i < this.degreeDisplayName.length; i++) {
      var color = [];
      var dial = [];
      if (!this.checkIfItIsRight(this.degreeDisplayName[i])) {
        color = [
          {
            minValue: '-90',
            maxValue: -this.degreeMaxValue[i],
            code: '#384f6b',
          },
          {
            minValue: -this.degreeMaxValue[i],
            maxValue: '0',
            code: '#2FC19A',
          },
          {
            minValue: '0',
            maxValue: '90',
            code: '#384f6b',
          },
        ];
        dial = [
          {
            value: 0 - this.degreeMeanValue[i],
            showValue: '0',
            valueX: '260',
            valueY: '220',
            tooltext: this.averageDegree + ' :' + this.degreeMeanValue[i],
            rearExtension: '15',
          },
        ];
      } else {
        color = [
          {
            minValue: '-90',
            maxValue: '0',
            code: '#384f6b',
          },
          {
            minValue: '0',
            maxValue: this.degreeMaxValue[i],
            code: '#2FC19A',
          },
          {
            minValue: this.degreeMaxValue[i],
            maxValue: '90',
            code: '#384f6b',
          },
        ];
        dial = [
          {
            value: this.degreeMeanValue[i],
            showValue: '0',
            valueX: '260',
            valueY: '220',
            tooltext: this.averageDegree + ' : $value',
            rearExtension: '15',
          },
        ];
      }

      var dataSource = {
        chart: {
          lowerLimit: '-90',
          upperLimit: '90',
          numberSuffix: '\xB0',
          theme: 'fusion',
          majorTMColor: '#384f6b',
        },
        colorRange: {
          color: color,
        },
        annotations: {
          origw: '450',
          origh: '300',
          autoscale: '1',
          showBelow: '0',
          groups: [
            {
              id: 'arcs',
              items: [
                {
                  id: 'national-cs-text',
                  type: 'Text',
                  color: '#000000',
                  label:
                    this.averageDegree +
                    ': ' +
                    Math.round(this.degreeMeanValue[i] * 10) / 10 +
                    '\xB0',
                  fontSize: '16',
                  align: 'left',
                  x: '$chartCenterX + 7',
                  y: '$chartEndY - 35',
                },
                {
                  id: 'state-cs-text',
                  type: 'Text',
                  color: '#2FC19A',
                  label:
                    this.maximumDegree +
                    ': ' +
                    Math.round(this.degreeMaxValue[i] * 10) / 10 +
                    '\xB0',
                  fontSize: '16',
                  align: 'right',
                  x: '$chartCenterX - 7',
                  y: '$chartEndY - 35',
                },
              ],
            },
          ],
        },
        dials: {
          dial: dial,
        },
      };
      this.dataSources.push(dataSource);
    }
  }

  preprocessData() {
    this.single = [];
    this.DegreesArrays = [];
    for (let i = 0; i < this.degreeDisplayName.length; i++) {
      var avgDegree = {
        name:
          this.removeFirstWord(this.degreeDisplayName[i]) + ' Average degree',
        value: this.degreeMeanValue[i],
      };
      var maxDegree = {
        name: this.removeFirstWord(this.degreeDisplayName[i]) + ' Max degree',
        value: this.degreeMaxValue[i],
      };

      this.single.push(avgDegree);
      this.single.push(maxDegree);
      this.DegreesArrays.push(this.single);
      this.single = [];
    }
  }

  evaluatingText() {
    this.labelsArray = [];
    for (var i = 0; i < this.degreeMaxValue.length; i++) {
      var averageLabel = 'Average degree: ' + this.degreeMeanValue[i];
      var maxLabel = 'Max degree: ' + this.degreeMaxValue[i];
      var label = averageLabel + ' / ' + maxLabel;
      this.labelsArray.push(label);
    }
  }

  removeFirstWord(str) {
    const indexOfSpace = str.indexOf(' ');

    if (indexOfSpace === -1) {
      return '';
    }

    return str.substring(indexOfSpace + 1);
  }

  valueFormating(value) {
    return value;
  }

  checkIfItIsRight(text) {
    if (text.includes('right')) {
      return true;
    } else {
      return false;
    }
  }

  getExerciseGif(event, exercise) {
    if (
      exercise.category === 'breathing' ||
      exercise.category === 'Breathing' ||
      exercise.exercise?.category === 'breathing' ||
      exercise.exercise?.category === 'Breathing'
    ) {
      event.target.src = '../../../assets/gifs/breath.gif';
    } else if (
      exercise.category === 'lower_body' ||
      exercise.category === 'Lower body' ||
      exercise.exercise?.category === 'lower_body' ||
      exercise.exercise?.category === 'Lower body'
    ) {
      event.target.src = '../../../assets/gifs/squat.gif';
    } else {
      event.target.src = '../../../assets/images/Breathment Logo_rev_white.png';
    }
  }

  createCollapsableExercises() {
    for (let i = 0; i < this.todayExercises.length; i++) {
      this.todayExercisesCollapsed[i] = true;
    }
    for (let i = 0; i < this.remainingPreviousExercises.length; i++) {
      this.previousExercisesCollapsed[i] = true;
    }
  }

  getProgramLength(program) {
    let count: number = 0;
    if (this.activeProgramTab === 2) {
      return program.exercises.length;
    }
    program.exercises.forEach((exercise) => {
      if (!exercise.deleted) {
        count++;
      }
    });
    return count;
  }
  addTask() {
    this.addNewTask = true;
  }
  cancelNewTask() {
    this.addNewTask = false;
    this.addNewTaskAction = false;
    this.newTaskFormGroup.reset();
  }

  dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control?.value) {
      const today = new Date().setHours(0, 0, 0, 0);
      const dateToCheck = new Date(control.value).setHours(0, 0, 0, 0);
      if (dateToCheck < today) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  timeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control?.value) {
      const today = new Date();
      const selectedDate = new Date(control.parent.get('date').value);
      if (selectedDate.toDateString() === today.toDateString()) {
        const timeControl = control.parent.get('time');
        if (
          Number(timeControl.value.slice(0, 2)) < today.getHours() ||
          (Number(timeControl.value.slice(0, 2)) === today.getHours() &&
            Number(timeControl.value.slice(2)) < today.getMinutes())
        ) {
          return { invalidTime: true };
        }
      }
    }
    return null;
  }

  checkIfDateIsEntered(control: UntypedFormControl): { [s: string]: boolean } {
    if (control.parent) {
      let timeControl = control.parent.get('time');
      let dateControl = control.parent.get('date');

      if (timeControl.value != null && dateControl.value === null) {
        dateControl.setErrors({ timeAndDateRequired: true });
      } else {
        dateControl.setErrors(null);
        dateControl.updateValueAndValidity();
      }
    }
    return null;
  }

  intializeNewTasksForm() {
    this.newTaskFormGroup = new UntypedFormGroup({
      title: new UntypedFormControl(this.newTaskModel.title, [
        Validators.required,
      ]),
      details: new UntypedFormControl(this.newTaskModel.details, []),
      date: new UntypedFormControl(this.newTaskModel.date, [
        this.dateValidator,
      ]),
      time: new UntypedFormControl(this.newTaskModel.time, [
        this.checkIfDateIsEntered.bind(this),
        this.timeValidator.bind(this),
      ]),
      reminder: new UntypedFormControl(this.newTaskModel.reminder, []),
    });
  }

  addNewTaskIntoTaks() {
    this.addNewTaskAction = true;
    if (this.newTaskFormGroup.valid) {
      this.newTaskModel.title = this.newTaskFormGroup.get('title').value;
      this.newTaskModel.details = this.newTaskFormGroup.get('details').value;
      this.newTaskModel.date = this.newTaskFormGroup.get('date').value;
      this.newTaskModel.time = this.newTaskFormGroup.get('time').value;
      this.newTaskModel.reminder = this.newTaskFormGroup.get('reminder').value;

      var current_date = new Date();
      var current_date_stringify = this.datepipe.transform(
        current_date,
        'd.M.yyyy h:mm'
      );

      var transformedDate = this.newTaskModel.date
        ? this.datepipe
          .transform(this.newTaskModel.date, 'dd.MM.yyyy')
          .replace(/\b0/g, '')
        : this.newTaskModel.date;
      var transformedTime = this.newTaskModel.time
        ? this.unformatTime(this.newTaskModel.time)
        : this.newTaskModel.time;
      var reminder = this.newTaskModel.reminder
        ? this.newTaskModel.reminder
        : false;
      const requestData = {
        patient_uid: this.patientId,
        title: this.newTaskModel.title,
        details: this.newTaskModel.details ? this.newTaskModel.details : '',
        date: transformedDate ? transformedDate : '',
        time: transformedTime ? transformedTime : '',
        reminder: reminder,
        completed: false,
        created_at: current_date,
      };

      this.userService.addNewTask(requestData).subscribe(
        (res) => {
          this.translate.get('toastr.taskAdded').subscribe((res: string) => {
            this.toastr.success(res);
          });
          this.getAllTasks();
          this.addNewTask = false;
          this.newTaskFormGroup.reset();
        },
        (error) => {
          this.translate.get('toastr.taskError').subscribe((res: string) => {
            this.toastr.error(res);
          });
          this.addNewTask = false;
          this.newTaskFormGroup.reset();
        }
      );
    }
  }
  changeTaskCompletness(task) {
    task.completed = !task.completed;
    const requestData = {
      task_id: task._id,
      title: task.title,
      details: task.details ? task.details : '',
      date: task.date ? task.date : '',
      time: task.time ? task.time : '',
      reminder: task.reminder,
      completed: task.completed,
    };
    this.userService.updateTask(requestData).subscribe(
      (res) => {
        this.translate
          .get('toastr.taskUpdatedSucc')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.getAllTasks();
      },
      (error) => {
        this.translate.get('toastr.taskUpdatedErr').subscribe((res: string) => {
          this.toastr.error(res);
        });
        this.getAllTasks();
      }
    );
  }

  deleteCertainTask(task, content) {
    this.deletedTask = task;
    this.modalService.open(content, { size: 'lg' });
  }

  deleteTask() {
    const requestData = {
      task_id: this.deletedTask._id,
    };
    this.userService.deleteTask(requestData).subscribe(
      (res) => {
        this.translate
          .get('toastr.taskDeletedSucc')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.getAllTasks();
      },
      (error) => {
        this.translate.get('toastr.taskDeletedErr').subscribe((res: string) => {
          this.toastr.error(res);
        });
        this.getAllTasks();
      }
    );
  }
  editTask(task) {
    task.edit = true;
    this.copyOfEditTask = new newTaskModel();
    this.copyOfEditTask.title = task.title;
    this.copyOfEditTask.details = task.details;
    this.copyOfEditTask.date = task.date;
    this.copyOfEditTask.time = task.time;
    this.copyOfEditTask.reminder = task.reminder;
    this.copyOfEditTask.completed = task.completed;

    if (task.date) {
      task.date = this.formateDate(task.date);
    }
    if (task.time) {
      task.time = this.formateTime(task.time);
    }
    this.updatedTask = task;
  }

  formateDate(date) {
    //1.1.2022
    //to "2019-07-22"
    var splittedDate = date.split('.');
    var formatedDate = new Date();
    formatedDate.setFullYear(splittedDate[2]);
    formatedDate.setMonth(parseInt(splittedDate[1]) - 1);
    formatedDate.setDate(splittedDate[0]);
    let newFormatedDate = this.datepipe.transform(formatedDate, 'yyyy-MM-dd');
    return newFormatedDate;
  }

  formateTime(time) {
    //4:30
    //04:30
    var splittedTime = time.split(':');
    var hours = splittedTime[0];
    var mins = splittedTime[1];
    if (parseInt(splittedTime[0]) < 10) {
      hours = '0' + hours;
    }

    var formattedTime = hours + ':' + mins;
    return formattedTime;
  }

  unformatTime(time) {
    var splittedTime = time.split(':');
    var hours = splittedTime[0];
    var mins = splittedTime[1];
    if (parseInt(splittedTime[0]) < 10) {
      hours = parseInt(splittedTime[0]);
    }

    var formattedTime = hours + ':' + mins;
    return formattedTime;
  }

  validDateTime(date, time): Boolean {
    let today = new Date();
    const selectedDate = new Date(date);
    if (selectedDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
      return false;
    }

    today = new Date();
    if (selectedDate.toDateString() === today.toDateString()) {
      if (
        Number(time.slice(0, 2)) < today.getHours() ||
        (Number(time.slice(0, 2)) === today.getHours() &&
          Number(time.slice(2)) < today.getMinutes())
      ) {
        return false;
      }
    }

    return true;
  }

  editCurrentTask() {
    if (!this.validDateTime(this.updatedTask.date, this.updatedTask.time)) {
      this.translate
        .get('errorMessages.validValue')
        .subscribe((res: string) => {
          this.toastr.error(res);
        });
      return;
    }

    var transformedDate = this.updatedTask.date
      ? this.datepipe
        .transform(this.updatedTask.date, 'dd.MM.yyyy')
        .replace(/\b0/g, '')
      : this.updatedTask.date;
    var transformedTime =
      this.updatedTask.time && this.updatedTask.date
        ? this.unformatTime(this.updatedTask.time)
        : null;

    const requestData = {
      task_id: this.updatedTask._id,
      title: this.updatedTask.title,
      details: this.updatedTask.details ? this.updatedTask.details : '',
      time: transformedTime ? transformedTime : '',
      date: transformedDate ? transformedDate : '',
      reminder: this.updatedTask.reminder,
      completed: this.updatedTask.completed,
    };

    this.userService.updateTask(requestData).subscribe(
      (res) => {
        this.translate
          .get('toastr.taskUpdatedSucc')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.getAllTasks();
      },
      (error) => {
        this.translate.get('toastr.taskUpdatedErr').subscribe((res: string) => {
          this.toastr.error(res);
        });
        this.getAllTasks();
      }
    );
  }
  cancelEdit() {
    this.updatedTask.title = this.copyOfEditTask.title;
    this.updatedTask.details = this.copyOfEditTask.details;
    this.updatedTask.date = this.copyOfEditTask.date;
    this.updatedTask.time = this.copyOfEditTask.time;
    this.updatedTask.reminder = this.copyOfEditTask.reminder;
    this.updatedTask.completed = this.copyOfEditTask.completed;
    this.updatedTask.edit = false;
  }
  openEditModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  activityWeekChanged() { }

  scrollToPrograms() {
    this.programsSection.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  // goToSurvey() {
  //   this.perfOverviewComponent.openSurveyModal();
  // }

  // goToActivity() {
  //   this.perfOverviewComponent.openActivityModal();
  // }

  // goToPerfOverview() {
  //   this.perfOverviewComponent.openOverviewModal();
  // }

  openCreateAppointmentModal(content) {
    this.appointmentDateSelected = false;
    this.selectedAppointmentSlot = null;
    this.mainCalendar.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      this.modalService.open(content, {
        size: 'lg',
        windowClass: 'bubble-modal',
        centered: true,
      });
    }, 600);
  }

  appointmentSelect(selection) {
    this.appointmentDateSelected = true;
    this.selectedAppointmentSlot = selection;
  }

  createAppointment() {
    const dto: any = {
      patient_uid: this.patientId,
      date: this.selectedAppointmentSlot.date,
      time: this.selectedAppointmentSlot.time,
    };
    if (this.newAppointmentNote) {
      dto.note = this.newAppointmentNote;
    }
    this.userService.createAppoinment(dto).subscribe({
      next: (resp) => {
        this.dataService.updateAppointments();
        setTimeout(() => {
          this.appointments = this.dataService
            .getPatientAppointments(this.patientId)
            .map((a) => {
              return { ...a, date: new Date(a.date) };
            });
        }, 1000);
        this.translate
          .get('patientDetails.appointment.createSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      error: (err) => {
        this.translate
          .get('patientDetails.appointment.createError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
    });
    this.selectedAppointmentSlot = null;
    this.newAppointmentNote = '';
    this.appointmentDateSelected = false;
  }

  openAppointmentModal(appointment, modal) {
    this.appointmentDateSelected = false;
    this.appointmentNoteEdited = false;
    this.selectedAppointment = {
      ...appointment,
      formated_date: DateTime.fromISO(appointment.appointment_dt).toFormat('yyyy-MM-dd'),
      formated_time: DateTime.fromISO(appointment.appointment_dt).toFormat('HH:mm'),
    };
    this.modalService.open(modal, { size: 'lg' });
  }

  updateAppointment() {
    const dto: any = {
      appointment_id: this.selectedAppointment._id,
      update_data: {},
    };
    if (this.appointmentDateSelected) {
      dto.update_data.date = this.selectedAppointmentSlot.date;
      dto.update_data.time = this.selectedAppointmentSlot.time;
    }
    if (this.appointmentNoteEdited) {
      dto.update_data.note = this.selectedAppointment.note;
    }
    this.userService.updateAppointment(dto).subscribe({
      next: () => {
        this.dataService.updateAppointment(dto.appointment_id, dto.update_data);
        if (dto.update_data.date) {
          dto.update_data.date = new Date(dto.update_data.date);
        }
        Object.assign(this.selectedAppointment, dto.update_data);
        this.translate
          .get('patientDetails.appointment.updateSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      error: () => {
        this.translate
          .get('patientDetails.appointment.updateError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
    });
  }

  cancelAppointment() {
    this.userService
      .deleteAppointment({ appointment_id: this.selectedAppointment._id })
      .subscribe({
        next: () => {
          this.dataService.removeAppointment(this.selectedAppointment._id);
          this.appointments = this.appointments.filter(
            (a) => a._id !== this.selectedAppointment._id
          );
          this.translate
            .get('patientDetails.appointment.cancelSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        },
        error: () => {
          this.translate
            .get('patientDetails.appointment.cancelError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        },
      });
  }
}
