<section class="slice slice-lg pt-3">
    <div class="container-fluid">
        <div *ngIf="!dataLoaded" class="full-screen-center">
            <div class="text-primary" role="status">
                <img src="../../../assets/Logo/spinner-border.gif" alt="Spinner" id="spinner">
            </div>
        </div>


        <section *ngIf="!exerciseMode" class="slice slice-lg pt-3 px-5">
            <div *ngIf="dataLoaded && !createProgramMode" class="container-fluid">
                <div class=" col-md-12 col-sm-12">
                    <div class="d-flex align-items-center justify-content-between">

                        <ng-container *ngIf="currentRoute !== '/dashboard/patient'">
                            <div class="text-end">
                                <button type="button" class="btn btn-warning btn-circle btn-sm"
                                    (click)="openModal(InformationModal);">
                                    <i class="text-end" class="fas fa-question" style="color: white"></i>
                                </button>
                            </div>
                        </ng-container>
                    </div>

                </div>
                <div class="row row-grid justify-content-between">


                    <div class="col-12 mb-3" #mainCalendar>
                        <app-calendar lang="{{locale}}" [role]="role" [functionality]="'patient-details'"
                            [assignedEvents]="calendarEvents" [patientAppointments]="appointments"
                            (exerciseClicked)="getExerciseDetails($event, calendarEventModal)"
                            (dayClicked)="getDayDetails($event, calendarDayModal)"
                            (viewAppointmentClick)="openAppointmentModal($event, viewAppointmentModal)"
                            (updateAppointmentClick)="openAppointmentModal($event, updateAppointmentModal)"
                            (cancelAppointmentClick)="openAppointmentModal($event, cancelAppointmentModal)"></app-calendar>

                    </div>
                </div>
            </div>

            <div *ngIf="dataLoaded && !createProgramMode && role === 'patient'" class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 m-0">
                    <div class="mt-3 mb-3">
                        <h3>
                            {{ "createExerciseProgram.availableExercises" | translate }}
                        </h3>
                    </div>

                    <div *ngIf="checkTodayExercises()" class="card">
                        <div class="d-flex p-4 justify-content-center">
                            <div>
                                <p class="text-muted mb-2">
                                    {{ "patientDetails.noExercisesToday" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="todayExercises.length > 0">
                        <div *ngFor="let exercise of todayExercises; let i = index">
                            <div class="card px-4 pb-4 pt-3" *ngIf="i < displayExerciseLimit">
                                <!--                    <div class="card p-4" *ngIf="!exercise.performed">-->
                                <span *ngIf="todayExercisesCollapsed[i]"><i class="fas fa-angle-down"
                                        style="float: right; color: #152c5b; cursor: pointer" (click)="
                        todayExercisesCollapsed[i] = !todayExercisesCollapsed[i]
                      "></i></span>
                                <span *ngIf="!todayExercisesCollapsed[i]"><i class="fas fa-angle-up"
                                        style="float: right; color: #152c5b; cursor: pointer" (click)="
                        todayExercisesCollapsed[i] = !todayExercisesCollapsed[i]
                      "></i></span>
                                <div class="row">
                                    <div class="col-3 d-flex align-items-center justify-content-center">
                                        <img alt="Image placeholder" [src]="
                      getGifLinks(exercise.exercise.name)
                        " style="height: 80px" id="navbar-logo" />
                                    </div>
                                    <div class="col-9 d-lg-flex d-md-flex justify-content-center align-items-center">
                                        <h5 class="m-0 pr-2">
                                            {{
                                            exercise.exercise.display_name[locale]
                                            ? exercise.exercise.display_name[locale]
                                            : exercise.exercise.display_name['en']
                                            }}
                                        </h5>
                                        <!--                                <button class="btn btn-primary btn-sm ml-auto" (click)="goBack(exercise)">Start</button>-->
                                        <button class="btn btn-primary btn-sm ml-auto" (click)="
                          startExerciseClicked(exerciseDetailsModal, exercise)
                        ">
                                            {{ "exercise.start" | translate }}
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="!todayExercisesCollapsed[i]" class="mt-3">
                                    <div class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "physiotherapist.category" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            "generalTerms." + exercise.exercise.category | translate
                                            }}
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.initial_pose?.pose.includes('sit')"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patientDetails.equipment" | translate }}:
                                        </h6>
                                        <img src="../../../../assets/images/chair.svg" alt="chair"
                                            style="height: 2rem; padding-top: 0.125rem;" class="img-fluid ml-2">
                                    </div>

                                    <div *ngIf="exercise.exercise.sets"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "createExerciseProgram.sets" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.sets
                                            }}
                                        </p>
                                    </div>
                                    

                                    <div *ngIf="!['sit2stand_duration', 'sit2stand_counter', 'squat'].includes(exercise.exercise.name)"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patientDetails.repetitions" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.repetitions
                                            }}
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.set_break?.active"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "createExerciseProgram.break" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.set_break.duration
                                            }}s
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.name === 'sit2stand_duration'"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patient.duration" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            ((exercise.exercise.actions[0].duration
                                                + exercise.exercise.actions[1].duration
                                                + exercise.exercise.set_break.duration
                                            ) * exercise.exercise.sets)
                                            }}s
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.name === 'squat' || exercise.exercise.name === 'sit2stand_counter'" class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "exercise.count" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.actions[0].count
                                            }}
                                        </p>
                                    </div>

                                    <div *ngIf="!['breathing_time', 'relaxation'].includes(exercise.exercise.category) && !['sit2stand_duration', 'sit2stand_counter', 'squat'].includes(exercise.exercise.name)"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patientDetails.averageDuration" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.avgDuration
                                            }}
                                        </p>
                                    </div>

                                    <h5 *ngIf="exercise.exercise.description" class="mt-3 mb-1">
                                        {{ "patientDetails.description" | translate }}:
                                    </h5>
                                    <p class="my-auto">
                                        {{
                                        exercise.exercise.description
                                        ? exercise.exercise.description[locale]
                                        : null
                                        }}
                                    </p>
                                </div>
                                <!--                      <div *ngIf="exercise.date !== getDateInString(viewDate)" class="w-100 text-center font-italic mt-sm-2">Remaining exercise from yesterday</div>-->
                            </div>
                        </div>
                        <p class="text-center" *ngIf="displayExerciseLimit < todayExercises.length"
                            (click)="displayExerciseLimit = todayExercises.length" style="cursor: pointer">
                            <span><i class="fas fa-angle-down"></i></span>&nbsp;{{
                            "patientDetails.showMore" | translate
                            }}
                        </p>
                        <p class="text-center" *ngIf="
                  displayExerciseLimit >= todayExercises.length &&
                  todayExercises.length > 4
                " (click)="displayExerciseLimit = 4" style="cursor: pointer">
                            <span><i class="fas fa-angle-up"></i></span>&nbsp;{{
                            "patientDetails.showLess" | translate
                            }}
                        </p>
                    </div>


                    <hr *ngIf="
                todayExercises.length > 0 && remainingPreviousExercises.length > 0
              " />

                    <div *ngIf="remainingPreviousExercises.length > 0">
                        <div *ngFor="let exercise of remainingPreviousExercises; let i = index">
                            <div class="card px-4 pb-4 pt-3">
                                <span *ngIf="previousExercisesCollapsed[i]"><i class="fas fa-angle-down"
                                        style="float: right; color: #152c5b; cursor: pointer" (click)="
                        previousExercisesCollapsed[i] =
                          !previousExercisesCollapsed[i]
                      "></i></span>
                                <span *ngIf="!previousExercisesCollapsed[i]"><i class="fas fa-angle-up"
                                        style="float: right; color: #152c5b; cursor: pointer" (click)="
                        previousExercisesCollapsed[i] =
                          !previousExercisesCollapsed[i]
                      "></i></span>
                                <div class="row">
                                    <div class="col-3 d-flex align-items-center justify-content-center">
                                        <img alt="Image placeholder" [src]="
                      getGifLinks(exercise.exercise.name)
                        " style="height: 80px" id="navbar-logo" />
                                    </div>
                                    <div class="col-9 d-lg-flex d-md-flex justify-content-center align-items-center">
                                        <h5 class="m-0 pr-2">
                                            {{
                                            exercise.exercise.display_name[locale]
                                            ? exercise.exercise.display_name[locale]
                                            : exercise.exercise.display_name['en']
                                            }}
                                        </h5>
                                        <button class="btn btn-primary btn-sm ml-auto" (click)="goBack(exercise)">
                                            Start
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="!previousExercisesCollapsed[i]" class="mt-3">
                                    <div class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "physiotherapist.category" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            "generalTerms." + exercise.exercise.category | translate
                                            }}
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.initial_pose?.pose.includes('sit')"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patientDetails.equipment" | translate }}:
                                        </h6>
                                        <img src="../../../../assets/images/chair.svg" alt="chair"
                                            style="height: 2rem; padding-top: 0.125rem;" class="img-fluid ml-2">
                                    </div>

                                    <div *ngIf="exercise.exercise.sets"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "createExerciseProgram.sets" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.sets
                                            }}
                                        </p>
                                    </div>

                                    <div *ngIf="!['sit2stand_duration', 'sit2stand_counter', 'squat'].includes(exercise.exercise.name)"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patientDetails.repetitions" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.repetitions
                                            }}
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.set_break?.active"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "createExerciseProgram.break" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.set_break.duration
                                            }}s
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.name === 'sit2stand_duration'"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patient.duration" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            ((exercise.exercise.actions[0].duration
                                                + exercise.exercise.actions[1].duration
                                                + exercise.exercise.set_break.duration
                                            ) * exercise.exercise.sets)
                                            }}s
                                        </p>
                                    </div>

                                    <div *ngIf="exercise.exercise.name === 'squat'  || exercise.exercise.name === 'sit2stand_counter'" class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "exercise.count" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.exercise.actions[0].count
                                            }}
                                        </p>
                                    </div>

                                    <div *ngIf="!['breathing_time', 'relaxation'].includes(exercise.exercise.category) && !['sit2stand_duration', 'sit2stand_counter', 'squat'].includes(exercise.exercise.name)"
                                        class="d-flex align-items-center">
                                        <h6 class="my-auto">
                                            {{ "patientDetails.averageDuration" | translate }}:
                                        </h6>
                                        <p class="my-auto">
                                            &nbsp;&nbsp;{{
                                            exercise.avgDuration
                                            }}
                                        </p>
                                    </div>

                                    <h5 *ngIf="exercise.exercise.description" class="mt-3 mb-1">
                                        {{ "patientDetails.description" | translate }}:
                                    </h5>
                                    <p class="my-auto">
                                        {{
                                        exercise.exercise.description
                                        ? exercise.exercise.description[locale]
                                        : null
                                        }}
                                    </p>
                                </div>
                                <div class="w-100 text-center font-italic mt-sm-2">
                                    {{ "patientDetails.remainingExercise" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 m-0">
                    <div class="mt-3 mb-3">
                        <h3>{{ "patientDetails.treatmentProgress" | translate }}</h3>
                    </div>
                    <div *ngIf="allExercisePrograms.length === 0" class="card">
                        <div class="d-flex p-4 justify-content-center">
                            <div>
                                <p class="text-muted text-center mb-2">
                                    {{ "patientDetails.noExercisePrograms" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="allExercisePrograms.length > 0" class="card" style="padding: 6%">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-8">
                                        <label class="card-title">{{ "patientDetails.startDate" | translate }}:
                                            <b>{{ activeTreatmentSummary['start_date'] }}</b></label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <label class="card-title">{{ "patientDetails.endDate" | translate }}:
                                            <b>{{ activeTreatmentSummary['end_date'] }}</b></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <circle-progress [percent]="this.donePercentage" [maxPercent]="100"
                                    [subtitleFontSize]="25" [subtitle]="this.subtitle"
                                    [outerStrokeColor]="'#384f6b '"></circle-progress>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mb-3">
                        <h3>{{ "patientDetails.myTasks" | translate }}</h3>
                    </div>
                    <div class="card" style="padding: 6%">
                        <div class="card-title">
                            <h5 (click)="addTask()" style="cursor: pointer">
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                {{ "patientDetails.addTask" | translate }}
                            </h5>
                            <div *ngIf="myTasks.length === 0 && !addNewTask">
                                <p class="text-center">
                                    {{ "patientDetails.emptyTasks" | translate }}
                                </p>
                            </div>
                            <div *ngIf="addNewTask" class="list-group ml-3">
                                <form class="pl-2 pr-2" [formGroup]="newTaskFormGroup">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-12 col-lg-12 d-inline" style="margin-bottom: 1%">
                                            <input type="checkbox" class="form-check-input checkbox-round" disabled
                                                style="cursor: default" />
                                            <input style="border: 0px solid; margin-left: 1%" type="text" placeholder="{{
                            'patientDetails.newtTitle' | translate
                          }}" formControlName="title" />
                                            <app-error-messages *ngIf="addNewTaskAction"
                                                [control]="newTaskFormGroup.get('title')">
                                            </app-error-messages>
                                        </div>

                                        <div class="col-sm-6 col-md-12 col-lg-12 d-inline" style="margin-bottom: 1%">
                                            <i class="fa fa-list-ul" aria-hidden="true"></i>
                                            <input style="border: 0px solid" type="text" placeholder="{{
                            'patientDetails.newDetails' | translate
                          }}" formControlName="details" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-12 col-lg-12 d-inline" style="margin-bottom: 1%">
                                            <!-- <i class="fa fa-calendar" aria-hidden="true"></i> -->
                                            <input style="border: 0px solid; color: #858585" type="date"
                                                [min]="todayDate" placeholder="{{ 'patientDetails.date' | translate }}"
                                                formControlName="date" />
                                            <app-error-messages *ngIf="addNewTaskAction"
                                                [control]="newTaskFormGroup.get('date')">
                                            </app-error-messages>
                                        </div>
                                        <div class="col-sm-6 col-md-12 col-lg-12 d-inline" style="margin-bottom: 1%">
                                            <!-- <i class="fa fa-calendar" aria-hidden="true"></i> -->
                                            <input style="border: 0px solid; color: #858585" type="time"
                                                placeholder="{{ 'patientDetails.time' | translate }}"
                                                formControlName="time" />
                                            <app-error-messages *ngIf="addNewTaskAction"
                                                [control]="newTaskFormGroup.get('time')">
                                            </app-error-messages>
                                        </div>
                                    </div>
                                    <div *ngIf="newTaskFormGroup.get('date').value" class="row" style="margin-left: 1%">
                                        <div class="col-12 d-inline">
                                            <input type="checkbox" class="form-check-input"
                                                formControlName="reminder" />
                                            <label style="font-size: x-small; vertical-align: middle">{{
                                                "patientDetails.taskReminder" |
                                                translate }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-check d-inline">
                                            <button class="btn btn-xs" (click)="addNewTaskIntoTaks()"
                                                style="color: white; background: #2fc19a">
                                                <i class="fa fa-plus-circle" aria-hidden="true"
                                                    style="color: white"></i>
                                                {{ "patientDetails.add" | translate }}
                                            </button>
                                            <button class="btn btn-xs" (click)="cancelNewTask()"
                                                style="background: #cacfe0; color: white">
                                                <!-- <i class="fa fa-minus-circle" aria-hidden="true" style="color:#823335; " ></i> -->
                                                {{ "patientDetails.cancel" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div *ngFor="let task of myTasks" style="margin-bottom: 4%">
                            <div *ngIf="!task.completed" class="list-group">
                                <div class="form-check d-inline">
                                    <input type="checkbox" class="form-check-input checkbox-round"
                                        [checked]="task.completed" (change)="changeTaskCompletness(task)" />
                                    <label *ngIf="!task.edit" class="task-label w-100"
                                        style="margin-left: 2%; margin-bottom: 2%">
                                        <span ngbDropdown class="float-right">
                                            <button class="btn btn-xs btn-secondary navbar-brand" id="dropdownBasic1"
                                                ngbDropdownToggle style="background: white; border: none">
                                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <button ngbDropdownItem (click)="editTask(task)">
                                                    {{ "patientDetails.edit" | translate }}
                                                    <i class="fa fa-pencil" aria-hidden="true "></i>
                                                </button>
                                                <button ngbDropdownItem style="color: red"
                                                    (click)="deleteCertainTask(task, removeTask)">
                                                    {{ "patientDetails.delete" | translate }}
                                                    <i style="float: right" class="fa fa-trash" aria-hidden="true "></i>
                                                </button>
                                            </div>
                                        </span>
                                        {{ task.title }}
                                    </label>
                                    <div *ngIf="task.edit" class="row">
                                        <div class="col-sm-6 col-md-12 col-lg-12 d-inline">
                                            <input style="border: 0px solid; margin-left: 2%" type="text"
                                                value="task.title" [(ngModel)]="task.title" />
                                        </div>
                                    </div>
                                    <!--                            <div ngbDropdown class="d-inline-block" style="float:right ;">-->
                                    <!--                                <button class="btn btn-xs btn-secondary navbar-brand" id="dropdownBasic1" ngbDropdownToggle style="background: white;-->
                                    <!--                            border: none;"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></button>-->
                                    <!--                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">-->
                                    <!--                                    <button ngbDropdownItem (click)=" editTask(task)"> {{ 'patientDetails.edit' | translate }} <i class=" fa fa-pencil " aria-hidden="true "></i></button>-->
                                    <!--                                    <button ngbDropdownItem style="color:red; " (click)="deleteCertainTask(task, removeTask) ">   {{ 'patientDetails.delete' | translate }} <i style="float: right; "class="fa fa-trash " aria-hidden="true "></i></button>-->
                                    <!--                                </div>-->
                                    <!--                            </div>-->
                                </div>

                                <div class="form-check d-inline" style="margin-left: 3%">
                                    <label *ngIf="!task.edit" class="task-label" style="font-size: smaller">{{
                                        task.details }}</label>
                                    <div *ngIf="task.edit" class="col-sm-6 col-md-12 col-lg-12 d-inline">
                                        <i class="fa fa-list-ul" aria-hidden="true"></i>
                                        <input style="border: 0px solid; margin-left: 2%" type="text"
                                            [(ngModel)]="task.details" />
                                    </div>
                                </div>
                                <div *ngIf="!task.edit && task.date" class="form-check d-inline">
                                    <span class="badge badge-pill badge-primary">
                                        {{ task.date
                                        }}<span *ngIf="task.time"> , {{ task.time }} </span>
                                    </span>
                                </div>
                                <div *ngIf="task.edit">
                                    <div class="row" style="margin-left: 10%">
                                        <div class="col-sm-6 col-md-12 col-lg-12 d-inline">
                                            <input style="border: 0px solid; color: #858585" type="date"
                                                [min]="todayDate" [(ngModel)]="task.date" />
                                        </div>
                                        <div *ngIf="task.date" class="col-sm-6 col-md-12 col-lg-12 d-inline">
                                            <input style="border: 0px solid; color: #858585" type="time"
                                                [(ngModel)]="task.time" />
                                        </div>
                                    </div>
                                    <div *ngIf="task.date" class="row" style="margin-left: 10%">
                                        <div class="col-12 d-inline">
                                            <input type="checkbox" class="form-check-input"
                                                [(ngModel)]="task.reminder" />
                                            <label style="font-size: x-small; vertical-align: middle">{{
                                                "patientDetails.taskReminder" |
                                                translate }}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-check d-inline">
                                            <button class="btn btn-xs" style="color: white; background: #2fc19a"
                                                [disabled]="!task.title || task.title.trim() == ''"
                                                (click)="openEditModal(editTaskModel)">
                                                <i class="fa fa-plus-circle" aria-hidden="true"
                                                    style="color: white"></i>
                                                {{ "patientDetails.save" | translate }}
                                            </button>
                                            <button class="btn btn-xs" style="background: #cacfe0; color: white"
                                                (click)="cancelEdit()">
                                                <!-- <i class="fa fa-minus-circle" aria-hidden="true" style="color:#823335; " ></i> -->
                                                {{ "patientDetails.cancel" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-10">
                                    <h6>
                                        {{ "generalTerms.completed" | translate }} (
                                        {{ completedTasks }} )
                                    </h6>
                                </div>
                                <div class="col-2">
                                    <button type="button" (click)="isCollapsed = !isCollapsed"
                                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
                                        class="transparent">
                                        <i *ngIf="!isCollapsed" class="fa fa-angle-down" aria-hidden="true"></i>
                                        <i *ngIf="isCollapsed" class="fa fa-angle-up" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                <div *ngFor="let task of myTasks" style="margin-bottom: 4%">
                                    <div *ngIf="task.completed" class="list-group">
                                        <div class="form-check d-inline">
                                            <i class="fa fa-check" aria-hidden="true"
                                                style="color: #152c5b; cursor: pointer"
                                                (click)="changeTaskCompletness(task)"></i>
                                            <label class="task-label" style="
                            margin-left: 3%;
                            margin-bottom: 0%;
                            text-decoration: line-through;
                          ">{{ task.title }}</label>
                                        </div>
                                        <div class="form-check d-inline">
                                            <label class="task-label"
                                                style="margin-left: 7%; text-decoration: line-through">{{ task.details
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- not used section -->
            <div [ngClass]="{'d-none':true}" class="row" #programsSection>
                <div class="col-lg-12 col-md-12 col-sm-12 m-0">
                    <div class="mt-3 mb-3">
                        <h3>{{ "patientDetails.exercisePrograms" | translate }}</h3>
                    </div>
                    <ul class="nav nav-pills nav-fill mb-3">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" [ngClass]="{ active: activeProgramTab === 0 }"
                                (click)="changeProgramTab(0)">{{ "patientDetails.activePrograms" | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: activeProgramTab === 1 }"
                                (click)="changeProgramTab(1)">{{
                                "patientDetails.oldPrograms" | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ active: activeProgramTab === 2 }"
                                (click)="changeProgramTab(2)">{{
                                "patientDetails.deletedPrograms" | translate }}</a>
                        </li>
                    </ul>
                    <div *ngIf="exercisePrograms.length === 0" class="row">
                        <div class="col-12 card p-4">
                            <p class="text-center mb-0">
                                {{ "patientDetails.noAssignedExercises" | translate }}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="exercisePrograms.length > 0" class="row card-group">
                        <div *ngFor="let exerciseProgram of exerciseProgramsCopy; let i = index" class="col-12">
                            <div *ngIf="collapsed[i]">
                                <div class="card p-4" (click)="expandProgram(i)" style="cursor: pointer">
                                    <span><i class="fas fa-angle-down"
                                            style="float: right; color: #152c5b; cursor: pointer"
                                            (click)="expandProgram(i);"></i></span>
                                    <div class="row" style="align-items: flex-end">
                                        <div class="col-lg-3 col-md-3 col-sm-6">
                                            <h4>
                                                {{ "patientDetails.program" | translate }} {{ i + 1 }}
                                            </h4>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-6">
                                            <h5>
                                                {{ getProgramLength(exerciseProgram) }}
                                                <span *ngIf="getProgramLength(exerciseProgram) > 1">{{
                                                    "patientDetails.exercises" | translate
                                                    }}</span><span *ngIf="getProgramLength(exerciseProgram) === 1">{{
                                                    "patientDetails.exercise" |
                                                    translate }}</span>
                                            </h5>
                                            <!--                                    <h5>{{exerciseProgram.exercises.length}} <span *ngIf=" exerciseProgram.exercises.length > 1">{{'patientDetails.exercises' | translate}}</span><span *ngIf=" exerciseProgram.exercises.length === 1">{{'patientDetails.exercise' | translate}}</span></h5>-->
                                        </div>
                                        <div *ngIf="this.programSmallestDate[i]"
                                            class="col-lg-3 col-md-3 col-sm-6 d-flex">
                                            <h5>Start</h5>
                                            <h5 class="pl-1 font-weight-light">
                                                {{ this.programSmallestDate[i] | date : "dd.MM.yyyy" }}
                                            </h5>
                                        </div>
                                        <div *ngIf="this.programLargestDate[i]"
                                            class="col-lg-3 col-md-3 col-sm-6 d-flex">
                                            <h5>End:</h5>
                                            <h5 class="pl-1 font-weight-light">
                                                {{ this.programLargestDate[i] | date : "dd.MM.yyyy" }}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!collapsed[i]">
                                <div class="card p-4" [ngClass]="{ 'card-border-primary': editMode[i] }">
                                    <span><i class="fas fa-angle-up"
                                            style="float: right; color: #152c5b; cursor: pointer"
                                            (click)="shrinkProgram(i)"></i></span>
                                    <div class="d-flex mb-4">
                                        <h4 class="my-auto">
                                            {{ "patientDetails.program" | translate }} {{ i + 1 }}
                                        </h4>
                                        <span *ngIf="activeProgramTab !== 2" class="pl-4 my-auto">
                                            <i class="fas fa-edit icon-btn" style="
                            color: #384f6b;
                            cursor: pointer;
                            padding-right: 10px;
                          " (click)="activeEditMode(i, cancelModal)"></i>
                                            <i class="fas fa-plus icon-btn" style="
                        color: #384f6b;
                        cursor: pointer;
                        padding-right: 10px;
                      " (click)="(!editMode[i] && activeEditMode(i, cancelModal));openAssignModal(exerciseProgram,assignExerciseModal)"></i>
                                            <i *ngIf="activeProgramTab !== 1" class="far fa-trash-alt icon-btn"
                                                style="color: #823335; cursor: pointer" (click)="
                            openModal(
                              deleteExerciseProgramModal,
                              exerciseProgram,
                              assignedDates[i]
                            )
                          "></i>

                                        </span>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div *ngFor="
                          let exercise of exerciseProgram.exercises | filterDeletedExercises: activeProgramTab ===2 ? activeProgramTab : null;
                          let j = index
                        " class="program-exercise-section row mx-2 px-5 mt-4 rounded bg-gray-100">
                                            <div *ngIf="!exercise.deleted || activeProgramTab === 2"
                                                class="d-flex flex-row flex-wrap w-100 align-items-center my-4">
                                                <img alt="Image placeholder" [src]="getGifLinks(exercise.name)"
                                                    (error)="getExerciseGif($event, exercise)"
                                                    style="height: 6.5rem; width: 6.5rem;object-fit: contain;" />
                                                <div class="col-lg-8 col-md-7">
                                                    <!--                        <i *ngIf="editMode[i] && !exercise.deleted" class="far fa-trash-alt"-->
                                                    <!--                          style="float: right; color: #823335; cursor: pointer" (click)="-->
                                                    <!--                            openRemoveExerciseModal(-->
                                                    <!--                              removeExerciseModal,-->
                                                    <!--                              exerciseProgram,-->
                                                    <!--                              exercise-->
                                                    <!--                            )-->
                                                    <!--                          "></i>-->
                                                    <h6>
                                                        {{
                                                        exercise.display_name[locale]
                                                        ? exercise.display_name[locale]
                                                        : exercise.display_name['en']
                                                        }}
                                                    </h6>
                                                    <p class="m-0">
                                                        {{ "patientDetails.category" | translate }}:
                                                        {{ "generalTerms." + exercise.category | translate }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div *ngIf="!exercise.deleted || activeProgramTab === 2"
                                                class="w-100 d-flex flex-column justify-content-center">
                                                <div *ngIf="exercise.set_break" class="row mx-0 justify-content-center">
                                                    <div class="form-group col-4" style="text-align: center">
                                                        <h6>
                                                            {{ "createExerciseProgram.sets" | translate }}
                                                        </h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.sets }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" min="1" max="10" type="number"
                                                            class="form-control" [(ngModel)]="exercise.sets"
                                                            (input)="inputValidator($event, 10)"
                                                            (blur)="checkEmptyValue($event)"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                                    </div>
                                                    <div class="form-group col-4" style="text-align: center">
                                                        <h6>
                                                            {{ "createExerciseProgram.break" | translate }} (s)
                                                        </h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.set_break.duration }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" min="0" type="number"
                                                            class="form-control"
                                                            [(ngModel)]="exercise.set_break.duration "
                                                            (blur)="checkEmptyValue($event)"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                                    </div>
                                                </div>
                                                <div class="row mx-0 justify-content-center">
                                                    <div *ngIf="exercise.name !== 'sit2stand_test'"
                                                        class="form-group col-4" style="text-align: center">
                                                        <h6>
                                                            {{ "createExerciseProgram.repetitions" | translate }}
                                                        </h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.repetitions }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" min="1" max="10" type="number"
                                                            class="form-control" [(ngModel)]="exercise.repetitions"
                                                            (input)="inputValidator($event, 10)"
                                                            (blur)="checkEmptyValue($event)"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                                    </div>
                                                    <div *ngIf="exercise.category === 'breathing'"
                                                        class="form-group col-4" style="text-align: center">
                                                        <h6>
                                                            {{ "createExerciseProgram.inhale" | translate }}
                                                            (s)
                                                        </h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.actions[0].duration }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" min="1" max="10" type="number"
                                                            class="form-control"
                                                            [(ngModel)]="exercise.actions[0].duration"
                                                            (input)="inputValidator($event, 10)"
                                                            (blur)="checkEmptyValue($event)"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                                    </div>
                                                    <div *ngIf="exercise.category === 'breathing'"
                                                        class="form-group col-4" style="text-align: center">
                                                        <h6>
                                                            {{ "createExerciseProgram.exhale" | translate }}
                                                            (s)
                                                        </h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.actions[1].duration }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" min="1" max="10" type="number"
                                                            class="form-control"
                                                            [(ngModel)]="exercise.actions[1].duration"
                                                            (input)="inputValidator($event, 10)"
                                                            (blur)="checkEmptyValue($event)"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                                    </div>
                                                    <div *ngIf="exercise.name === 'sit2stand_test'"
                                                        class="form-group col-4" style="text-align: center">
                                                        <h6>
                                                            {{ "createExerciseProgram.duration" | translate }}
                                                            (s)
                                                        </h6>
                                                        <input type="number" min="1" class="form-control"
                                                            id="input-repetitions" name="input-repetitions"
                                                            placeholder="1" [disabled]="true"
                                                            [(ngModel)]="exercise.repetitions"
                                                            (input)="inputValidator($event, 10)"
                                                            (blur)="checkEmptyValue($event)"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                                    </div>
                                                    <div *ngIf="
                                exercise.category === 'lower_body' &&
                                exercise.name !== 'sit2stand_test' &&
                                exercise.actions[0].duration
                              " class="form-group col-4" style="text-align: center">
                                                        <h6>{{ "createExerciseProgram.duration" | translate }} (s)</h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.actions[0].duration }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" type="number" class="form-control"
                                                            [(ngModel)]="exercise.actions[0].duration" />
                                                    </div>
                                                    <div *ngIf="
                                exercise.category === 'lower_body' &&
                                exercise.name !== 'sit2stand_test' &&
                                exercise.actions[0] | hasProp : 'count'
                              " class="form-group col-4" style="text-align: center">
                                                        <h6>{{ "exercise.count" | translate }}</h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.actions[0].count }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" type="number" min="1" max="30"
                                                            class="form-control" [(ngModel)]="exercise.actions[0].count"
                                                            (input)="inputValidator($event, 30)"
                                                            (blur)="checkEmptyValue($event)"
                                                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57" />
                                                    </div>
                                                    <div *ngIf="exercise.category === 'stretch' && exercise.actions[0].count"
                                                        class="form-group col-4" style="text-align: center">
                                                        <h6>{{ "exercise.count" | translate }}</h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.actions[0].count }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" type="number" class="form-control"
                                                            [(ngModel)]="exercise.actions[0].count" (change)="
                                  exercise.actions[2].count =
                                    exercise.actions[0].count
                                " />
                                                    </div>
                                                    <div *ngIf="
                                exercise.category === 'stretch' &&
                                exercise.actions[1] &&
                                exercise.actions[1].duration
                              " class="form-group col-4" style="text-align: center">
                                                        <h6>
                                                            {{ "createExerciseProgram.break" | translate }}
                                                            (s)
                                                        </h6>
                                                        <p *ngIf="!editMode[i]">
                                                            {{ exercise.actions[1].duration }}
                                                        </p>
                                                        <input *ngIf="editMode[i]" type="number" class="form-control"
                                                            [(ngModel)]="exercise.actions[1].duration" (change)="
                                  exercise.actions[3].duration =
                                    exercise.actions[1].duration
                                " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="exerciseProgram.exercises.length > 0" class="mt-5">
                                        <h4>{{ "patientDetails.assignedDates" | translate }}</h4>
                                        <div class="row mt-3">
                                            <div class="col-lg-9 col-md-10 col-12 mx-auto">
                                                <app-calendar *ngIf="!editMode[i] && updated"
                                                    [functionality]="'program-details'"
                                                    [assignedEvents]="singleProgramEvents"
                                                    (exerciseClicked)="getExerciseDetails($event, calendarEventModal)"></app-calendar>
                                                <app-calendar *ngIf="editMode[i]  && updated"
                                                    [functionality]="'program-update'"
                                                    [exerciseList]="exerciseProgram.exercises"
                                                    [assignedEvents]="singleProgramEvents"
                                                    (exerciseClicked)="getExerciseDetails($event, calendarEventModal)"
                                                    #calendarComponent></app-calendar>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="exerciseProgram.exercises.length === 0">
                                        <p class="text-center">
                                            {{ "patientDetails.emptyProgram" | translate }}
                                        </p>
                                    </div>
                                    <div *ngIf="editMode[i] " class="d-flex ml-auto mt-3">
                                        <button class="btn btn-sm btn-primary text-white" (click)="
                        confirmUpdateExercise(
                          saveModal,
                            exerciseProgram,
                            calendarComponent
                          )
                        ">
                                            {{ "generalTerms.save" | translate }}
                                        </button>
                                        <button class="btn btn-sm btn-secondary" (click)="openModal(cancelModal)">
                                            {{ "generalTerms.cancel" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>

<!--Remove exercise modal-->
<ng-template #removeExerciseModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-danger">{{ 'patientDetails.removeExerciseFromProgram.title' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <p>
                {{ 'patientDetails.removeExerciseFromProgram.removeExerciseQuestion1' | translate }}

                <span class="font-italic text-dark">{{
                    chosenExercise["display_name"][locale] ?? chosenExercise["display_name"]['en']
                    }}</span>
                {{ 'patientDetails.removeExerciseFromProgram.removeExerciseQuestion2' | translate }}
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="removeExercise(); modal.close()">
            {{ 'generalTerms.remove' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ 'generalTerms.cancel' | translate }}

        </button>
    </div>
</ng-template>

<!--Assign exercise modal-->

<ng-template #assignExerciseModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ "patientDetails.assignExercise" | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="exerciseInput">{{
                "patientDetails.chooseExercise" | translate
                }}</label>
            <select id="exerciseInput" class="form-control" [(ngModel)]="chosenExercise">
                <option *ngFor="let exercise of availableExercises" [ngValue]="exercise">
                    {{ exercise.display_name[locale] ?? exercise.display_name['en'] }}
                </option>
            </select>
            <div *ngIf="assignedExercisesExist" class="alert alert-danger" role="alert">
                {{ "patientDetails.allExercisesAssigned" | translate }}
            </div>
            <div *ngIf="chosenExercise?.category=='breathing'">
                <label for="inhaleDuration">{{
                    "patientDetails.chooseInhaleDuration" | translate
                    }}</label>
                <input type="number" class="form-control" id="inhaleDuration" placeholder="(e.g. 3 seconds)" min="2"
                    max="10" [(ngModel)]="exercise_options.inhale_duration" />
                <label for="exhaleDuration">{{
                    "patientDetails.chooseExhaleDuration" | translate
                    }}</label>
                <input type="number" class="form-control" id="exhaleDuration" placeholder="(e.g. 3 seconds)" min="2"
                    max="10" [(ngModel)]="exercise_options.exhale_duration" />
            </div>
            <!-- only for breathing_time category -->
            <div *ngIf="chosenExercise?.category=='breathing_time'">

                <label for="duration">{{
                    "patientDetails.duration" | translate
                    }}</label>
                <input type="number" class="form-control" id="repetitions" placeholder="(e.g. 60 seconds)" min="1"
                    max="200" [(ngModel)]="exercise_options.duration" />
            </div>
            <!-- for lower_body category -->

            <!-- for all categories -->
            <div *ngIf="chosenExercise">

                <label for="repetitions">{{
                    "patientDetails.exercise_options_repetitions" | translate
                    }}</label>
                <input type="number" class="form-control" id="repetitions" placeholder="(e.g. 2 repetitions)" min="1"
                    max="10" [(ngModel)]="exercise_options.repetitions_count" />

                <label for="sets">{{
                    "patientDetails.exercise_options_sets" | translate
                    }}</label>
                <input type="number" class="form-control" id="sets" placeholder="(e.g. 2 sets)" min="1" max="10"
                    [(ngModel)]="exercise_options.sets_count" />

            </div>
            <!--      <input type="number" class="form-control" id="exhaleDuration" name="tentacles" min="2" max="10">-->
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" [disabled]="!chosenExercise.id  "
            (click)="assignExercise(); modal.close()">
            {{ "patientDetails.assign" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="clearChosenExercise(); modal.close()">
            {{ "patientDetails.cancel" | translate }}
        </button>
    </div>
</ng-template>

<!--Exercise detail modal-->

<ng-template #calendarEventModal let-modal>
    <div class="modal-header">
        <i *ngIf="exerciseFromDay" class="fa fa-chevron-left fa-2x mr-4" aria-hidden="true"
            style="color: #384f6b; cursor: pointer"
            (click)="handleBackToDay(exercise.start, modal, calendarDayModal)"></i>
        <h5 class="modal-title">{{ exercise.title }} - {{ titleDate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div [ngSwitch]="exerciseResults">
            <div *ngSwitchCase="'exists'">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeResultTab" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <button ngbNavLink>{{ 'patientDetails.performanceResults' | translate }}</button>
                        <ng-template ngbNavContent>
                            <div *ngIf="!exerciseResultsError && exerciseResultsLoaded">
                                <div
                                    *ngIf="exerciseCategory === 'abdominal' || (exerciseCategory === 'breathing' && !physicalExercise)">
                                    <div *ngIf="exerciseDetails.display" class="mt-3 ml-3">
                                        <h4>{{ "patientDetails.exerciseDetails" | translate }}</h4>
                                        <div class="row d-flex ml-3">
                                            <h5 class="col-lg-4 col-md-4 col-sm-6 mx-auto font-weight-light">
                                                {{ "patientDetails.inhale" | translate }}:
                                                {{ exerciseDetails.inhale_duration }} s
                                            </h5>
                                            <h5 class="col-lg-4 col-md-4 col-sm-6 mx-auto font-weight-light">
                                                {{ "patientDetails.exhale" | translate }}:
                                                {{ exerciseDetails.exhale_duration }} s
                                            </h5>
                                            <h5 class="col-lg-4 col-md-4 col-sm-6 mx-auto font-weight-light">
                                                {{ "createExerciseProgram.repetitions" | translate }}:
                                                {{ exerciseDetails.sets }}
                                            </h5>
                                        </div>
                                    </div>

                                    <h4 class="mt-4 ml-3">
                                        {{ "patientDetails.breathingResults" | translate }}
                                    </h4>
                                    <div class="row justify-content-around" style="margin-bottom: 6%">
                                        <div class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                                            <circle-progress [percent]="this.inhale" [maxPercent]="100"
                                                [subtitle]="this.inhale + '%'"
                                                [subtitleColor]="getDotColor(this.inhale)"
                                                [outerStrokeColor]="getDotColor(this.inhale)"></circle-progress>
                                            <h5 class="pie-chart">
                                                {{ "patientDetails.inhale" | translate }}
                                            </h5>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                                            <circle-progress [percent]="this.exhale" [maxPercent]="100"
                                                [subtitle]="this.exhale + '%'"
                                                [subtitleColor]="getDotColor(this.exhale)"
                                                [outerStrokeColor]="getDotColor(this.exhale)"></circle-progress>
                                            <h5 class="pie-chart">
                                                {{ "patientDetails.exhale" | translate }}
                                            </h5>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                                            <circle-progress [percent]="this.overall" [maxPercent]="100"
                                                [subtitle]="this.overall + '%'"
                                                [subtitleColor]="getDotColor(this.overall)"
                                                [outerStrokeColor]="getDotColor(this.overall)"></circle-progress>
                                            <h5 class="pie-chart">
                                                {{ "patientDetails.overall" | translate }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row justify-content-around" style="margin-bottom: 6%">
                                        <div *ngIf="this.posture"
                                            class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                                            <circle-progress [percent]="this.posture" [maxPercent]="100"
                                                [subtitle]="this.posture + '%'"
                                                [subtitleColor]="getDotColor(this.posture)"
                                                [outerStrokeColor]="getDotColor(this.posture)"></circle-progress>
                                            <h5 class="pie-chart">
                                                {{ "patientDetails.posture" | translate }}
                                            </h5>
                                        </div>
                                        <div *ngIf="this.shoulder"
                                            class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0">
                                            <circle-progress [percent]="this.shoulder" [maxPercent]="100"
                                                [subtitle]="this.shoulder + '%'"
                                                [subtitleColor]="getDotColor(this.shoulder)"
                                                [outerStrokeColor]="getDotColor(this.shoulder)"></circle-progress>
                                            <h5 class="pie-chart">
                                                {{ "patientDetails.shoulder" | translate }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div *ngIf="this.countUnitMeanValue > 0 && exerciseCategory !== 'abdominal'"
                                        class="mt-5" style="display: inline-flex">
                                        <span class="dot" [style.background-color]="
                            getDotColor(this.countUnitMeanValue)
                          "></span>
                                        <h5>
                                            &nbsp;&nbsp;
                                            {{ "exercise.detectionConfidence" | translate }}:
                                            {{ this.countUnitMeanValue }}%
                                        </h5>
                                    </div>
                                </div>
                                <div *ngIf="physicalExercise">
                                    <div *ngIf="aiTrainerDisabled" class="d-flex mt-3">
                                        <img [src]="'../../../assets/images/exercise-icons/warning-icon.png'" alt="warning"
                                            height="30" width="auto" />
                                        <h5 class="my-auto pl-2">{{ 'patientDetails.aiTrainerWasDisabled' | translate }}
                                        </h5>
                                    </div>
                                    <div *ngIf="repetitions > -1 || count > -1" class="mt-3 mx-3">
                                        <h4>{{ "patientDetails.exerciseDetails" | translate }}</h4>
                                        <div class="row m-0 justify-content-center">
                                            <h5 *ngIf="repetitions > -1" class="text-center font-weight-light mr-4">
                                                {{ "createExerciseProgram.sets" | translate }}: {{ repetitions }}
                                            </h5>
                                            <h5 *ngIf="count > -1" class="text-center font-weight-light mr-4">
                                                {{ "exercise.count" | translate }}: {{ count }}
                                            </h5>
                                        </div>
                                    </div>
                                    <h4 class="mt-4 ml-3">
                                        {{ "exercise.results" | translate }}:
                                    </h4>
                                    <div style="margin-top: 0%">

                                        <div class="row justify-content-center">
                                            <div *ngFor="let result of countDisplayNames; let i = index"
                                                class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0"
                                                style="margin-top: 0%">
                                                <circle-progress [percent]="
                                (this.countValues[i] / this.countTotal[i]) * 100
                              " [maxPercent]="100" [subtitle]="
                                this.countValues[i] + '/' + this.countTotal[i]
                              " [subtitleColor]="
                                convertToPercentage(
                                  this.countValues[i],
                                  this.countTotal[i]
                                )
                              " [outerStrokeColor]="
                                convertToPercentage(
                                  this.countValues[i],
                                  this.countTotal[i]
                                )
                              "></circle-progress>
                                                <h5 class="pie-chart">
                                                    {{ "exercise." + result | translate }}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="margin-top: 2%">
                                        <!-- <div class="row" *ngIf="countUnitDisplayNames && countUnitDisplayNames.length >0">
                                                <h2>Count Analysis: </h2>
                                                <hr>
                                            </div> -->
                                        <div class="row justify-content-center">
                                            <div *ngFor="
                              let result of countUnitDisplayNames;
                              let i = index
                            " class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0"
                                                style="margin-top: 0%">
                                                <circle-progress [percent]="
                                (this.countUnitValues[i] /
                                  this.countUnitValues[i]) *
                                100
                              " [maxPercent]="100" [subtitle]="this.countUnitValues[i]" [subtitleColor]="'#152c5b'"
                                                    [outerStrokeColor]="'#152c5b'"></circle-progress>
                                                <h5 class="pie-chart">
                                                    {{ "exercise." + result | translate }}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="margin-top: 2%; margin-bottom: 5%">
                                        <!-- <div class="row" *ngIf="percentDisplayName && percentDisplayName.length >0">
                                                <h2>Count Analysis: </h2>
                                                <hr>
                                            </div> -->
                                        <div class="row justify-content-center">
                                            <div *ngFor="let result of percentDisplayName; let i = index"
                                                class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0"
                                                style="margin-top: 0%">
                                                <circle-progress [percent]="percentValue[i]" [maxPercent]="100"
                                                    [subtitle]="this.percentValue[i] + '%'"
                                                    [subtitleColor]="getDotColor(percentValue[i])"
                                                    [outerStrokeColor]="getDotColor(percentValue[i])"></circle-progress>
                                                <h5 class="pie-chart">
                                                    {{ "exercise." + result | translate }}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="row nopadding" style="margin-top: 6%;">
                                            <div *ngFor="let array of DegreesArrays; let i = index" class="col-lg-6 col-md-12 col-sm-12 mx-auto text-center nopadding" style="margin-top: 6%;">
                                                <div class="container"> </div>
                                                <div class="box">
                                                    <ngx-charts-gauge [view]="view" [scheme]="colorScheme" [results]="array" [legend]="legend" [units]="labelsArray[i]" [legendPosition]="legendPosition" [angleSpan]="'180'" [startAngle]="'-90'" [min]="'0'" [max]="'180'" [valueFormatting]="valueFormating"
                                                        [animations]="true" [showText]="true">
                                                    </ngx-charts-gauge>
                                                </div>
                                            </div>
                                        </div> -->
                                    <!-- <div class="row nopadding" style="margin-top: 6%;">
                                            <fusioncharts width="700" height="400" type="Column2d" [dataSource]="dataSource">
                                            </fusioncharts>
                                        </div> -->
                                    <div>
                                        <!-- <div class="row" *ngIf=" dataSources && dataSources.length >0">
                                                <h2>Degree Analysis: </h2>
                                                <hr>
                                            </div> -->
                                        <div class="row nopadding">
                                            <div *ngFor="let dataSource of dataSources; let i = index"
                                                class="col-lg-6 col-md-12 col-sm-12 mx-auto text-center nopadding"
                                                style="margin-top: 0%">
                                                <fusioncharts type="angulargauge" height="250" dataFormat="json"
                                                    [dataSource]="dataSource">
                                                </fusioncharts>
                                                <h5 class="pie-chart">
                                                    {{ "exercise." + degreeDisplayName[i] | translate }}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="problemDisplayNameNotNull.length > 0" class="mb-5">
                                        <h4 class="mt-6 ml-3">
                                            {{ "exercise.problems" | translate }}:
                                        </h4>
                                        <div class="row justify-content-center">
                                            <div *ngFor="
                              let result of problemDisplayNameNotNull;
                              let i = index
                            " class="col-lg-3 col-md-6 col-sm-12 mx-auto mb-lg-0 mb-md-2 mb-sm-0"
                                                style="margin-top: 0%">
                                                <circle-progress [percent]="
                                (this.problemCountValueNotNull[i] /
                                  this.problemTotalValueNotNull[i]) *
                                100
                              " [maxPercent]="100" [subtitle]="
                                this.problemCountValueNotNull[i] +
                                '/' +
                                this.problemTotalValueNotNull[i]
                              " [subtitleColor]="'#823335'" [outerStrokeColor]="'#823335'"></circle-progress>
                                                <h5 class="pie-chart">
                                                    {{ "exercise." + result | translate }}
                                                </h5>
                                            </div>
                                        </div>
                                        <!-- <div style="display: block; margin-top: 6%;">
                                                <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                                </canvas>
                                            </div> -->
                                    </div>

                                    <div *ngIf="this.countUnitMeanValue > 0" class="mt-5" style="display: inline-flex">
                                        <span class="dot" [style.background-color]="
                            getDotColor(this.countUnitMeanValue)
                          "></span>
                                        <h5>
                                            &nbsp;&nbsp;
                                            {{ "exercise.detectionConfidence" | translate }}:
                                            {{ this.countUnitMeanValue }}%
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!exerciseResultsLoaded"
                                class="preloader d-flex justify-content-center align-items-center my-5">
                                <div class="spinner-border text-primary" role="status ">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li *ngIf="exerciseCategory === 'breathing'" [ngbNavItem]="2">
                        <button ngbNavLink>{{ 'patientDetails.postureAnalysis' | translate }}</button>
                        <ng-template ngbNavContent>
                            <canvas baseChart class="chart" [datasets]="breathingBarChartData.data.datasets"
                                [labels]="breathingBarChartData.data.labels" [chartType]="breathingBarChartData.type"
                                [options]="breathingBarChartData.options">
                            </canvas>
                        </ng-template>
                    </li>
                    <li *ngIf="exerciseCategory === 'abdominal' || !physicalExercise" [ngbNavItem]="3">
                        <button ngbNavLink>{{ 'patientDetails.breathingChart' | translate }}</button>
                        <ng-template ngbNavContent>
                            <div *ngIf="breathingPlotData">
                                <app-breathing-graph [plotData]="breathingPlotData" [assignedDates]="assignedDates"
                                    [patientUid]="patient.uid" [exerciseId]="exercise.id"
                                    [choosenDate]="exerciseDetailsDate"></app-breathing-graph>
                            </div>
                            <div *ngIf="!breathingPlotData"
                                class="preloader d-flex justify-content-center align-items-center my-5">
                                <div class="spinner-border text-primary" role="status ">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li *ngIf="emotion && emotion.length > 0" [ngbNavItem]="4">
                        <button ngbNavLink>{{ 'patientDetails.emotions' | translate }}</button>
                        <ng-template ngbNavContent>
                            <div style="justify-content: space-around;">
                                <table style="width: 100%;" mat-table [dataSource]="emotion" aria-describedby="Exercise emotions">
                                    <ng-container matColumnDef="instruction">
                                        <th mat-header-cell *matHeaderCellDef> Instruction </th>
                                        <td mat-cell *matCellDef="let emotion"> {{emotion.instruction[this.locale]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="FER">
                                        <th mat-header-cell *matHeaderCellDef> Emotion </th>
                                        <td mat-cell *matCellDef="let emotion">
                                            <img alt="Image placeholder"
                                                [src]="'../../../assets/images/emojis/' + emotion.FER + '.png'"
                                                width="30" height="auto" />
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="FERsec">
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell *matCellDef="let emotion">
                                            <img *ngIf="emotion.FERsec != ''" alt="Image placeholder"
                                                [src]="'../../../assets/images/emojis/' + emotion.FERsec + '.png'"
                                                width="30" height="auto" />
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            </div>
                            <div>
                                <img alt="Image placeholder" [src]="'../../../assets/images/question-mark.png'"
                                    id="description" width="15" height="auto" />
                                {{ 'patientDetails.emotionDescription' | translate }}
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
            <div *ngSwitchCase="'not-performed'">
                <div class="text-center">
                    <p class="h5 text-muted mb-2">
                        {{ "patientDetails.exerciseNotDone" | translate }}
                    </p>
                </div>
            </div>
            <div *ngSwitchCase="'today-not-performed'">
                <div class="text-center">
                    <p class="h5 text-muted mb-2">
                        {{ "patientDetails.notPerformedYet" | translate }}
                    </p>
                </div>
            </div>
            <div *ngSwitchCase="'future-date'">
                <div class="text-center">
                    <p class="h5 text-muted mb-2">
                        {{ "patientDetails.futureDate" | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Day detail modal-->

<ng-template #calendarDayModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ "patientDetails.exercises" | translate }} - {{ titleDate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-lg-12">
            <div *ngFor="let exercise of exercisesForDay" style="cursor: pointer"
                [ngStyle]="{'background-color': exercise.color.secondary}"
                class="row my-1 px-1 rounded-lg justify-content-center"
                (click)="handleExerciseFromDay(exercise, calendarEventModal, modal)">
                <h6 [ngStyle]="exerciseTextColor(exercise.performed)" class="my-1">{{ exercise.title }}</h6>
            </div>
            <div *ngIf=" titleDate >= formattedDate">
                <div *ngIf="!exercisesForDay || exercisesForDay.length === 0">
                    <div class="text-center">
                        <p class="h5 text-muted mb-2">
                            {{ "patientDetails.calendarFuture" | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf=" titleDate < formattedDate">
                <div *ngIf="!exercisesForDay || exercisesForDay.length === 0">
                    <div class="text-center">
                        <p class="h5 text-muted mb-2">
                            {{ "patientDetails.calendarPast" | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Create appointment modal-->

<ng-template #createAppointmentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ "patientDetails.appointment.create" | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <app-calendar [functionality]="'appointment-selection'" (dateSelect)="appointmentSelect($event)">
        </app-calendar>
        <div class="d-flex flex-row align-items-center mt-3">
            <span>{{ "patientDetails.appointment.note" | translate }}</span>:
            <input type="text" class="form-control ml-2" trim="blur" [(ngModel)]="newAppointmentNote" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" [disabled]="!appointmentDateSelected"
            (click)="createAppointment(); modal.close()">
            {{ 'generalTerms.create' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ 'generalTerms.cancel' | translate }}
        </button>
    </div>
</ng-template>

<!--View appointment modal-->

<ng-template #viewAppointmentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ "patientDetails.appointment.title" | translate }} - {{
            selectedAppointment.formated_date  }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center mb-2">

                <label class="m-0">&nbsp; {{ selectedAppointment.name }}</label>
            </div>
            <div class="d-flex align-items-center mb-2">
                <h6 class="m-0">{{ "patientDetails.appointment.time" | translate }}:</h6>
                <label class="m-0">&nbsp; {{ selectedAppointment.formated_time }}</label>
            </div>
            <div class="d-flex align-items-center">
                <h6 class="m-0">{{ "patientDetails.appointment.link" | translate }}:</h6>
                <label class="m-0">&nbsp;
                    <a href="{{selectedAppointment.patient_url}}">
                        {{ selectedAppointment.patient_url
                        }}
                    </a>
                </label>
            </div>
        </div>
    </div>
</ng-template>

<!--Update appointment modal-->

<ng-template #updateAppointmentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ "patientDetails.appointment.update" | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <app-calendar [functionality]="'appointment-selection'"
            [selectedAppointmentSlot]="{ date: selectedAppointment.date, time: selectedAppointment.time }"
            (dateSelect)="appointmentSelect($event)">
        </app-calendar>
        <div class="d-flex flex-row align-items-center mt-3">
            <span>{{ "patientDetails.appointment.note" | translate }}</span>:
            <input type="text" class="form-control ml-2" trim="blur" [(ngModel)]="selectedAppointment.note"
                (change)="appointmentNoteEdited = true" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary"
            [disabled]="!appointmentDateSelected && !appointmentNoteEdited"
            (click)="updateAppointment(); modal.close()">
            {{ 'generalTerms.update' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ 'generalTerms.cancel' | translate }}
        </button>
    </div>
</ng-template>

<!--Cancel appointment modal-->

<ng-template #cancelAppointmentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ "patientDetails.appointment.cancel" | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ "patientDetails.appointment.cancelQuestionText"| translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); cancelAppointment()">
            {{ "generalTerms.yes" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "generalTerms.no" | translate }}
        </button>
    </div>
</ng-template>

<!-- Performance details modal-->

<ng-template #performanceDetailsModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ titleDate }} - {{ modalData.event.title }}</h5>
        <button type="button " class="close" aria-label="Close " (click)="modal.dismiss('Cross click ')">
            <span aria-hidden="true ">×</span>
        </button>
    </div>
    <div *ngIf="chosenEvent.date.toDate() > viewDate" class="modal-body">
        <div class="text-center">
            <p class="h5 text-muted mb-2">
                {{ "patientDetails.futureDate " | translate }}
            </p>
        </div>
    </div>
    <div *ngIf="chosenEvent.date.toDate() <= viewDate" class="modal-body">
        <div *ngIf="chosenEvent.surveyDone; else surveyEmpty" class="row border-bottom">
            <div class="col-6">
                <div class="d-flex justify-content-center mt-3 mb-3">
                    <h4>{{ "patientDetails.beforeExercise " | translate }}</h4>
                </div>
                <div class="text-center">
                    <p class="h5 text-muted mb-2">
                        {{ "patientDetails.scaleEvaluation " | translate }}:
                        <span class="badge" [ngClass]="
                  customEvaluationClass(
                    chosenEvent.beforeExerciseSurvey.scaleEvaluation
                  )
                ">{{ chosenEvent.beforeExerciseSurvey.scaleEvaluation }}%</span>
                    </p>
                    <p class="text-muted mb-2">
                        {{ "patientDetails.stress " | translate }}:
                        {{ chosenEvent.beforeExerciseSurvey.answers[0] }}%
                    </p>
                    <p class="text-muted mb-2">
                        {{ "patientDetails.breathStruggle " | translate }}:
                        {{ chosenEvent.beforeExerciseSurvey.answers[1] }}%
                    </p>
                    <p class="text-muted mb-2">
                        {{ "patientDetails.sleep " | translate }}:
                        {{ chosenEvent.beforeExerciseSurvey.answers[2] }}%
                    </p>
                    <p class="text-muted mb-2">
                        {{ "patientDetails.haveMucus " | translate }}:
                        <span *ngIf="chosenEvent.beforeExerciseSurvey.answers[3]">Yes</span><span
                            *ngIf="!chosenEvent.beforeExerciseSurvey.answers[3]">No</span>
                    </p>
                    <p class="text-muted mb-2">
                        {{ "patientDetails.mucusOut " | translate }}:
                        <span *ngIf="chosenEvent.beforeExerciseSurvey.answers[4]">Yes</span><span
                            *ngIf="!chosenEvent.beforeExerciseSurvey.answers[4]">No</span>
                    </p>
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex justify-content-center mt-3 mb-3">
                    <h4>{{ "patientDetails.afterExercise " | translate }}</h4>
                </div>
                <div class="text-center">
                    <p class="h5 text-muted mb-2">
                        {{ "patientDetails.scaleEvaluation " | translate }}:
                        <span class="badge" [ngClass]="
                  customEvaluationClass(
                    chosenEvent.afterExerciseSurvey.scaleEvaluation
                  )
                ">{{ chosenEvent.afterExerciseSurvey.scaleEvaluation }}%</span>
                    </p>
                    <p class="text-muted mb-2">
                        {{ "patientDetails.stress " | translate }}:
                        {{ chosenEvent.afterExerciseSurvey.answers[0] }}%
                    </p>
                    <p class="text-muted mb-2">
                        {{ "patientDetails.breathStruggle " | translate }}:
                        {{ chosenEvent.afterExerciseSurvey.answers[1] }}%
                    </p>
                    <br />
                    <p class="text-muted mb-2">
                        {{ "patientDetails.mucusOut " | translate }}:
                        <span *ngIf="chosenEvent.afterExerciseSurvey.answers[2]">Yes</span><span
                            *ngIf="!chosenEvent.afterExerciseSurvey.answers[2]">No</span>
                    </p>
                </div>
            </div>
        </div>
        <ng-template #surveyEmpty>
            <div class="text-center">
                <p class="h5 text-muted mb-2">
                    {{ "patientDetails.exerciseNotDone " | translate }}
                </p>
            </div>
        </ng-template>
        <div *ngIf="eventType === 'diaphragm ' && chosenEvent.diaphragmBreathing.done">
            <div class="d-flex justify-content-center mt-3 mb-3">
                <h4>{{ "patientDetails.exercisePerformance " | translate }}</h4>
            </div>
            <div class="text-center">
                <p class="h5 text-muted mb-2">
                    {{ "patientDetails.inhaleResults " | translate }}:
                    {{ chosenEvent.diaphragmBreathing.inhaleResults.performance }} / 10
                    {{ "patientDetails.correct " | translate }} <br />
                    - {{ "patientDetails.problems " | translate }}:
                </p>
                <ul class="mb-5">
                    <li class="d-flex justify-content-center" *ngFor="
                let problem of chosenEvent.diaphragmBreathing.inhaleResults
                  .problems
              ">
                        {{ problem }}
                    </li>
                </ul>
                <p class="h5 text-muted mb-2">
                    {{ "patientDetails.exhaleResults " | translate }}:
                    {{ chosenEvent.diaphragmBreathing.exhaleResults.performance }} / 10
                    {{ "patientDetails.correct " | translate }} <br />
                    - {{ "patientDetails.problems " | translate }}:
                </p>
                <ul class="mb-5">
                    <li class="d-flex justify-content-center" *ngFor="
                let problem of chosenEvent.diaphragmBreathing.exhaleResults
                  .problems
              ">
                        {{ problem }}
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="eventType === 'lip ' && chosenEvent.lipBreathing.done">
            <div class="d-flex justify-content-center mt-3 mb-3">
                <h4>{{ "patientDetails.exercisePerformance " | translate }}</h4>
            </div>
            <div class="text-center">
                <p class="h5 text-muted mb-2">
                    {{ "patientDetails.inhaleResults " | translate }}:
                    {{ chosenEvent.lipBreathing.inhaleResults.performance }} / 5
                    {{ "patientDetails.correct " | translate }} <br />
                    - {{ "patientDetails.problems " | translate }}:
                </p>
                <ul class="mb-5">
                    <li class="d-flex justify-content-center" *ngFor="
                let problem of chosenEvent.lipBreathing.inhaleResults.problems
              ">
                        {{ problem }}
                    </li>
                </ul>
                <p class="h5 text-muted mb-2">
                    {{ "patientDetails.exhaleResults " | translate }}:
                    {{ chosenEvent.lipBreathing.exhaleResults.performance }} / 5 correct
                    <br />
                    - {{ "patientDetails.problems " | translate }}:
                </p>
                <ul class="mb-5">
                    <li class="d-flex justify-content-center" *ngFor="
                let problem of chosenEvent.lipBreathing.exhaleResults.problems
              ">
                        {{ problem }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "patientDetails.okay " | translate }}
        </button>
    </div>
</ng-template>

<ng-template #cancelModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ "patientDetails.cancelEditExerciseProgram.title" | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{
            "patientDetails.cancelEditExerciseProgram.cancelQuestionText"
            | translate
            }}
        </p>
        <!--        <p>{{ 'patientDetails.cancelEditExerciseProgram.cancelExplanation1' | translate }} <span class="font-italic text-danger">{{ 'generalTerms.yes' | translate }}</span>{{ 'patientDetails.cancelEditExerciseProgram.cancelExplanation2' | translate }}</p>-->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); cancelEditProgram()">
            {{ "generalTerms.yes" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "generalTerms.no" | translate }}
        </button>
    </div>
</ng-template>

<ng-template #saveModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ "patientDetails.saveEditExerciseProgram.title" | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{
            "patientDetails.saveEditExerciseProgram.cancelQuestionText" | translate
            }}
        </p>
        <!--        <p>{{ 'patientDetails.saveEditExerciseProgram.cancelExplanation1' | translate }} <span class="font-italic text-danger">{{ 'generalTerms.yes' | translate }}</span>{{ 'patientDetails.saveEditExerciseProgram.cancelExplanation2' | translate }}</p>-->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); updateExerciseProgram()">
            {{ "generalTerms.yes" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "generalTerms.no" | translate }}
        </button>
    </div>
</ng-template>

<!-- Delete exercise program modal -->

<ng-template #deleteExerciseProgramModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Delete Exercise Program</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you would like to delete this exercise program?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="modal.close(); deleteExerciseProgram()">
            {{ "generalTerms.yes" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "generalTerms.no" | translate }}
        </button>
    </div>
</ng-template>

<ng-template #ExitPersonalInfoMode let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Exit Personal Information editing view</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Are you sure you would like to exit personal information editing view?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="modal.close(); cancelEditPersonalInfo()">
            {{ "generalTerms.yes" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "generalTerms.no" | translate }}
        </button>
    </div>
</ng-template>

<ng-template #SaveEditPersonalInfo let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ "patientDetails.updatePatientInformation.title" | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{
            "patientDetails.updatePatientInformation.updateQuestionText" | translate
            }}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="modal.close(); saveEditPersonalInfo()">
            {{ "generalTerms.yes" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(); cancelEditPersonalInfo()">
            {{ "generalTerms.no" | translate }}
        </button>
    </div>
</ng-template>

<ng-template #removeTask let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-danger">
            {{ "patientDetails.removeTask" | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <p>{{ "patientDetails.removeTaskQuestion" | translate }}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="deleteTask(); modal.close()">
            {{ "patientDetails.delete" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "patientDetails.cancel" | translate }}
        </button>
    </div>
</ng-template>

<ng-template #editTaskModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title" style="color: #384f6b">
            {{ "patientDetails.editTask" | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <p>{{ "patientDetails.editTaskQuestion" | translate }}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="editCurrentTask(); modal.close()">
            {{ "patientDetails.edit" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "patientDetails.cancel" | translate }}
        </button>
    </div>
</ng-template>

<!--EXERCISE DETAILS MODAL-->

<ng-template #exerciseDetailsModal let-modal>
    <div class="modal-header">
        <h3 class="modal-title">
            {{ "patientDetails.exerciseDetails" | translate }} -
            {{ chosenExercise.display_name[locale] ?? chosenExercise.display_name['en'] }}
        </h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center">
            <h5 class="my-auto">{{ "physiotherapist.category" | translate }}:</h5>
            <p class="my-auto">
                &nbsp;&nbsp;{{ "generalTerms." + chosenExercise.category | translate }}
            </p>
        </div>
        <h5 class="mt-3">{{ "patientDetails.description" | translate }}:</h5>
        <p class="my-auto">{{ chosenExercise["description"][locale] }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="goBack(exerciseToStart); modal.close()"
            [disabled]="chosenExercise.disabled">
            {{ "physiotherapist.startExercise" | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close()">
            {{ "generalTerms.close" | translate }}
        </button>
    </div>
</ng-template>
<ng-template #InformationModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title"><img class="mr-2" alt="Image placeholder"
                src="../../../assets/Logo/Breathment Logo_rev_white.jpg" style="height: 50px;" id="navbar-mobilr-logo">
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'tutorial.patientDetailsPart1' | translate}} </p>
        <p>{{'tutorial.patientDetailsPart2' | translate}} </p>
        <p>{{'tutorial.patientDetailsPart3' | translate}} </p>
        <p>{{'tutorial.patientDetailsPart4' | translate}} </p>
        <p>{{'tutorial.patientDetailsPart5' | translate}} </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close();">{{ 'generalTerms.close' |
            translate
            }}</button>
    </div>
</ng-template>
