import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  

  constructor(  public auth: AuthService){

  }
  ngOnInit(): void {}
  logout() {
    this.auth.logout();
  }
}
