import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import {ExerciseComponent} from './views/exercise/exercise.component';
import { LoginComponent } from '@pages/login/login.component';
import { PhysiotherapistComponent } from '@pages/physiotherapist/physiotherapist.component';

import { NotFoundComponent } from '@pages/not-found/not-found.component';
import { PatientComponent } from '@pages/patient/patient.component';
import { AuthGuard } from '@services/auth.guard';

import { PatientDetailsComponent } from '@components/patient-details/patient-details.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';

import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";
import { CreatePatientComponent } from "./views/shared/create-patient/create-patient.component";

import { IndexComponent } from '@pages/physiotherapist/index/index.component';
//import { PhysiotherapistDataResolver } from './resolvers/physiotherapist-data.resolver';

//import { PatientDataResolver } from './resolvers/patient-data.resolver';
import { CreateExerciseProgramComponent } from '@components/create-exercise-program/create-exercise-program.component';

import { PaymentComponent } from "./views/payment/payment.component";


import { OldPasswordComponent } from '@pages/reset-password/old-password/old-password.component';
import { SettingsScreenComponent } from '@pages/settings-screen/settings-screen.component';
import { UnsubscribeComponent } from "@pages/unsubscribe/unsubscribe.component";
import { passwordChangeGuard } from '@services/passwordChangeGuard';
import { ChangeEmailComponent } from './views/change-email/change-email.component';
import { OldEmailComponent } from './views/old-email/old-email.component';
import {
  patientDashboardDataResolver,
  createPatientProgram_DataResolver,
  patientDetailsDashboardDataResolver, physioDashboardDataResolver, DashboardDataResolver,
  SettingsDataResolver,
  ChangeEmailDataResolver,
  OldPasswordDataResolver
} from './resolvers';
import { NotificationsComponent } from '@pages/notifications/notifications.component';

// import {CreatePatientComponent} from '@components/create-patient/create-patient.component';
// import {ResetPasswordComponent} from '@pages/reset-password/reset-password.component';
// import {AboutComponent} from '@pages/about/about.component';


const routes: Routes = [

  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'confirm-password', component: OldEmailComponent, canActivate: [AuthGuard], resolve: { data: ChangeEmailDataResolver } },
  { path: 'change-email', component: ChangeEmailComponent, canActivate: [AuthGuard, passwordChangeGuard] },
  {
    path: 'dashboard', component: DashboardComponent,
    canActivate: [AuthGuard],
    resolve: { data: DashboardDataResolver },
    children: [
      {
        path: 'patient',
        component: PatientComponent,
        resolve: { data: patientDashboardDataResolver },
      },
      {
        path: 'physiotherapist',
        component: PhysiotherapistComponent,
        resolve: { data: physioDashboardDataResolver },
        children: [
          { path: '', component: IndexComponent, pathMatch: 'full' },
          {
            path: 'create-patient', component: CreatePatientComponent,
          },
          {
            path: 'exercise/:id', component: IndexComponent,
            resolve: { data: physioDashboardDataResolver }
          },
          {
            path: ':id', component: PatientDetailsComponent,
            resolve: { data: patientDetailsDashboardDataResolver }

          },
          {
            path: ':id/create-program', component: CreateExerciseProgramComponent,
            resolve: { data: createPatientProgram_DataResolver }

          }

        ]
      },


    ]
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password/:hash', component: ResetPasswordComponent },
  { path: 'old-password', component: OldPasswordComponent, canActivate: [AuthGuard], resolve: { data: OldPasswordDataResolver } },
  { path: 'settings', component: SettingsScreenComponent, canActivate: [AuthGuard], resolve: { data: SettingsDataResolver }, },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'unsubscribe/:token', component: UnsubscribeComponent },
  { path: 'payment_failure', component: PaymentComponent },
  { path: 'payment_success', component: PaymentComponent },
  { path: '404', component: NotFoundComponent },
  //{ path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
