<div class="container">
  <section class="slice slice-lg pt-3">
    <div class="row justify-content-center">
      <div class="col-12 responsive-margin title-responsive">
          <!-- <a *ngIf="exerciseSelection" class="btn btn-transparent btn-icon py-0 pl-0"
              (click)="onBackButton(cancelModal)">
              <i class="fas fa-arrow-left"></i>
              {{ "patientDetails.back" | translate }}
            </a>-->
        <div class="d-flex align-items-center">
          <a
            [ngClass]="{ hidden: templatesTab || exercisesTab }"
            class="btn btn-transparent btn-icon p-0 m-0 d-flex g-2"
            href="#"
          >
            <img src="assets/img/svg/icons/left-arrow.svg" />
            {{ "patientDetails.back" | translate }}
          </a>

          <a
            [ngClass]="{ hidden: !programCreation }"
            class="btn btn-transparent btn-icon p-0 m-0 d-flex g-2"
            (click)="backToExerciseSelection(calendarComponent)"
          >
            <img src="assets/img/svg/icons/left-arrow.svg" />
            {{ "patientDetails.back" | translate }}
          </a>
        </div>

        <h1 class="title">
          {{ "createExerciseProgram.createNew" | translate }}
        </h1>

        <div style="visibility: hidden">
          <button
            type="button"
            class="btn btn-warning btn-circle btn-sm mr-2"
            (click)="openModal(InformationModal)"
          >
            <i class="fas fa-question" style="color: white"></i>
          </button>
        </div>
      </div>

      <!-- template & exercise blocks -->
      <div class="col-lg-7 col-md-7 col-12 pr-5">
        <div *ngIf="exerciseSelection">
          <!-- template block -->
          <div
            class="row col-12 d-flex g-3"
            [ngClass]="{ 'arrow-container': templatesTab }"
            *ngIf="!exercisesTab"
          >
            <img
              src="assets/img/svg/icons/left-arrow.svg"
              [ngClass]="{ deleted: !templatesTab }"
              (click)="templatesTab = false"
              style="cursor: pointer"
            />
            <h2 class="mb-0 sub-title">
              {{ "createExerciseProgram.useTemplate" | translate }}
            </h2>
            <img
              src="assets/img/svg/icons/right-arrow.svg"
              [ngClass]="{ hidden: templatesTab }"
              style="cursor: pointer"
              (click)="templatesTab = true"
            />
          </div>

          <hr [ngClass]="{ deleted: exercisesTab }" />

          <div *ngIf="templatesTab" class="mb-3">
            <div class="d-flex g-4 mb-4 mt-4 w-full justify-content-between">
              <div
                class="d-flex align-items-center justify-content-center custom-button g-2"
                (click)="addTemplate(); templatePage = 1"
                style="flex: 2"
              >
                <img src="assets/img/svg/icons/plus-sign.svg" />
                <span style="font-size: 14px; font-weight: 700">
                  {{
                    "createExerciseProgram.templates.createNewTemplate"
                      | translate
                  }}
                </span>
              </div>

              <div
                class="d-flex align-items-center justify-content-center w-full custom-button-dark"
                (click)="unselectAllTemplates()"
                style="flex: 1"
                *ngIf="
                  tempChosenExercisesInTemplates.length > 0 && templatesTab
                "
              >
                <span style="font-size: 14px; font-weight: 700">
                  {{ "createExerciseProgram.unselect" | translate }}

                  {{ tempChosenExercisesInTemplates.length }}

                  {{ "createExerciseProgram.exercises" | translate }}
                </span>
              </div>
            </div>

            <div *ngFor="let template of paginatedTemplates(); index as i">
              <div class="card py-3 pl-4 pr-4 pt-5 pb-4">
                <!-- one exercise in template in standart mode -->
                <div
                  class="w-100 row mx-0"
                  [ngClass]="{ 'mb-3': template.expanded }"
                >
                  <div class="d-flex justify-content-between w-100">
                    <h3
                      *ngIf="!template.added && !template.edited"
                      class="mb-3"
                      style="font-size: 20px; font-weight: 700"
                    >
                      {{ template.template_name }}
                    </h3>

                    <input
                      *ngIf="template.added || template.edited"
                      class="custom-input"
                      type="text"
                      placeholder="Template Name"
                      [(ngModel)]="template.template_name"
                    />

                    <input
                      *ngIf="!template.edited && !template.added"
                      class="checkbox-relative"
                      type="checkbox"
                      name="checkbox"
                      [checked]="tempChosenTemplates.includes(template._id)"
                      (change)="onTemplateCheckboxChange($event, template)"
                    />
                  </div>

                  <div
                    class="d-flex w-100 justify-content-between"
                    *ngIf="!template.added"
                  >
                    <div class="d-flex g-2">
                      <div>Template</div>
                      <div>•</div>
                      <div>{{ template.exercise_program.length }}</div>
                      <div>
                        Exercise<span
                          *ngIf="template.exercise_program.length > 1"
                          >s</span
                        >
                      </div>
                      <div>
                        <span
                          ><img
                            style="cursor: pointer"
                            src="assets/img/svg/icons/arrow-dovn.svg"
                            *ngIf="!template.expanded"
                            (click)="
                              toggleTemplate(template); $event.stopPropagation()
                            " />
                          <img
                            style="cursor: pointer"
                            src="assets/img/svg/icons/arrow-up.svg"
                            *ngIf="template.expanded"
                            (click)="
                              toggleTemplate(template); $event.stopPropagation()
                            "
                        /></span>
                      </div>
                    </div>

                    <div
                      class="my-auto h-100 d-flex align-items-center justify-content-end flex-row ml-auto"
                      style="width: 17%; gap: 0.5rem"
                    >
                      <img
                        style="cursor: pointer"
                        src="assets/img/svg/icons/edit.svg"
                        (click)="
                          template.edited = true;
                          template.added = true;
                          refreshSelectedTemplateExercisesList(template)
                        "
                        *ngIf="
                          template.expanded &&
                          !(template.edited && template._id)
                        "
                      />

                      <img
                        style="cursor: pointer"
                        src="assets/img/svg/icons/bin.svg"
                        *ngIf="
                          template.expanded &&
                          !(template.edited && template._id)
                        "
                        (click)="
                          openModal(deleteTemplateModal);
                          templateDeletionIndex = i;
                          $event.stopPropagation()
                        "
                      />
                    </div>
                  </div>
                </div>

                <!-- one exercise in template in edit mode -->
                <div
                  *ngIf="template.expanded && template.exercise_program?.length"
                  class="w-100 row mx-0 mb-3"
                >
                  <div class="w-100">
                    <div
                      *ngFor="
                        let exercise of template.exercise_program;
                        index as j
                      "
                    >
                      <div
                        class="element-of-template d-flex flex-column"
                        *ngIf="template.edited"
                      >
                        <!-- button to delete one exercises from template -->
                        <div class="d-flex align-self-end">
                          <i
                            class="fas fa-minus-circle"
                            style="
                              font-size: 25px;
                              color: #7b3939;
                              cursor: pointer;
                            "
                            (click)="removeTemplateExercise(template, j)"
                          ></i>
                        </div>

                        <div
                          class="d-flex align-items-center justify-content-between g-3 flex-wrap w-100"
                        >
                          <div class="d-flex g-1 w-100" style="flex: 1">
                            <div class="d-flex align-items-center g-2">
                              <img
                                alt="Image placeholder"
                                [src]="getGifLinks(exercise.name)"
                                (error)="getExerciseGif($event, exercise)"
                                style="
                                  height: 80px;
                                  width: 80px;
                                  object-fit: contain;
                                "
                                id="navbar-logo"
                              />
                            </div>
                            <div class="d-flex flex-column g-2">
                              <h5
                                class="lh-120 my-0 font-weight-700"
                                style="font-size: 20px"
                              >
                                {{
                                  exercise.display_name[language] ??
                                    exercise.display_name["en"]
                                }}
                              </h5>
                              <p class="text-muted m-0" style="font-size: 16px">
                                <span class="font-weight-700">
                                  {{ "patientDetails.category" | translate }}:
                                </span>
                                {{
                                  "generalTerms." +
                                    (["breathing_time", "relaxation"].includes(
                                      exercise.category
                                    )
                                      ? "breathing"
                                      : exercise.category) | translate
                                }}
                              </p>
                              <div class="d-flex align-items-center p-0 g-2">
                                <p
                                  class="m-0 font-weight-700"
                                  style="font-size: 16px"
                                >
                                  {{ "generalTerms.aiTrainer" | translate }}
                                </p>
                                <ng-toggle
                                  [(ngModel)]="exercise.initial_pose.check"
                                  [color]="{
                                    unchecked: '#939da2',
                                    checked: '#33a1ff'
                                  }"
                                ></ng-toggle>
                              </div>
                            </div>
                          </div>

                          <div
                            class="d-flex flex-column align-items-center w-100"
                            style="flex: 1"
                          >
                            <div
                              *ngIf="exercise.hasOwnProperty('sets')"
                              class="d-flex justify-content-between w-100"
                            >
                              <!-- sets -->
                              <div
                                class="exercise-input"
                                (click)="test('sets')"
                              >
                                <label
                                  for="input-sets-{{ j }}"
                                  class="font-weight-bold"
                                  >{{
                                    "createExerciseProgram.sets" | translate
                                  }}</label
                                >
                                <input
                                  (click)="onInnerDivClick($event)"
                                  type="number"
                                  class="form-control text-center py-0"
                                  style="
                                    height: 2rem;
                                    width: 4rem;

                                    padding-left: 0.4rem;
                                    padding-right: 0.1rem;
                                  "
                                  id="input-sets-{{ j }}"
                                  name="input-sets-{{ j }}"
                                  [disabled]="
                                    exercise.name === 'sit2stand_test' ||
                                    ['breathing_time', 'relaxation'].includes(
                                      exercise.category
                                    )
                                  "
                                  placeholder="1"
                                  min="1"
                                  max="10"
                                  [(ngModel)]="exercise.sets"
                                />
                              </div>

                              <!-- break -->
                              <div
                                *ngIf="
                                  !['breathing_time', 'relaxation'].includes(
                                    exercise.category
                                  )
                                "
                                (click)="test('break')"
                                class="exercise-input"
                              >
                                <label
                                  for="input-break-{{ j }}"
                                  class="font-weight-bold"
                                  >{{
                                    "createExerciseProgram.break" | translate
                                  }}
                                </label>
                                <input
                                  (click)="onInnerDivClick($event)"
                                  type="number"
                                  class="form-control text-center py-0"
                                  style="
                                    height: 2rem;
                                    width: 4rem;

                                    padding-left: 0.4rem;
                                    padding-right: 0.1rem;
                                  "
                                  id="input-break-{{ j }}"
                                  name="input-break-{{ j }}"
                                  [disabled]="
                                    exercise.name === 'sit2stand_test'
                                  "
                                  placeholder="25"
                                  min="25"
                                  [(ngModel)]="exercise.set_break.duration"
                                />
                              </div>

                              <!-- break -->
                              <div
                                *ngIf="
                                  exercise.category === 'stretch' &&
                                  exercise.actions[1] &&
                                  (exercise.actions[1] | hasProp : 'duration')
                                "
                                class="exercise-input"
                                (click)="test('break-2')"
                              >
                                <div>
                                  <label
                                    for="input-break-duration-{{ j }}"
                                    class="font-weight-bold"
                                    >{{
                                      "createExerciseProgram.break" | translate
                                    }}
                                  </label>
                                  <input
                                    (click)="onInnerDivClick($event)"
                                    type="number"
                                    class="form-control text-center py-0"
                                    style="
                                      height: 2rem;
                                      width: 4rem;

                                      padding-left: 0.4rem;
                                      padding-right: 0.1rem;
                                    "
                                    id="input-break-duration-{{ j }}"
                                    name="input-break-duration-{{ j }}"
                                    placeholder="10"
                                    min="1"
                                    [(ngModel)]="exercise.actions[1].duration"
                                    (change)="
                                      onChangingStretchDuration(exercise)
                                    "
                                  />
                                </div>
                              </div>

                              <!-- count -->
                              <div
                                *ngIf="
                                  exercise.category === 'stretch' &&
                                    exercise.actions[0] | hasProp : 'count'
                                "
                                class="exercise-input"
                                (click)="test('count-1')"
                              >
                                <label
                                  for="input-side-count-{{ j }}"
                                  class="font-weight-bold"
                                  >{{ "exercise.count" | translate }}</label
                                >
                                <input
                                  (click)="onInnerDivClick($event)"
                                  type="number"
                                  class="form-control text-center py-0"
                                  style="
                                    height: 2rem;
                                    width: 4rem;

                                    padding-left: 0.4rem;
                                    padding-right: 0.1rem;
                                  "
                                  id="input-side-count-{{ j }}"
                                  name="input-side-count-{{ j }}"
                                  placeholder="10"
                                  min="1"
                                  [(ngModel)]="exercise.actions[0].count"
                                  (change)="
                                    exercise.actions[1].count =
                                      exercise.actions[0].count
                                  "
                                />
                              </div>

                            <!-- count -->
                              <div
                                *ngIf="
                                  exercise.actions[0] | hasProp : 'count'
                                "
                              >
                                <label
                                  for="input-counter"
                                  class="font-weight-bold"
                                  >{{ "exercise.count" | translate }}</label
                                >
                                <input
                                  (click)="onInnerDivClick($event)"
                                  type="number"
                                  class="form-control text-center py-0"
                                  style="
                                    height: 2rem;
                                    width: 4rem;

                                    padding-left: 0.4rem;
                                    padding-right: 0.1rem;
                                  "
                                  id="input-counter"
                                  name="input-counter"
                                  placeholder="10"
                                  min="1"
                                  max="30"
                                  [(ngModel)]="exercise.actions[0].count"
                                />
                              </div>

                              <!-- duration -->
                              <div
                                *ngIf="exercise.category === 'lower_body'"
                                class="exercise-input d-flex"
                                (click)="test('duration-1')"
                              >
                                <div
                                  *ngIf="
                                    exercise.actions[0] | hasProp : 'duration'
                                  "
                                >
                                  <label
                                    for="input-duration-{{ j }}"
                                    class="font-weight-bold"
                                    style="white-space: nowrap"
                                    >{{
                                      "createExerciseProgram.duration"
                                        | translate
                                    }}
                                  </label>
                                  <input
                                    (click)="onInnerDivClick($event)"
                                    type="number"
                                    class="form-control text-center py-0"
                                    style="
                                      height: 2rem;
                                      width: 4rem;

                                      padding-left: 0.4rem;
                                      padding-right: 0.1rem;
                                    "
                                    id="input-duration-{{ j }}"
                                    name="input-duration-{{ j }}"
                                    placeholder="10"
                                    min="1"
                                    [(ngModel)]="exercise.actions[0].duration"
                                    [disabled]="
                                      exercise.name === 'sit2stand_test'
                                    "
                                  />
                                </div>
                              </div>

                              <!-- duration -->
                              <div
                                *ngIf="exercise.category === 'breathing_time'"
                                class="exercise-input"
                                (click)="test('duration-2')"
                              >
                                <div>
                                  <label
                                    for="input-breathing-{{ j }}"
                                    class="font-weight-bold text-center"
                                    style="white-space: nowrap"
                                    >{{
                                      "createExerciseProgram.duration"
                                        | translate
                                    }}
                                  </label>
                                  <input
                                    (click)="onInnerDivClick($event)"
                                    type="number"
                                    style="
                                      height: 2rem;
                                      width: 4rem;

                                      padding-left: 0.4rem;
                                      padding-right: 0.1rem;
                                    "
                                    class="form-control text-center py-0"
                                    id="input-breathing-{{ j }}"
                                    name="input-breathing-{{ j }}"
                                    placeholder="15"
                                    min="15"
                                    [(ngModel)]="exercise.actions[1].duration"
                                  />
                                </div>
                              </div>

                              <!-- duration -->
                              <div
                                *ngIf="exercise.category === 'relaxation'"
                                class="exercise-input d-flex"
                                (click)="test('duration-3')"
                              >
                                <div>
                                  <label
                                    for="input-breathing-{{ j }}"
                                    class="font-weight-bold text-center"
                                    style="white-space: nowrap"
                                    >{{
                                      "createExerciseProgram.duration"
                                        | translate
                                    }}
                                  </label>
                                  <input
                                    (click)="onInnerDivClick($event)"
                                    type="number"
                                    style="
                                      height: 2rem;
                                      width: 4rem;

                                      padding-left: 0.4rem;
                                      padding-right: 0.1rem;
                                    "
                                    class="form-control text-center py-0"
                                    id="input-breathing-{{ j }}"
                                    name="input-breathing-{{ j }}"
                                    placeholder="15"
                                    min="15"
                                    [(ngModel)]="exercise.actions[0].duration"
                                  />
                                </div>
                              </div>

                              <!-- repetitions -->
                              <div
                                *ngIf="exercise.category === 'breathing'"
                                class="exercise-input"
                                (click)="test('repetitions-1')"
                              >
                                <label
                                  for="input-repetitions-{{ i }}"
                                  class="font-weight-bold"
                                  >{{
                                    "createExerciseProgram.repetitions"
                                      | translate
                                  }}</label
                                >
                                <input
                                  (click)="onInnerDivClick($event)"
                                  type="number"
                                  class="form-control text-center py-0"
                                  style="
                                    height: 2rem;
                                    width: 4rem;

                                    padding-left: 0.4rem;
                                    padding-right: 0.1rem;
                                  "
                                  id="input-repetitions-{{ j }}"
                                  name="input-repetitions-{{ j }}"
                                  placeholder="1"
                                  min="1"
                                  max="10"
                                  [disabled]="
                                    ['breathing_time', 'relaxation'].includes(
                                      exercise.category
                                    )
                                  "
                                  [(ngModel)]="exercise.repetitions"
                                />
                              </div>

                              <!-- repetitions -->
                              <div
                                *ngIf="
                                  ![
                                    'sit2stand_test',
                                    'sit2stand_duration',
                                    'sit2stand_counter',
                                    'squat'
                                  ].includes(exercise.name) &&
                                  exercise.category !== 'breathing'
                                "
                                class="exercise-input"
                                (click)="test('repetitions-2')"
                              >
                                <label
                                  for="input-repetitions-{{ j }}"
                                  class="font-weight-bold"
                                  >{{
                                    "createExerciseProgram.repetitions"
                                      | translate
                                  }}</label
                                >
                                <input
                                  (click)="onInnerDivClick($event)"
                                  type="number"
                                  class="form-control text-center py-0"
                                  style="
                                    height: 2rem;
                                    width: 4rem;

                                    padding-left: 0.4rem;
                                    padding-right: 0.1rem;
                                  "
                                  id="input-repetitions-{{ j }}"
                                  name="input-repetitions-{{ j }}"
                                  placeholder="1"
                                  min="1"
                                  max="10"
                                  [disabled]="
                                    ['breathing_time', 'relaxation'].includes(
                                      exercise.category
                                    )
                                  "
                                  [(ngModel)]="exercise.repetitions"
                                />
                              </div>
                            </div>

                            <div
                              class="d-flex justify-content-between w-100"
                              *ngIf="exercise.actions[0].name == 'inhale'"
                            >
                              <!-- inhale -->
                              <div
                                *ngIf="exercise.category === 'breathing'"
                                class="exercise-input"
                              >
                                <div>
                                  <label
                                    for="input-inhale-{{ j }}"
                                    class="font-weight-bold text-center"
                                    style="white-space: nowrap"
                                    >{{
                                      "createExerciseProgram.inhale" | translate
                                    }}
                                  </label>
                                  <input
                                    (click)="onInnerDivClick($event)"
                                    type="number"
                                    class="form-control text-center py-0"
                                    style="
                                      height: 2rem;
                                      width: 4rem;

                                      padding-left: 0.4rem;
                                      padding-right: 0.1rem;
                                    "
                                    id="input-inhale-{{ j }}"
                                    name="input-inhale-{{ j }}"
                                    placeholder="1"
                                    min="1"
                                    max="10"
                                    [(ngModel)]="exercise.actions[0].duration"
                                  />
                                </div>
                              </div>

                              <!-- hold -->
                              <div
                                *ngIf="exercise.category === 'breathing'"
                                class="exercise-input"
                              >
                                <div>
                                  <label
                                    for="input-hold-{{ j }}"
                                    class="font-weight-bold text-center"
                                    style="white-space: nowrap"
                                    >{{
                                      "createExerciseProgram.hold" | translate
                                    }}
                                  </label>
                                  <input
                                    (click)="onInnerDivClick($event)"
                                    type="number"
                                    class="form-control text-center py-0"
                                    (blur)="exercise.actions[1].duration = exercise.actions[1].duration ? exercise.actions[1].duration : 0"
                                    (change)="
                                      updateExerciseHoldDuration(
                                        $event,
                                        exercise
                                      )
                                    "
                                    style="
                                      height: 2rem;
                                      width: 4rem;

                                      padding-left: 0.4rem;
                                      padding-right: 0.1rem;
                                    "
                                    id="input-hold-{{ j }}"
                                    name="input-hold-{{ j }}"
                                    placeholder="1"
                                    min="0"
                                    max="10"
                                    [(ngModel)]="exercise.actions[1].duration"
                                  />
                                </div>
                              </div>

                              <!-- exhale -->
                              <div
                                *ngIf="exercise.category === 'breathing'"
                                class="exercise-input"
                              >
                                <div>
                                  <label
                                    for="input-exhale-{{ j }}"
                                    class="font-weight-bold text-center"
                                    style="white-space: nowrap"
                                    >{{
                                      "createExerciseProgram.exhale" | translate
                                    }}
                                  </label>
                                  <input
                                    (click)="onInnerDivClick($event)"
                                    type="number"
                                    class="form-control text-center py-0"
                                    style="
                                      height: 2rem;
                                      width: 4rem;

                                      padding-left: 0.4rem;
                                      padding-right: 0.1rem;
                                    "
                                    id="input-exhale-{{ j }}"
                                    name="input-exhale-{{ j }}"
                                    placeholder="1"
                                    min="1"
                                    max="10"
                                    [(ngModel)]="exercise.actions[2].duration"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- one exercise of template regular mode -->
                      <div
                        class="element-of-template d-flex g-2"
                        *ngIf="!template.edited"
                      >
                        <div
                          class="d-flex align-items-center g-2"
                          style="flex: 2"
                        >
                          <img src="assets/img/svg/icons/ellipse.svg" />
                          <span>{{
                            exercise.display_name[language] ??
                              exercise.display_name["en"]
                          }}</span>
                        </div>

                        <div
                          class="d-flex justify-content-between align-items-center w-100 g-2"
                          style="font-size: 14px; font-weight: inherit; flex: 3"
                          *ngIf="
                            exercise.actions[0].name == 'inhale';
                            else notInhale
                          "
                        >
                          <div
                            class="d-flex flex-column"
                            style="min-width: 70px"
                          >
                            <span style="white-space: nowrap">
                              {{ "createExerciseProgram.sets" | translate }}:
                              {{ exercise.sets }}
                            </span>
                            <span style="white-space: nowrap">
                              {{ "createExerciseProgram.inhale" | translate }}:
                              {{ exercise.actions[0].duration }}
                            </span>
                          </div>

                          <div class="d-flex flex-column">
                            <span style="white-space: nowrap">
                              {{ "createExerciseProgram.break" | translate }}:
                              {{ exercise.set_break.duration }}
                            </span>
                            <span style="white-space: nowrap">
                              {{ "createExerciseProgram.hold" | translate }}:
                              {{ exercise.actions[1].duration }}
                            </span>
                          </div>
                          <div class="d-flex flex-column">
                            <span style="white-space: nowrap">
                              {{
                                "createExerciseProgram.repetitions" | translate
                              }}:
                              {{ exercise.repetitions }}
                            </span>
                            <span style="white-space: nowrap">
                              {{ "createExerciseProgram.exhale" | translate }}:
                              {{ exercise.actions[2].duration }}
                            </span>
                          </div>
                        </div>

                        <ng-template #notInhale>
                          <div
                            class="d-flex justify-content-between align-items-center w-100 g-2"
                            style="
                              font-size: 14px;
                              font-weight: inherit;
                              flex: 3;
                            "
                          >
                            <div class="d-flex" style="min-width: 70px">
                              <span style="white-space: nowrap">
                                {{ "createExerciseProgram.sets" | translate }}:
                                {{ exercise.sets }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="
                                !['breathing_time', 'relaxation'].includes(
                                  exercise.category
                                )
                              "
                            >
                              <span style="white-space: nowrap">
                                {{ "createExerciseProgram.break" | translate }}:
                                {{ exercise.set_break.duration }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="
                                exercise.category === 'stretch' &&
                                exercise.actions[1] &&
                                (exercise.actions[1] | hasProp : 'duration')
                              "
                            >
                              <span style="white-space: nowrap">
                                {{ "createExerciseProgram.break" | translate }}:
                                {{ exercise.actions[1].duration }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="exercise.actions[0] | hasProp : 'count'"
                            >
                              <span style="white-space: nowrap">
                                {{ "exercise.count" | translate }}:
                                {{ exercise.actions[0].count }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="
                                exercise.category === 'lower_body' &&
                                  exercise.actions[0] | hasProp : 'duration'
                              "
                            >
                              <span style="white-space: nowrap">
                                {{
                                  "createExerciseProgram.duration" | translate
                                }}:
                                {{ exercise.actions[0].duration }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="exercise.category === 'breathing_time'"
                            >
                              <span style="white-space: nowrap">
                                {{
                                  "createExerciseProgram.duration" | translate
                                }}:
                                {{ exercise.actions[1].duration }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="exercise.category === 'relaxation'"
                            >
                              <span style="white-space: nowrap">
                                {{
                                  "createExerciseProgram.duration" | translate
                                }}:
                                {{ exercise.actions[0].duration }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="exercise.category === 'breathing'"
                            >
                              <span style="white-space: nowrap">
                                {{
                                  "createExerciseProgram.repetitions"
                                    | translate
                                }}:
                                {{ exercise.repetitions }}
                              </span>
                            </div>

                            <div
                              class="d-flex"
                              style="min-width: 70px"
                              *ngIf="
                                ![
                                  'sit2stand_test',
                                  'sit2stand_duration',
                                  'sit2stand_counter',
                                  'squat'
                                ].includes(exercise.name) &&
                                exercise.category !== 'breathing'
                              "
                            >
                              <span style="white-space: nowrap">
                                {{
                                  "createExerciseProgram.repetitions"
                                    | translate
                                }}:
                                {{ exercise.repetitions }}
                              </span>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- button to add exercise to template -->
                <div
                  *ngIf="template.expanded"
                  ngbDropdown
                  class="w-full px-3"
                  (click)="
                    refreshSelectedTemplateExercisesList(template);
                    $event.stopPropagation()
                  "
                >
                  <div
                    class="exercise-options d-flex align-items-center justify-content-center w-full"
                  >
                    <i
                      class="fas fa-plus-circle"
                      aria-hidden="true"
                      style="font-size: 30px; color: #748094; cursor: pointer"
                      id="dropdownAddToTemplate"
                      ngbDropdownToggle
                    ></i>
                  </div>

                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownAddToTemplate"
                    style="width: max-content"
                  >
                    <div
                      class="d-flex align-items-center px-2"
                      style="width: 100%"
                    >
                      <p class="my-auto">
                        {{ "generalTerms.search" | translate }}:
                      </p>
                      <input
                        type="text"
                        class="form-control mx-2 my-1"
                        id="search-text-template"
                        aria-describedby="search-text-template"
                        style="height: 2.3rem"
                        [(ngModel)]="searchExerciseTemplate"
                        placeholder="{{ 'generalTerms.search' | translate }}"
                      />
                    </div>
                    <div
                      class="w-100 d-flex flex-column align-items-center"
                      style="
                        max-height: 25rem;
                        height: fit-content;
                        width: max-content;
                        overflow-y: auto;
                      "
                    >
                      <ng-directive
                        *ngIf="
                          selected_template_available_exercises
                            | searchFilter
                              : 'exercise'
                              : searchExerciseTemplate
                              : 'none' as filteredExerciseItems
                        "
                      >
                        <div
                          *ngFor="let exerciseOption of filteredExerciseItems"
                        >
                          <div
                            *ngIf="
                              !programHasExercise(
                                template.exercise_program,
                                exerciseOption
                              )
                            "
                            class="row mx-0 align-items-center"
                            style="
                              display: flex;
                              width: fit-content;
                              flex-wrap: nowrap;
                              cursor: pointer;
                            "
                            (click)="
                              template.added || template.edited
                                ? addTemplateExercise(template, exerciseOption)
                                : addTemplateExerciseAndSave(
                                    template,
                                    exerciseOption,
                                    i
                                  )
                            "
                            ngbDropdownItem
                          >
                            <div
                              class="d-flex px-0"
                              style="height: 3.5rem; width: 4rem"
                            >
                              <img
                                alt="Image placeholder"
                                [src]="getGifLinks(exerciseOption.name)"
                                (error)="getExerciseGif($event, exerciseOption)"
                                class="w-100 h-100 m-0"
                              />
                            </div>
                            <div
                              class="d-flex px-0 ml-2"
                              style="color: inherit"
                            >
                              <h6
                                class="lh-100 my-0 d-inline"
                                style="font-size: 0.9rem; color: inherit"
                              >
                                {{
                                  exerciseOption.display_name[language] ??
                                    exerciseOption.display_name["en"]
                                }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="filteredExerciseItems.length === 0">
                          <p class="font-italic text-center mt-3">
                            {{ "exercise.exerciseNotFound" | translate }}
                          </p>
                        </div>
                      </ng-directive>
                    </div>
                  </div>
                </div>

                <!-- buttons for save-cansel edit/add mode -->
                <div
                  class="d-flex justify-content-end g-2"
                  *ngIf="template.edited || template.added"
                >
                  <button
                    *ngIf="template.edited"
                    class="custom-button pl-2 pr-2"
                    (click)="
                      template.edited = false;
                      template.added = false;
                      refreshSelectedTemplateExercisesList(template)
                    "
                  >
                    Cancel
                  </button>

                  <button
                    *ngIf="template.added && !template.edited"
                    class="custom-button pl-2 pr-2"
                    (click)="
                      openModal(deleteTemplateModal);
                      templateDeletionIndex = i;
                      $event.stopPropagation()
                    "
                  >
                    Cancel
                  </button>
                  <button
                    [disabled]="
                      !template.template_name ||
                      !template.exercise_program?.length
                    "
                    class="custom-button-dark pl-2 pr-2"
                    (click)="
                      saveTemplate(template, i); $event.stopPropagation()
                    "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- exercises block -->
          <div
            class="row col-12 d-flex g-3 d-flex"
            [ngClass]="{ 'arrow-container': exercisesTab }"
            *ngIf="!templatesTab"
          >
            <img
              src="assets/img/svg/icons/left-arrow.svg"
              [ngClass]="{ deleted: !exercisesTab }"
              (click)="exercisesTab = false"
              style="cursor: pointer"
            />
            <h2 class="mb-0 sub-title">
              {{ "createExerciseProgram.selectExercises" | translate }}
            </h2>
            <img
              src="assets/img/svg/icons/right-arrow.svg"
              [ngClass]="{ hidden: exercisesTab }"
              style="cursor: pointer"
              (click)="exercisesTab = true"
            />
          </div>

          <hr [ngClass]="{ deleted: templatesTab }" />

          <div class="container" *ngIf="exercisesTab">
            <div class="container-responsive mb-4 g-2">
              <div class="d-flex align-items-center g-2">
                <div class="d-flex g-1">
                  <p class="my-auto">
                    {{ "generalTerms.search" | translate }}:
                  </p>
                  <input
                    type="email"
                    class="form-control my-auto"
                    id="search-text"
                    aria-describedby="search-text"
                    style="
                      max-height: 36px;
                      font-size: 14px;
                      min-width: 80px;
                      max-width: 120px;
                      padding: 5px;
                    "
                    [(ngModel)]="searchExerciseText"
                    placeholder="{{ 'generalTerms.search' | translate }}"
                  />
                </div>

                <div class="d-flex g-1">
                  <p class="my-auto">
                    {{ "generalTerms.filter" | translate }}:
                  </p>
                  <div ngbDropdown class="my-auto">
                    <button
                      *ngIf="exerciseFilterSelected === 'none'"
                      class="btn btn-xs btn-secondary"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                      style="
                        padding: 5px;
                        height: 36px;
                        max-width: 120px;
                        min-width: 70px;
                      "
                    >
                      {{ "generalTerms.select" | translate }}
                    </button>
                    <button
                      *ngIf="exerciseFilterSelected !== 'none'"
                      class="btn btn-xs btn-secondary"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                    >
                      {{ "generalTerms." + exerciseFilterSelected | translate }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                      <button
                        ngbDropdownItem
                        *ngFor="let option of exerciseFilterOptions"
                        (click)="exerciseFilterSelected = option"
                      >
                        {{ "generalTerms." + option | translate }}
                      </button>
                    </div>
                  </div>
                  <button
                    *ngIf="
                      exerciseFilterSelected !== 'none' || searchExerciseText
                    "
                    class="btn btn-xs btn-danger ml-3"
                    (click)="
                      searchExerciseText = ''; exerciseFilterSelected = 'none'
                    "
                  >
                    {{ "createExerciseProgram.removeFilters" | translate }}
                  </button>
                </div>
              </div>

              <div class="d-flex g-1 w-100" style="position: relative">
                <div
                  class="d-flex align-items-center justify-content-center w-full custom-button"
                  (click)="addNewTemplate()"
                  style="flex: 1"
                  *ngIf="
                    tempChosenExercisesInExercises.length > 0 && exercisesTab
                  "
                >
                  <span style="font-size: 14px; font-weight: 700">
                    {{ "createExerciseProgram.saveAsTemp" | translate }}
                  </span>
                </div>

                <div
                  class="d-flex align-items-center justify-content-center w-full custom-button-dark"
                  (click)="unselectAllExersies()"
                  style="flex: 1"
                  *ngIf="
                    tempChosenExercisesInExercises.length > 0 && exercisesTab
                  "
                >
                  <span style="font-size: 14px; font-weight: 700">
                    {{ "createExerciseProgram.unselect" | translate }}

                    {{ tempChosenExercisesInExercises.length }}

                    {{ "createExerciseProgram.exercises" | translate }}
                  </span>
                </div>

                <div
                  *ngIf="isModalOpen"
                  id="modal-content"
                  class="modal-content"
                >
                  <input
                    type="text"
                    [(ngModel)]="newTemplateName"
                    placeholder="{{
                      'createExerciseProgram.templates.title' | translate
                    }}"
                  />
                  <button (click)="saveNewTemplate()" class="round-button" [disabled]="newTemplateName.length === 0">
                    <img src="assets/img/svg/icons/checked.svg" />
                  </button>
                </div>
              </div>
            </div>

            <ng-directive
              *ngIf="
                allExercises
                  | searchFilter
                    : 'exercise'
                    : searchExerciseText
                    : exerciseFilterSelected as filteredExerciseItems
              "
            >
              <div
                *ngFor="
                  let exercise of filteredExerciseItems
                    | slice
                      : (exercisePage - 1) * exercisePageSize
                      : exercisePage * exercisePageSize;
                  index as i
                "
              >
                <div
                  class="card p-4"
                  [ngClass]="{
                    'card-border-primary': isExerciseSelected(exercise._id)
                  }"
                >
                  <div class="d-flex flex-column g-4 pt-4">
                    <div class="d-flex">
                      <div class="w-100 d-flex g-3">
                        <div class="d-flex align-items-center g-2">
                          <img
                            alt="Image placeholder"
                            [src]="getGifLinks(exercise.name)"
                            (error)="getExerciseGif($event, exercise)"
                            style="height: 80px"
                            id="navbar-logo"
                          />
                        </div>
                        <div class="d-flex flex-column g-2">
                          <h5 class="lh-120 my-0 font-weight-700">
                            {{
                              exercise.display_name[language] ??
                                exercise.display_name["en"]
                            }}
                          </h5>
                          <p class="text-muted m-0">
                            <span class="font-weight-700">
                              {{ "patientDetails.category" | translate }}:
                            </span>
                            {{
                              "generalTerms." +
                                (["breathing_time", "relaxation"].includes(
                                  exercise.category
                                )
                                  ? "breathing"
                                  : exercise.category) | translate
                            }}
                          </p>
                          <div class="d-flex align-items-center p-0 g-2">
                            <p class="m-0 font-weight-700">
                              {{ "generalTerms.aiTrainer" | translate }}
                            </p>
                            <ng-toggle
                              [(ngModel)]="exercise.initial_pose.check"
                              [color]="{
                                unchecked: '#939da2',
                                checked: '#33a1ff'
                              }"
                            ></ng-toggle>
                          </div>
                        </div>
                      </div>

                      <input
                        class="checkbox-relative"
                        type="checkbox"
                        style="top: -30px"
                        [checked]="isExerciseSelected(exercise._id)"
                        (change)="onExerciseCheckboxChange($event, exercise)"
                      />
                    </div>

                    <div
                      *ngIf="exercise.hasOwnProperty('sets')"
                      class="d-flex flex-wrap g-2 justify-content-around"
                    >
                      <!-- sets -->
                      <div class="exercise-input">
                        <label for="input-sets" class="font-weight-bold">{{
                          "createExerciseProgram.sets" | translate
                        }}</label>
                        <input
                          (click)="onInnerDivClick($event)"
                          type="number"
                          class="form-control text-center py-0"
                          style="
                            height: 2rem;
                            width: 5rem;

                            padding-left: 0.4rem;
                            padding-right: 0.1rem;
                          "
                          id="input-sets"
                          name="input-sets"
                          [disabled]="
                            exercise.name === 'sit2stand_test' ||
                            ['breathing_time', 'relaxation'].includes(
                              exercise.category
                            )
                          "
                          placeholder="1"
                          min="1"
                          max="10"
                          [(ngModel)]="exercise.sets"
                        />
                      </div>

                      <!-- break -->
                      <div
                        *ngIf="
                          !['breathing_time', 'relaxation'].includes(
                            exercise.category
                          )
                        "
                        class="exercise-input"
                      >
                        <label for="input-break" class="font-weight-bold"
                          >{{ "createExerciseProgram.break" | translate }}
                        </label>
                        <input
                          (click)="onInnerDivClick($event)"
                          type="number"
                          class="form-control text-center py-0"
                          style="
                            height: 2rem;
                            width: 5rem;

                            padding-left: 0.4rem;
                            padding-right: 0.1rem;
                          "
                          id="input-break"
                          name="input-break"
                          [disabled]="exercise.name === 'sit2stand_test'"
                          placeholder="25"
                          min="25"
                          [(ngModel)]="exercise.set_break.duration"
                        />
                      </div>

                      <!-- repetitions -->
                      <div
                        *ngIf="exercise.category === 'breathing'"
                        class="exercise-input"
                      >
                        <label
                          for="input-repetitions"
                          class="font-weight-bold"
                          >{{
                            "createExerciseProgram.repetitions" | translate
                          }}</label
                        >
                        <input
                          (click)="onInnerDivClick($event)"
                          type="number"
                          class="form-control text-center py-0"
                          style="
                            height: 2rem;
                            width: 5rem;

                            padding-left: 0.4rem;
                            padding-right: 0.1rem;
                          "
                          id="input-repetitions"
                          name="input-repetitions"
                          placeholder="1"
                          min="1"
                          max="10"
                          [disabled]="
                            ['breathing_time', 'relaxation'].includes(
                              exercise.category
                            )
                          "
                          [(ngModel)]="exercise.repetitions"
                        />
                      </div>

                      <!-- repetitions -->
                      <div
                        *ngIf="
                          ![
                            'sit2stand_test',
                            'sit2stand_duration',
                            'sit2stand_counter',
                            'squat'
                          ].includes(exercise.name) &&
                          exercise.category !== 'breathing'
                        "
                        class="exercise-input"
                      >
                        <label
                          for="input-repetitions"
                          class="font-weight-bold"
                          >{{
                            "createExerciseProgram.repetitions" | translate
                          }}</label
                        >
                        <input
                          (click)="onInnerDivClick($event)"
                          type="number"
                          class="form-control text-center py-0"
                          style="
                            height: 2rem;
                            width: 5rem;

                            padding-left: 0.4rem;
                            padding-right: 0.1rem;
                          "
                          id="input-repetitions"
                          name="input-repetitions"
                          placeholder="1"
                          min="1"
                          max="10"
                          [disabled]="
                            ['breathing_time', 'relaxation'].includes(
                              exercise.category
                            )
                          "
                          [(ngModel)]="exercise.repetitions"
                        />
                      </div>

                      <!-- duration -->
                      <div
                        *ngIf="exercise.category === 'lower_body'"
                        class="exercise-input d-flex"
                      >
                        <div *ngIf="exercise.actions[0] | hasProp : 'duration'">
                          <label for="input-duration" class="font-weight-bold"
                            >{{
                              "createExerciseProgram.duration" | translate
                            }}
                            (s)</label
                          >
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            class="form-control text-center py-0"
                            style="
                              height: 2rem;
                              width: 5rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            id="input-duration"
                            name="input-duration"
                            placeholder="10"
                            min="1"
                            [(ngModel)]="exercise.actions[0].duration"
                            [disabled]="exercise.name === 'sit2stand_test'"
                          />
                        </div>

                        <!-- count -->
                        <div *ngIf="exercise.actions[0] | hasProp : 'count'">
                          <label for="input-counter" class="font-weight-bold">{{
                            "exercise.count" | translate
                          }}</label>
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            class="form-control text-center py-0"
                            style="
                              height: 2rem;
                              width: 4rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            id="input-counter"
                            name="input-counter"
                            placeholder="10"
                            min="1"
                            max="30"
                            [(ngModel)]="exercise.actions[0].count"
                          />
                        </div>
                      </div>

                      <!-- count -->
                      <div
                        *ngIf="
                          exercise.category === 'stretch' && exercise.actions[0]
                            | hasProp : 'count'
                        "
                        class="exercise-input"
                      >
                        <label
                          for="input-side-count"
                          class="font-weight-bold"
                          >{{ "exercise.count" | translate }}</label
                        >
                        <input
                          (click)="onInnerDivClick($event)"
                          type="number"
                          class="form-control text-center py-0"
                          style="
                            height: 2rem;
                            width: 5rem;

                            padding-left: 0.4rem;
                            padding-right: 0.1rem;
                          "
                          id="input-side-count"
                          name="input-side-count"
                          placeholder="10"
                          min="1"
                          [(ngModel)]="exercise.actions[0].count"
                          (change)="
                            exercise.actions[1].count =
                              exercise.actions[0].count
                          "
                        />
                      </div>

                      <!-- break -->
                      <div
                        *ngIf="
                          exercise.category === 'stretch' &&
                          exercise.actions[1] &&
                          (exercise.actions[1] | hasProp : 'duration')
                        "
                        class="exercise-input"
                      >
                        <div>
                          <label
                            for="input-break-duration"
                            class="font-weight-bold"
                            >{{ "createExerciseProgram.break" | translate }}
                          </label>
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            class="form-control text-center py-0"
                            style="
                              height: 2rem;
                              width: 5rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            id="input-break-duration"
                            name="input-break-duration"
                            placeholder="10"
                            min="1"
                            [(ngModel)]="exercise.actions[1].duration"
                            (change)="onChangingStretchDuration(exercise)"
                          />
                        </div>
                      </div>

                      <!-- duration -->
                      <div
                        *ngIf="exercise.category === 'breathing_time'"
                        class="exercise-input"
                      >
                        <div>
                          <label
                            for="input-breathing"
                            class="font-weight-bold text-center"
                            style="white-space: nowrap"
                            >{{
                              "createExerciseProgram.duration" | translate
                            }}
                            (s)</label
                          >
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            style="
                              height: 2rem;
                              width: 5rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            class="form-control text-center py-0"
                            id="input-breathing"
                            name="input-breathing"
                            placeholder="15"
                            min="15"
                            [(ngModel)]="exercise.actions[1].duration"
                          />
                        </div>
                      </div>

                      <!-- duration -->
                      <div
                        *ngIf="exercise.category === 'relaxation'"
                        class="exercise-input d-flex"
                      >
                        <div>
                          <label
                            for="input-breathing"
                            class="font-weight-bold text-center"
                            style="white-space: nowrap"
                            >{{
                              "createExerciseProgram.duration" | translate
                            }}
                            (s)</label
                          >
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            style="
                              height: 2rem;
                              width: 5rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            class="form-control text-center py-0"
                            id="input-breathing"
                            name="input-breathing"
                            placeholder="15"
                            min="15"
                            [(ngModel)]="exercise.actions[0].duration"
                          />
                        </div>
                      </div>

                      <!-- inhale -->
                      <div
                        *ngIf="exercise.category === 'breathing'"
                        class="exercise-input"
                      >
                        <div>
                          <label
                            for="input-inhale"
                            class="font-weight-bold text-center"
                            style="white-space: nowrap"
                            >{{ "createExerciseProgram.inhale" | translate }}
                          </label>
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            class="form-control text-center py-0"
                            style="
                              height: 2rem;
                              width: 5rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            id="input-inhale"
                            name="input-inhale"
                            placeholder="1"
                            min="1"
                            max="10"
                            [(ngModel)]="exercise.actions[0].duration"
                          />
                        </div>
                      </div>

                      <!-- hold -->
                      <div
                        *ngIf="exercise.category === 'breathing'"
                        class="exercise-input"
                      >
                        <div>
                          <label
                            for="input-hold"
                            class="font-weight-bold text-center"
                            style="white-space: nowrap"
                            >{{ "createExerciseProgram.hold" | translate }}
                          </label>
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            class="form-control text-center py-0"
                            (change)="
                              updateExerciseHoldDuration($event, exercise)
                            "
                              (blur)="exercise.actions[1].duration = exercise.actions[1].duration ? exercise.actions[1].duration : 0"

                            style="
                              height: 2rem;
                              width: 5rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            id="input-inhale"
                            name="input-hold"
                            placeholder="1"
                            min="0"
                            max="10"
                            [(ngModel)]="exercise.actions[1].duration"
                          />
                        </div>
                      </div>

                      <!-- exhale -->
                      <div
                        *ngIf="exercise.category === 'breathing'"
                        class="exercise-input"
                      >
                        <div>
                          <label
                            for="input-exhale"
                            class="font-weight-bold text-center"
                            style="white-space: nowrap"
                            >{{ "createExerciseProgram.exhale" | translate }}
                          </label>
                          <input
                            (click)="onInnerDivClick($event)"
                            type="number"
                            class="form-control text-center py-0"
                            style="
                              height: 2rem;
                              width: 5rem;

                              padding-left: 0.4rem;
                              padding-right: 0.1rem;
                            "
                            id="input-exhale"
                            name="input-exhale"
                            placeholder="1"
                            min="1"
                            max="10"
                            [(ngModel)]="exercise.actions[2].duration"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="filteredExerciseItems.length === 0">
                <p class="font-italic text-center text-lg my-6">
                  {{ "exercise.exerciseNotFound" | translate }}
                </p>
              </div>
            </ng-directive>
          </div>
        </div>
        <div *ngIf="programCreation">
          <div class="mt-3 mb-3">
            <app-calendar
              [functionality]="'program-creation'"
              [exerciseList]="allChosenExercises"
              [assignedEvents]="assignedExercises"
              (storeEvents)="storeTemporaryExercises($event)"
              (dayClicked)="onDayClicked($event)"
              (onAutoSelectDone)="onAutoSelectDone($event)"
              #calendarComponent
            ></app-calendar>
          </div>
          <div class="mt-3">
            <!--          <button class="btn btn-sm btn-secondary float-left" (click)="programCreation = false; exerciseSelection = true;">Back</button>-->
            <button
              class="btn btn-sm btn-primary float-right"
              [disabled]="
                selectedDatesEmpty(calendarComponent)  
              "
              (click)="createProgram(calendarComponent)"
            >
              {{ "createExerciseProgram.create" | translate }}
            </button>
            <button
              class="btn btn-sm btn-danger float-left"
              [disabled]="selectedDatesEmpty(calendarComponent)"
              (click)="
                openModal(clearSelectedExercisesModal, calendarComponent)
              "
            >
              {{ "createExerciseProgram.clear" | translate }}
            </button>
          </div>
        </div>
      </div>

      <!-- right panel mor aft evn -->
      <div class="col-lg-5 col-md-5 right-container p-3">
        <div
          class="outer-container-wrapper d-flex flex-column justify-content-between"
        >
          <div class="outer-container">
            <div class="tab-container">
              <div
                *ngFor="let tab of tabs; let i = index"
                class="tab font-weight-700"
                [ngClass]="{ active: selectedTab === i }"
                (click)="selectTab(i)"
                style="white-space: nowrap"
              >
                {{ tab.label | translate }}
                <span class="font-weight-400">
                  ({{ countAfterFilter(i) }})
                </span>
              </div>
            </div>

            <div class="content-container">
              <div
                class="d-flex justify-content-between align-items-center mb-4 mt-4"
              >
                <span>
                  <h3
                    *ngIf="selectedTab === 0"
                    style="font-size: 24px; margin: 0"
                  >
                    {{ "createExerciseProgram.morning" | translate }}
                    {{ "createExerciseProgram.program" | translate }}
                  </h3>
                  <h3
                    *ngIf="selectedTab === 1"
                    style="font-size: 24px; margin: 0"
                  >
                    {{ "createExerciseProgram.afternoon" | translate }}
                    {{ "createExerciseProgram.program" | translate }}
                  </h3>
                  <h3
                    *ngIf="selectedTab === 2"
                    style="font-size: 24px; margin: 0"
                  >
                    {{ "createExerciseProgram.evening" | translate }}
                    {{ "createExerciseProgram.program" | translate }}
                  </h3>
                </span>

                <div *ngIf="!programCreation">
                  <div
                    class="d-flex align-items-center justify-content-center w-full custom-button-dark"
                    (click)="addExercisesToPermanentList(selectedTab)"
                    *ngIf="chosenExercises.length>0"
                  >
                    <span
                      style="font-size: 12px"
                      class="ont-weight-700 d-flex align-items-center g-1"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 1L8 15"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M15 8L1 8"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                      <span>
                        {{
                          "createExerciseProgram.templates.addSelectedExercises"
                            | translate
                        }} 
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div *ngIf="countAfterFilter(selectedTab) === 0">
                <p class="font-italic font-weight-400" style="font-size: 18px">
                  {{ "createExerciseProgram.noExercisesChosen" | translate }}
                </p>
              </div>

              <div>
                <div
                  *ngFor="
                    let exercise of paginatedChosenExercises(selectedTab);
                    let i = index
                  "
                  class="element-of-choosen-ex"
                >
                  <div class="d-flex g-2">
                    <img src="assets/img/svg/icons/ellipse.svg" />
                    {{
                      exercise.display_name[language] ??
                        exercise.display_name["en"]
                    }}
                  </div>

                  <i
                    *ngIf="!programCreation"
                    (click)="deleteFromPermanentList(exercise)"
                    class="fas fa-minus-circle"
                    style="font-size: 20px; color: #7b3939; cursor: pointer"
                  ></i>
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <ngb-pagination
                  *ngIf="getExercisesForSelectedTab(selectedTab).length !== 0"
                  [(page)]="chosenExercisesPage"
                  [pageSize]="chosenExercisesPageSize"
                  [collectionSize]="
                    getExercisesForSelectedTab(selectedTab).length
                  "
                ></ngb-pagination>
              </div>
            </div>
          </div>

          <div
            class="d-flex align-self-end"
            *ngIf="totalCount()>0"
          >
            Total:
            <span>
              {{
                totalCount()
              }}
            </span>
          </div>
        </div>
      </div>

      <!-- Bottom block with pagination and next -->
      <div
        class="d-flex pt-4 pb-2 justify-content-between w-100"
        [ngClass]="{ hidden: !templatesTab && !exercisesTab }"
      >
        <div class="d-flex justify-content-center" style="flex: 3">
          <ngb-pagination
            *ngIf="templatesTab"
            [(page)]="templatePage"
            [pageSize]="templatePageSize"
            [collectionSize]="extendedTemplates.length"
          ></ngb-pagination>

          <ngb-pagination
            *ngIf="exercisesTab"
            [(page)]="exercisePage"
            [pageSize]="exercisePageSize"
            [collectionSize]="
              (
                allExercises
                | searchFilter
                  : 'exercise'
                  : searchExerciseText
                  : exerciseFilterSelected
              )?.length
            "
          ></ngb-pagination>
        </div>
        <div class="d-flex justify-content-end" style="flex: 2">
          <button
            *ngIf="!programCreation"
            class="custom-button-dark d-flex align-items-center g-2"
            [disabled]="totalCount()==0"
            (click)="next()"
          >
            {{ "generalTerms.next" | translate }}
            <svg
              class="arrow-svg"
              width="20"
              height="15"
              viewBox="0 0 20 15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="arrow-path"
                d="M0.25 7.5C0.25 7.08579 0.585786 6.75 1 6.75H19C19.4142 6.75 19.75 7.08579 19.75 7.5C19.75 7.91421 19.4142 8.25 19 8.25H1C0.585786 8.25 0.25 7.91421 0.25 7.5Z"
              />
              <path
                class="arrow-path"
                d="M12.4697 0.96967C12.7626 0.676777 13.2375 0.676777 13.5304 0.96967L19.5304 6.96967C19.8233 7.26256 19.8233 7.73744 19.5304 8.03033L13.5304 14.0303C13.2375 14.3232 12.7626 14.3232 12.4697 14.0303C12.1768 13.7374 12.1768 13.2626 12.4697 12.9697L17.9394 7.5L12.4697 2.03033C12.1768 1.73744 12.1768 1.26256 12.4697 0.96967Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>

  <!--Cancel modal-->

  <ng-template #cancelModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "createExerciseProgram.cancelExerciseProgram.title" | translate }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{ "createExerciseProgram.cancelExerciseProgram.question" | translate }}
      </p>
      <p>
        {{ "createExerciseProgram.cancelExerciseProgram.text1" | translate }}
        <span class="font-italic text-danger">{{
          "generalTerms.yes" | translate
        }}</span
        >{{ "createExerciseProgram.cancelExerciseProgram.text2" | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="modal.close(); closeProgramCreation()"
      >
        {{ "generalTerms.yes" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        (click)="modal.close()"
      >
        {{ "generalTerms.no" | translate }}
      </button>
    </div>
  </ng-template>

  <!--Delete Template modal-->

  <ng-template #deleteTemplateModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "createExerciseProgram.templates.deleteModalTitle" | translate }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); templateDeletionIndex = -1"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {{ "createExerciseProgram.templates.deleteModalText" | translate }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="modal.close(); deleteTemplate(templateDeletionIndex)"
      >
        {{ "generalTerms.yes" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        (click)="modal.close(); templateDeletionIndex = -1"
      >
        {{ "generalTerms.no" | translate }}
      </button>
    </div>
  </ng-template>

  <!--Clear selected exercises modal-->

  <ng-template #clearSelectedExercisesModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">
        {{ "createExerciseProgram.clearDates" | translate }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "createExerciseProgram.clearDatesQuestion" | translate }}</p>
      <!--    <p>If you click <span class="font-italic text-danger">Yes</span>, all your previous work will be lost!</p>-->
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-sm btn-danger"
        (click)="modal.close(); clearDates()"
      >
        {{ "generalTerms.yes" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        (click)="modal.close()"
      >
        {{ "generalTerms.no" | translate }}
      </button>
    </div>
  </ng-template>
</div>

<ng-template #InformationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <img
        class="mr-2"
        alt="Image placeholder"
        src="../../../assets/Logo/Breathment Logo_rev_white.jpg"
        style="height: 50px"
        id="navbar-mobilr-logo"
      />
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "tutorial.createExerciseProgram1" | translate }}</p>
    <p>{{ "tutorial.createExerciseProgram2" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      (click)="modal.close()"
    >
      {{ "generalTerms.close" | translate }}
    </button>
  </div>
</ng-template>
