import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';



//import { WebcamModule } from 'ngx-webcam';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeDe from '@angular/common/locales/de';
import localeTr from '@angular/common/locales/tr';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
// import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
// import { CookiebotConfig } from './services/cookiebot.config';


// Import angular-fusioncharts
import { FusionChartsModule } from 'angular-fusioncharts';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

// import { FusionChartsModule } from "angular-fusioncharts";
// import * as FusionCharts from 'fusioncharts';
import * as Widgets from 'fusioncharts/fusioncharts.widgets';
// import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'

import { VideoService } from './services/video.service';

import { AppComponent } from './app.component';
import { PhysiotherapistComponent } from './views/physiotherapist/physiotherapist.component';
import { LoginComponent } from './views/login/login.component';
import { PatientComponent } from './views/patient/patient.component';
import { PatientDetailsComponent } from './views/shared/patient-details/patient-details.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SurveyComponent } from './views/shared/survey/survey.component';

import { CreatePatientComponent } from './views/shared/create-patient/create-patient.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

import { CreateExerciseProgramComponent } from './views/shared/create-exercise-program/create-exercise-program.component';
import { CalendarComponent } from './views/shared/calendar/calendar.component';
import { NavbarComponent } from './views/shared/navbar/navbar.component';
import { ToastrModule } from 'ngx-toastr';
import { ErrorMessagesComponent } from './views/shared/error-messages/error-messages.component';
import { YesNoPipe } from './shared/pipes/yes-no.pipe';
import { FilterPipe } from "./shared/pipes/filter.pipe";
import { ChartsModule } from 'ng2-charts';
import { BreathingGraphComponent } from './views/shared/breathing-graph/breathing-graph.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { PhysicalExerciseComponent } from './views/exercises/physical-exercise/physical-exercise.component';
import { HasPropPipe } from './shared/pipes/has-prop.pipe';
import { MissingTranslationHelper } from "./shared/pipes/missing-translation-helper.pipe";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Interceptor } from "./services/interceptor/interceptor";
import { AuthService } from "./services/auth.service";
import { DynamicFormComponent } from '@components/survey/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from '@components/survey/dynamic-form/dynamic-question/dynamic-form-question.component';
import { ModalComponent } from '@components/modal/modal.component';

import { StoreModule } from '@ngrx/store';
import { BreathingExerciseComponent } from './views/exercises/breathing-exercise/breathing-exercise.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { IndexComponent } from './views/physiotherapist/index/index.component';
import { PaymentComponent } from './views/payment/payment.component';
import { FilterDeletedExercisesPipe } from './shared/pipes/filter-deleted-exercises.pipe';
import { MatTableModule } from '@angular/material/table';
import { PerformanceOverviewComponent } from './views/shared/patient-details/performance-overview/performance-overview.component';
import { LineChartComponent } from './views/shared/line-chart/line-chart.component';
import { NgToggleModule } from 'ng-toggle-button';

import { UnsubscribeComponent } from './views/unsubscribe/unsubscribe.component';
import { OldPasswordComponent } from './views/reset-password/old-password/old-password.component';
import { OldEmailComponent } from './views/old-email/old-email.component';
import { ChangeEmailComponent } from './views/change-email/change-email.component';
import { SettingsScreenComponent } from './views/settings-screen/settings-screen.component';
import { CustomDatePipe } from './shared/pipes/date.pipe';
import { PatientHomeComponent } from './views/patient/patient-home/patient-home.component';
import { CustomFormBuilderComponent } from './shared/custom-form-builder/custom-form-builder.component';
import { BreathingTimeExerciseComponent } from '@pages/exercises/breathing-time-exercise/breathing-time-exercise.component';
import { NotificationsComponent } from '@pages/notifications/notifications.component';
import { RelaxationExerciseComponent } from './views/exercises/relaxation-exercise/relaxation-exercise.component';


/* breathment FIREBASE CONFIG */

// const firebaseConfig = {
//   apiKey: "AIzaSyB29PLgHpO310Ko0Rvv1iWYqvGfO8EoaTU",
//   authDomain: "breathment.firebaseapp.com",
//   databaseURL: "https://breathment.firebaseio.com",
//   projectId: "breathment",
//   storageBucket: "breathment.appspot.com",
//   messagingSenderId: "239464058104",
//   appId: "1:239464058104:web:48af46be4a0055fa50ff0d",
//   measurementId: "G-SH4S7G33SV"
// };
/* breathment FIREBASE CONFIG */


/* breathment-staging FIREBASE CONFIG */

// const firebaseConfig = {
//   apiKey: "AIzaSyC2ICUWv9Wizalzc3Fu3LWB4a0gjJTW_00",
//   authDomain: "breathment-staging.firebaseapp.com",
//   databaseURL: "https://breathment-staging-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "breathment-staging",
//   storageBucket: "breathment-staging.appspot.com",
//   messagingSenderId: "97537147854",
//   appId: "1:97537147854:web:6308c4af928e9886e9619f",
//   measurementId: "G-DNZYYRMQQP"
// };
/* breathment-staging FIREBASE CONFIG */


/* breathment-production FIREBASE CONFIG */

const firebaseConfig = {
  apiKey: "AIzaSyB6ramrl6sO_kyoQ_6A3-ht7RPNAL-TYQY",
  authDomain: "breathment-production-bc2ad.firebaseapp.com",
  projectId: "breathment-production-bc2ad",
  storageBucket: "breathment-production-bc2ad.appspot.com",
  messagingSenderId: "692331334043",
  appId: "1:692331334043:web:7dfed943a65c02c943b93e",
  measurementId: "G-KDNVHYR947"
};
/* breathment-production FIREBASE CONFIG */



export function HttpLoaderFactory(handler: HttpBackend) {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
registerLocaleData(localeDe);
registerLocaleData(localeTr);
registerLocaleData(localeIt);
// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Widgets, FusionTheme);
@NgModule({
  declarations: [
    AppComponent,
    PhysiotherapistComponent,
    LoginComponent,
    PatientComponent,
    PatientDetailsComponent,
    SurveyComponent,
    CreatePatientComponent,
    ResetPasswordComponent,
    BreathingExerciseComponent,
    BreathingTimeExerciseComponent,
    CreateExerciseProgramComponent,
    CalendarComponent,
    NavbarComponent,
    ErrorMessagesComponent,
    YesNoPipe,
    BreathingGraphComponent,
    NotFoundComponent,
    PhysicalExerciseComponent,
    FilterPipe,
    HasPropPipe,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ModalComponent,
    DashboardComponent,
    IndexComponent,
    PaymentComponent,
    FilterDeletedExercisesPipe,
    PerformanceOverviewComponent,
    LineChartComponent,
    UnsubscribeComponent,
    OldPasswordComponent,
    OldEmailComponent,
    ChangeEmailComponent,
    SettingsScreenComponent,
    CustomDatePipe,
    PatientHomeComponent,
    CustomFormBuilderComponent,
    NotificationsComponent,
    RelaxationExerciseComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    //WebcamModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
    }),
    NgCircleProgressModule.forRoot({
      // set defaults here
      // "radius": 60,
      "space": -10,
      "unitsFontSize": "13",
      "unitsFontWeight": "400",
      "outerStrokeGradient": false,
      "outerStrokeWidth": 10,
      "outerStrokeColor": "#4882c2",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 10,
      "subtitleFontSize": "31",
      "subtitleFontWeight": "300",
      "animateTitle": false,
      "animationDuration": 1000,
      "showTitle": false,
      "showSubtitle": true,
      "showUnits": false,
      "showBackground": false,
      "responsive": true,
      "lazy": false
    }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    DragDropModule,
    MatProgressBarModule,
    ToastrModule.forRoot(),
    MatGridListModule,
    ChartsModule,
    NgxChartsModule,
    FusionChartsModule,
    StoreModule.forRoot({}, {}),

    MatTableModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
    NgToggleModule.forRoot(),
    // StoreModule.forRoot(reducers, {
    //   metaReducers
    // }),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [

    VideoService,
    DatePipe,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
