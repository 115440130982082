import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DashboardSettings } from '@schemas/settings/DashboardSettings';
import { AuthService } from '@services/auth.service';
import { StoreService } from '@services/store.service';
import { UserService } from '@services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Output() language: EventEmitter<any> = new EventEmitter<any>();

  public currentUser: any;
  public userInitial: string;
  public currentRoute: string;
  public controlRouteForDashboard: any;
  public routeName: string | undefined;
  public event$
  public id: string = '';
  public isThatCreateProgram: boolean = false;
  public locale: string = 'EN';
  firstName: string = '';
  lastName: string = '';
  constructor(
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private userService: UserService,
    private toastr: ToastrService,
    private storeService: StoreService,
    private ngzone: NgZone
  ) {
    this.authService.authenticatedUser.subscribe(value => {
      this.ngzone.run(() => {
        this.authService.me$.subscribe(val => {
          this.currentUser = val
          this.getUser();
        })
      })
    })
    this.locale = this.translate.currentLang.toUpperCase() ?? 'EN';
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang.toUpperCase() ?? 'EN';
    });
  }

  ngOnInit(): void {
    //this.getUser();
    this.getCurrentRouter();

    this.routeName = this.router.url;

    // if (!await this.authService.isAuthenticated()) {
    //   this.currentUser = null
    // }

  }


  getUser() {
    this.currentUser = this.authService.me
    if (this.currentUser) {
      this.currentUser.first_name = this.currentUser?.first_name ?? this.currentUser?.personal_information?.first_name ?? ''
      this.userInitial = this.currentUser.first_name?.[0];
    }

  }

  getCurrentRouter() {
    this.currentRoute = this.router.url;
    this.router.events.subscribe(path => {
      this.currentRoute = this.router.url;
      const idIndex = this.currentRoute.lastIndexOf('/') + 1;
      this.id = this.currentRoute.slice(idIndex);
      if (this.id == 'create-program') {
        return this.isThatCreateProgram = true;
      }
      const pattern = /.*[0-9].*/;
      if (pattern.test(this.id)) {
        this.isThatCreateProgram = false;
      } else {
        this.id = '';
      }
    });

  }

  logout() {
    this.storeService.updatesSearches({
      searchPatientText: '',
      searchExerciseText: '',
      exercisePage: 1,
      patientPage: 1,
      patientsFilterSelected: 'none',
      exerciseFilterSelected: 'none',
    })
    this.authService.logout();
  }

  changeEmail() {
    this.router.navigate(['change-email']);
  }


  // Helper Methods

  useLanguage(language: 'en' | 'de' | 'tr' | 'it') {
    if (this.currentUser) {
      const requestData = {
        language: language
      }
      this.storeService.changeUserLanguage(requestData).subscribe(() => { });
    }
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.language.emit(language);
    this.authService.onLanguageChanged(language)
  }

  navigateToDashboard() {
    this.router.navigate([`dashboard/${this.currentUser.role}`]);
  }

  navigate(url, section?) {
    this.router.navigateByUrl('/').then(() => {
      this.router.navigate([url], { queryParams: { section: section } });
    });
  }

  openModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  get isPhysioTherapist() {
    return this.currentUser?.role === 'physiotherapist';
  }

  get isPatient() {
    return this.currentUser?.role === 'patient';
  }

  get isDashboardActiveRoute() {
    return !DashboardSettings.DASHBOARDS.includes(this.currentRoute);
  }


}
