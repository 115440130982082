import {Component, OnInit, Input} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {GeneralService} from '@services/general/general.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  // @Input('surveyType') surveyType: number;
  @Input('surveyData') surveyData: any;
  @Input('isWeeklySurvey') isWeeklySurvey: boolean;

  public surveyDone = false;
  public firstAnswer: any;
  public surveyResult: any[] = [];
  public survey: any[];
  public currentLanguage: string;
  public dataLoaded: boolean = false;


  constructor(
    private generalService: GeneralService,
    private translate: TranslateService
  ) {
    this.currentLanguage = this.translate.currentLang;
  }

  ngOnInit(): void {
    if (this.surveyData) {
      this.dataLoaded = true;
    }
  }

  // helper methods

  answerSelected(question, answer) {
    this.survey[question].choice = this.survey[question].answers[answer].value;
    this.checkSurveyDone();
  }

  checkSurveyDone() {
    const choiceNull = this.survey.every(element => {
      return element.choice !== null;
    });
    if (choiceNull) {
      this.surveyDone = true;
    }
  }

  getData() {
    // let data = surveyResults
    // return data
    this.survey.forEach(question => {
      this.surveyResult.push(question.choice);
    });
    return this.surveyResult;


  }

}
