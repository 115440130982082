import { ProgramExercise } from './../../../services/program-manager/ProgramExercise';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef, AfterViewChecked,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { DatePipe, Location } from '@angular/common';

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { UserService } from '@services/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ExerciseService } from '@services/exercise/exercise.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';

// Models
import { TreatmentSummary } from '../../../schemas/exercise.model';
import { CalendarEvent } from 'angular-calendar';
import { PersonalInfoModel } from 'src/app/schemas/personalInformation.model';
import { Constants } from '../../../constants';

import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, BaseChartDirective } from 'ng2-charts';
import { newTaskModel } from 'src/app/schemas/newTask.model';
import { DataService } from '@services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  colors,
  buildCalendarEvents,
  addAlldates, transformOnboardingFormData
} from '@helpers/helper';
import { PerformanceOverviewComponent } from './performance-overview/performance-overview.component';
import * as moment from 'moment';
import { GeneralService } from '@services/general/general.service';
import { map, tap, filter } from 'rxjs/operators';
import { StoreService } from '@services/store.service';

import { Observable, Subject, Subscription } from 'rxjs';
import { IUserProfile } from 'src/app/resolvers';
import { ProgramManagerService } from '@services/program-manager/program-manager.service';
import { getDateAsString } from '@services/program-manager/Program';
import { IProgramExercise } from '@services/program-manager/IProgramExercise';
import { IExercise } from '@services/program-manager/IExercise';
import { Exercise } from '@services/program-manager/Exercise';
import { DateTime } from 'luxon';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('void', style({ height: '0px', overflow: 'hidden' })),
      state('*', style({ height: '*', overflow: 'hidden' })),
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        animate('500ms ease-out', style({ height: '*' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ height: '0px', overflow: 'hidden' }))
      ])
    ])
  ]
})
export class PatientDetailsComponent
  implements OnDestroy, OnInit, AfterViewChecked {
  @Input() patient: any;
  @Input() role: string;
  physiotherapistExercises?: Array<Exercise>;
  @Output() remainingExercise = new EventEmitter<boolean>();
  @ViewChild('calendarComponent') calendarComponent;
  @ViewChild('programsSection') programsSection: ElementRef;
  @ViewChild('topSection') topSection: ElementRef;
  @ViewChild('mainCalendar') mainCalendar: ElementRef;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @ViewChild(PerformanceOverviewComponent, { static: false })
  private perfOverviewComponent: PerformanceOverviewComponent;

  personalInformationGroup: UntypedFormGroup;
  public personalInfoModel: PersonalInfoModel = new PersonalInfoModel();
  active_exercise_periods: boolean[];
  trackByGroup(index: number, group: any[]): number {
    return index;
  }
  //Patterns
  alphabetPattern: string = Constants.alphabetPattern;
  emailPattern: string = Constants.emailPattern;

  //screen size
  public screenWidth: any;
  public screenHeight: any;
  public smallScreen: boolean;
  currentDate: moment.Moment;
  public repetitions: number = -1;
  public count: number = -1;
  public sets: number = 1;
  public setBreak: number = 0;

  public exercisesList: Array<IExercise>;
  public availableExercises: Array<Exercise> = [];
  public chosenExercise: any = {};
  public chosenProgram: any;
  public newDates: any[] = [];
  public inhaleDuration: number = null;
  public exhaleDuration: number = null;
  public exercise;
  public clinic = false;
  public rehabilitationCenter = false;
  public previousOperation = false;
  public exerciseFromDay: Boolean = false;
  public exercisesByDateWithDuplicates: any[] = [];
  public oldDayExercises: boolean = false;

  // Program Exercises

  public separetedProgramExercises: {
    0: IExercise[],
    1: IExercise[],
    2: IExercise[]
  } = { 0: [], 1: [], 2: [] };

  public selectedPeriod: { [key: string]: boolean } = { 0: true, 1: false, 2: false };
  public selectedExerciseOption: any = null;
  public dropdownTop: number = 0;
  public dropdownLeft: number = 0;

  public isDropdownOpen: boolean = false;

  public periodDropdownStates: { [key: number]: boolean } = {};
  public periodOptions: { [key: string]: boolean } = {
    '0': false,
    '1': false,
    '2': false,
  };

  // Calendar
  public expandedPeriods: { [key: string]: boolean } = {
    '0': true,
    '1': true,
    '2': true,
  };

  public expandedPeriodsObj: any = [
    { id: '0', name: 'patientDetails.morning' },
    { id: '1', name: 'patientDetails.afternoon' },
    { id: '2', name: 'patientDetails.evening' }
  ];

  public exercisesForDay: any = {
    0: [],
    1: [],
    2: [],
  };

  public exercisesForDayEmpty = true

  public ageNumber;
  public ageBirthdate;
  public age;

  // Alert
  public assignedExercisesExist = false;

  private _dataLoaded = false;
  set dataLoaded(value: boolean) {
    this._dataLoaded = value;
  }
  get dataLoaded() {
    return this._dataLoaded;
  }
  public exerciseMode = false;
  public createProgramMode: boolean = false;
  public inhale: number = 0;
  public exhale: number = 0;
  public overall: number = 0;
  public posture: number = 0;
  public shoulder: number = 0;
  public exerciseDetails = {
    inhale_duration: null,
    exhale_duration: null,
    sets: null,
    display: false,
  };
  public exerciseCategory: string;
  public breathingBarChartData: any = null;

  // Calendar
  events: CalendarEvent[] = [];
  calendarEvents: CalendarEvent[] = [];
  singleProgramEvents: CalendarEvent[] = [];
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  public emotion: any[] = [];
  public exerciseDay: any;
  public exerciseDayIndex: number;
  public chosenEvent: any;
  public beforeEvaluationColor: any;
  public afterEvaluationColor: any;
  public eventType: string;
  public titleDate: string;
  public exerciseResults: string;
  public breathingPlotData: any;
  public exerciseResultsError: boolean = false;
  public breathingPlotError: boolean = false;
  public exerciseResultsLoaded: boolean = false;

  public exerciseDetailsDate: string;

  public locale: string;

  // patient details
  public patientDetails: any;
  public orginalPatientDetails: any;
  private _activeTab: number = 0;
  set activeTab(tab: number) {
    this._activeTab = tab;

  }
  get activeTab() {
    return this._activeTab;
  }
  public activeProgramTab: number = 0;
  public activeResultTab: number = 1;
  public savingPatientDetails: boolean = false;
  public detailsEdited: boolean = false;

  // today's exercises
  public todayExercises: any[] = [];
  public todayAllExercises: any[] = [];
  public displayExerciseLimit: number = 4;

  // previous day's exercises
  public remainingPreviousExercises: any[] = [];

  public exercisePrograms: any[] = [];
  public exerciseProgramsCopy: any[] = [];
  public allExercisePrograms: any[] = [];
  public allExerciseProgramsWithDates: any[] = [];

  // all programs
  public allExercisesDates: any[] = [];
  public largestDate: any = '';
  public smallestDate: any = '';
  public fullDuration: any;
  public remaningduration: any;
  public diffAllDays: number = 0;
  public diffFromToday: number = 0;
  public doneDays: number = 0;
  public donePercentage: number = 0;
  public subtitle = '';

  // treatment summary
  public activeTreatmentSummary: TreatmentSummary;
  public performanceOverview: any;

  public multiSelect: Boolean = true;
  public editMode: Boolean[] = [];
  public assignedDates: Date[][] = [];

  public chosenDate: any;
  public deletedExercisesList: any[] = [];
  public tempExercises: any[] = [];

  public collapsed: boolean[] = [];
  public todayExercisesCollapsed: boolean[] = [];
  public previousExercisesCollapsed: boolean[] = [];
  public programSmallestDate: Date[] = [];
  public programLargestDate: Date[] = [];

  public programCalendarDates: any[][] = [];

  public edit = false;
  public tempActiveTab = 0;

  public countDisplayNames: string[] = [];
  public countValues: number[] = [];
  public countTotal: number[] = [];

  public countUnitDisplayNames: string[] = [];
  public countUnitValues: number[] = [];
  public countUnitMeanValue: number;

  public degreeMeanValue: number[] = [];
  public degreeMaxValue: number[] = [];
  public degreeDisplayName: number[] = [];

  public percentValue: number[] = [];
  public percentDisplayName: number[] = [];

  public problemDisplayName: String[] = [];
  public problemCountValue: number[] = [];
  public problemTotalValue: number[] = [];

  public problemDisplayNameNotNull: String[] = [];
  public problemCountValueNotNull: number[] = [];
  public problemTotalValueNotNull: number[] = [];

  public aiTrainerDisabled: boolean = false;
  public aiTrainerDisabledReason: string = '';
  hidden_events = [];
  view: any[] = [400, 400];
  legend: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#E44D25'],
  };

  single: any[] = [];
  DegreesArrays: any[][] = [];
  labelsArray: string[] = [];

  public physicalExercise: boolean;
  dataSources = [];

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  public maximumDegree: String = '';
  public averageDegree: String = '';
  public formattedDate: String = '';
  public myTasks: any = [];
  public addNewTask: boolean = false;
  public newTaskFormGroup: UntypedFormGroup;
  public newTaskModel: newTaskModel = new newTaskModel();
  public deletedTask: any;
  public updatedTask: any;
  public copyOfEditTask: newTaskModel;
  public dataArr: string[] = [];

  public addNewTaskAction: boolean;

  public isCollapsed = false;
  public completedTasks: number = 0;
  public exerciseToStart: any;
  public paramValue = '';
  public gifUrls: any[] = [];
  public data: any;
  Math = Math;
  displayedColumns: string[] = ['instruction', 'FER', 'FERsec'];
  dataSource = this.emotion;

  public allAppointments: any[] = [];
  public patientAppointments: any[] = [];
  public appointmentDateSelected: boolean = false;
  public selectedAppointmentSlot: any = null;
  public newAppointmentNote: string = '';
  public selectedAppointment: any = null;
  public appointmentNoteEdited: boolean = false;

  public newUserAccessLevel: string = '';

  public adherence: any = null;
  public currentRoute: any;
  patientId: string;
  language = 'en';
  me: IUserProfile;
  appointments: any;
  getRadioValue(el) {
    for (const opt of el.options) {
      if (opt.value == el.value) return opt.label;
    }
    if (el.value === undefined || null) return '';
    return `Not Found ${el.value}`
  }
  getCheckboxValue(el) {
    const values = el.options.map(opt => {
      const item = el.value?.filter(v => v.name == opt.name)?.[0];
      opt.value = item?.value ?? false;
      return opt
    }).filter(el => el.value)

    return values;
  }
  constructor(
    private userService: UserService,
    private exerciseService: ExerciseService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    public datepipe: DatePipe,
    private dataService: DataService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private generalService: GeneralService,
    private ngZone: NgZone,
    private pm: ProgramManagerService,
    private location: Location
  ) {
    this.me = this.authService.me;
    this.currentDate = moment();
    this.locale = this.translate.currentLang;
    pm.setLanguage(this.locale);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.getPatientVersions();
    });
    this.route.data.subscribe(({ data }) => {
      if (this.role == 'physiotherapist') {
        this.orginalPatientDetails = JSON.parse(
          JSON.stringify(data.patientDetails)
        );
        this.patient = data.patientDetails;
        //alert('this.route.data.subscribe')
        this.patientDetails = JSON.parse(JSON.stringify(data.patientDetails));
        this.exercisesList = data.exercisesList;
        this.patient.exerciseList = this.exercisesList;
        this.allExercisePrograms = data.allExercisePrograms;
        this.activeTreatmentSummary = data.activeTreatmentSummary;
        this.performanceOverview = data.performanceOverview ?? {};
        this.exetractPatientDetails();
        this.processPatientDetails(
          this.patientDetails,
          this.allExercisePrograms
        );
        this.appointments = data.appointments;

        this.adherence = {
          activeDays: data.activeDays,
          longestStreak: data.longestStreak,
        };
        this.ngZone.run(() => {
          this.dataLoaded = true;
        });
      }
    });

    this.patientId =
      this.me.role === 'patient'
        ? this.me.id
        : this.route.snapshot.paramMap.get('id');

    this.intializeNewTasksForm();
    this.translate
      .get('patientDetails.maximumDegree')
      .subscribe((res: string) => {
        this.maximumDegree = res;
      });
    this.translate
      .get('patientDetails.averageDegree')
      .subscribe((res: string) => {
        this.averageDegree = res;
      });

    // })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      pm.setLanguage(this.locale);
      this.processPatientDetails(this.patientDetails, this.allExercisePrograms);

      this.old_system = true;
      if (this.patientDetails && this.patientDetails.about_copd && Object.keys(this.patientDetails.about_copd)?.length == 0) {
        this.old_system = false
        transformOnboardingFormData(JSON.parse(JSON.stringify(this.orginalCards)), this.locale, this.translate, this.patientDetails)
          .then(cards => {
            this.allCards = cards
          })
      } else {

        this.handleOldPatientDetails();
      }
    });
    this.patientId = this.route.snapshot.paramMap.get('id');
    this.role = this.me.role;
    // this.route.data.subscribe(({ data }) => {
    //   this.onDataReceived(data);
    //   console.log(data)
    // });
    this.route.paramMap.subscribe((e) => {
      if (this.router.getCurrentNavigation()?.extras?.state?.reload) {
        this.storeService.refreshData$.next(true);
        (
          this.storeService.getPatientDetailsDashboardDataResolver(
            e.get('id')
          ) as Observable<any>
        ).subscribe((_) => { },
          err => {
            this.translate
              .get('toastr.getPatientDetailsError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });

            if (err.error === 'patientNotFound') {
              this.router.navigate(['404']);
            } else {
              this.router.navigate(['dashboard']);
            }
          });
      }
    });

    this.intializeNewTasksForm();
    this.translate
      .get('patientDetails.maximumDegree')
      .subscribe((res: string) => {
        this.maximumDegree = res;
      });
    this.translate
      .get('patientDetails.averageDegree')
      .subscribe((res: string) => {
        this.averageDegree = res;
      });
  }

  private destroy$ = new Subject<void>();
  unsub: Subscription;
  ngOnDestroy() {
    this.unsub.unsubscribe()
    this.destroy$.next();
    this.destroy$.complete();

    document.removeEventListener("click", this.closeListenerCallBack.bind(this))
  }

  allCards: any;
  orginalCards: any;
  old_system = false
  ngAfterViewChecked(): void { }
  generatePersonalInformationCard = async () => {
    return {
      title: await this.translate.get('createPatientForm.personalInformationCard.personalInformation').toPromise(),
      questions: [
        [{
          show: typeof this.patientDetails.personal_information.gender != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.salutation.title').toPromise(),
          value: await this.translate.get(`createPatientForm.personalInformationCard.salutation.${this.patientDetails.personal_information.gender}`, { default: 'Mr.' }).toPromise(),
          type: 'text'
        },
        {
          show: true,
          label: await this.translate.get('createPatientForm.personalInformationCard.firstName').toPromise(),
          value: this.patientDetails.personal_information.first_name,
          type: 'text'
        },
        {
          show: true,
          label: await this.translate.get('createPatientForm.personalInformationCard.lastName').toPromise(),
          value: this.patientDetails.personal_information.last_name,
          type: 'text'
        }],
        [{
          show: typeof this.patientDetails.personal_information.age != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.birthdate').toPromise(),
          value: this.patientDetails.personal_information.age,
          type: 'date'
        }],
        [{
          show: typeof this.patientDetails.personal_information.email != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.emailAddress').toPromise(),
          value: this.patientDetails.personal_information.email,
          type: 'text'
        },
        {
          show: typeof this.patientDetails.personal_information.phone != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.phone').toPromise(),
          value: this.patientDetails.personal_information.phone,
          type: 'text'
        }],
        [{
          show: typeof this.patientDetails.personal_information.contact_reason != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.contactReason').toPromise(),
          value: this.patientDetails.personal_information.contact_reason,
          type: 'text'
        }],
        [{
          show: typeof this.patientDetails.personal_information.reason_explanation != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.reasonExplanation').toPromise(),
          value: this.patientDetails.personal_information.reason_explanation,
          type: 'textarea'
        }],
        [{
          show: typeof this.patientDetails.personal_information.health_insurance != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.healthInsurance').toPromise(),
          value: this.patientDetails.personal_information.health_insurance,
          type: 'text'
        }],
        [{
          show: typeof this.patientDetails.personal_information.weight_kg != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.weight').toPromise(),
          value: `${this.patientDetails.personal_information.weight_kg} Kg.`,

        },
        {
          show: typeof this.patientDetails.personal_information.height_m != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.height').toPromise(),
          value: `${this.patientDetails.personal_information.height_m} m.`,
        }],
        [{
          show: typeof this.patientDetails.personal_information.status != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.status').toPromise(),
          value: await this.translate.get(`createPatientForm.personalInformationCard.${this.patientDetails.personal_information.status}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_information.smoking != 'undefined',
          label: await this.translate.get('createPatientForm.personalInformationCard.smoking').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.personal_information.smoking ? 'yes' : 'no'}`).toPromise(),
        }]
      ]
    }
  }
  generateAboutCopdCard = async () => {
    return {
      title: await this.translate.get('createPatientForm.copdCard.title').toPromise(),
      questions: [
        [{
          show: typeof this.patientDetails?.about_copd?.classification != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.classification').toPromise(),
          value: {
            'gold_1': 'GOLD l',
            'gold_2': 'GOLD ll',
            'gold_3': 'GOLD lll',
            'gold_4': 'GOLD V',
            'gold_5': 'GOLD Vl',
          }[this.patientDetails.about_copd.classification]
        }],
        [{
          show: typeof this.patientDetails.about_copd?.duration_years != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.duration').toPromise(),
          value: this.patientDetails.about_copd.duration_years
        }],
        [{
          show: typeof this.patientDetails?.about_copd?.o2_requirement != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.oxygen').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.about_copd.o2_requirement ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails?.about_copd?.ongoing_treatment != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.currentTreatment').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.about_copd.ongoing_treatment ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails?.about_copd?.actions != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.actions.title').toPromise(),
          values: await this.patientDetails.about_copd.actions.map(async (act) => {
            return await this.translate.get(`createPatientForm.copdCard.actions.${act}`).toPromise()
          })
        }],
        [{
          show: typeof this.patientDetails.about_copd?.medical_aids != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.medicalAids.title').toPromise(),
          values: this.patientDetails.about_copd.medical_aids.map(async (act) => {
            return await this.translate.get(`createPatientForm.copdCard.medicalAids.${act}`).toPromise()
          })
        }],
        [{
          show: typeof this.patientDetails?.about_copd?.symptoms != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.symptoms.title').toPromise(),
          values: this.patientDetails?.about_copd?.symptoms?.map(async (act) => {
            return await this.translate.get(`createPatientForm.copdCard.symptoms.${act}`, { default: act }).toPromise()
          })
        }],
        [{
          show: typeof this.patientDetails?.personal_story?.other_symptoms != 'undefined',
          label: await this.translate.get('createPatientForm.copdCard.extraSymptoms.title').toPromise(),
          value: this.patientDetails.personal_story?.other_symptoms ? await this.translate.get(`generalTerms.${this.patientDetails.personal_story.other_symptoms?.otherSymptoms ? 'yes' : 'no'}`).toPromise() : '',
        }]
      ]
    }
  }
  generateOtherInfoCard = async () => {
    return {
      title: await this.translate.get('createPatientForm.otherCard.title').toPromise(),
      questions: [
        [{
          show: typeof this.patientDetails.other_info.cardiovascular_disease != 'undefined',
          label: await this.translate.get('createPatientForm.otherCard.cardiovascularDisease').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.other_info.cardiovascular_disease ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.other_info.fluid_consumption != 'undefined',
          label: await this.translate.get('createPatientForm.otherCard.fluids').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.other_info.fluid_consumption ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.other_info.recent_surgery != 'undefined',
          label: await this.translate.get('createPatientForm.otherCard.surgery').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.other_info.recent_surgery ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.other_info.sensitive_veins != 'undefined',
          label: await this.translate.get('createPatientForm.otherCard.sensitiveVeins').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.other_info.sensitive_veins ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.other_info.chronic_disease != 'undefined',
          label: await this.translate.get('createPatientForm.otherCard.chronicDisease').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.other_info?.chronic_disease?.diagnosed ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.other_info.difficult_acts != 'undefined',
          label: await this.translate.get('createPatientForm.otherCard.activities.title').toPromise(),
          values: this.patientDetails.other_info.difficult_acts.map(async (act) => {
            return await this.translate.get(`createPatientForm.otherCard.activities.${act}`).toPromise()
          })
        }],
        [{
          show: typeof this.patientDetails.other_info.medical_aids != 'undefined',
          label: await this.translate.get('createPatientForm.otherCard.medicalAids.title').toPromise(),
          values: this.patientDetails.other_info.medical_aids.map(async (act) => {
            return await this.translate.get(`createPatientForm.otherCard.medicalAids.${act}`).toPromise()
          })
        }]
      ]
    }
  }
  generateLifeStyleCard = async () => {
    return {

      title: await this.translate.get('createPatientForm.lifeStyleCard.lifeStyle').toPromise(),
      questions: [
        [{
          show: typeof this.patientDetails.lifestyle.work_condition != 'undefined',
          label: await this.translate.get('createPatientForm.lifeStyleCard.employment').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.lifestyle?.work_condition?.employed ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.lifestyle.smoking != 'undefined',
          label: await this.translate.get('createPatientForm.lifeStyleCard.smoking').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.lifestyle.smoking ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.lifestyle.regular_breaks != 'undefined',
          label: await this.translate.get('createPatientForm.lifeStyleCard.regularBreaks').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.lifestyle.regular_breaks ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.lifestyle.lunch_break != 'undefined',
          label: await this.translate.get('createPatientForm.lifeStyleCard.lunchBreak').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails.lifestyle.lunch_break ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: this.patientDetails.lifestyle?.active_sports?.active,
          label: await this.translate.get('createPatientForm.lifeStyleCard.activeSports').toPromise(),
          value: await this.translate.get(`createPatientForm.lifeStyleCard.${this.patientDetails.lifestyle?.active_sports?.type}`, { default: this.patientDetails.lifestyle?.active_sports?.type }).toPromise()
        }]
      ]
    }
  }
  generatePersonalStoryCard = async () => {
    return {
      title: await this.translate.get('createPatientForm.personalStoryCard.title').toPromise(),
      questions: [
        [{
          show: typeof this.patientDetails?.personal_story?.chronic_disease != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.chronicDiseaseText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.chronic_disease?.chronicDisease ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.back_pain != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.backPainText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.back_pain?.backPain ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.cardiovascular_disease != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.cardiovascularDiseaseText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.cardiovascular_disease ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.concentration_problems != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.concentrationProblemsText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.concentration_problems ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.diabetes != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.diabetes').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.diabetes ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.ongoing_treatment != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.currentTreatmentText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.ongoing_treatment ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.sensitive_veins != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.sensitiveVeinsText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.sensitive_veins ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.sleep != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.sleepText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.sleep ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.stress != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.stressText').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.stress ? 'yes' : 'no'}`).toPromise(),
        }],
        [{
          show: typeof this.patientDetails.personal_story?.surgery != 'undefined',
          label: await this.translate.get('createPatientForm.personalStoryCard.surgery').toPromise(),
          value: await this.translate.get(`generalTerms.${this.patientDetails?.personal_story?.surgery ? 'yes' : 'no'}`).toPromise(),
        }],
      ]
    }
  }
  async handleOldPatientDetails() {
    this.old_system = true;
    this.allCards = [];

    let cardIndex = 0;

    if (this.patientDetails.personal_information && Object.keys(this.patientDetails.personal_information)?.length > 0) {
      this.allCards[cardIndex++] = await this.generatePersonalInformationCard();
    }
    if (this.patientDetails.about_copd && Object.keys(this.patientDetails.about_copd)?.length > 0) {

      this.allCards[cardIndex++] = await this.generateAboutCopdCard();
    }
    if (this.patientDetails.other_info && Object.keys(this.patientDetails.other_info)?.length > 0) {
      this.allCards[cardIndex++] = await this.generateOtherInfoCard();
    }
    if (this.patientDetails.lifestyle && Object.keys(this.patientDetails.lifestyle)?.length > 0) {
      this.allCards[cardIndex++] = await this.generateLifeStyleCard();
    }
    if (this.patientDetails.personal_story && Object.keys(this.patientDetails.personal_story)?.length > 0) {
      this.allCards[cardIndex++] = await this.generatePersonalStoryCard();
    }


  }
  onDataReceived(data) {
    try {
      this.ngZone.run(() => {
        this.patientId = this.route.snapshot.paramMap.get('id');
        this.getPatientVersions();
        if (data[this.patientId]) data = data[this.patientId];
        try {
          this.orginalCards = JSON.parse(JSON.stringify(data?.onBoardingForm?.cards));
        } catch (error) {
          this.orginalCards = []
        }
        this.patient = data.patientDetails;
        this.patientDetails = JSON.parse(JSON.stringify(data.patientDetails));
        this.old_system = true;
        if (this.patientDetails && this.patientDetails.about_copd && Object.keys(this.patientDetails.about_copd)?.length == 0) {
          this.old_system = false

          transformOnboardingFormData(JSON.parse(JSON.stringify(this.orginalCards)), this.locale, this.translate, this.patientDetails)
            .then(cards => {
              this.allCards = cards
            })
        }
        else {
          //handle backward comatability
          this.handleOldPatientDetails()

        }
        this.orginalPatientDetails = JSON.parse(
          JSON.stringify(data.patientDetails)
        );
        this.exercisesList = data.exercisesList;
        this.patient.exerciseList = this.exercisesList;
        this.allExercisePrograms = data.allExercisePrograms;

        this.pm.setPrograms(data.allExercisePrograms);
        this.activeTreatmentSummary = data.activeTreatmentSummary;
        this.performanceOverview = data.performanceOverview ?? {};
        this.exetractPatientDetails();
        this.processPatientDetails(
          this.patientDetails,
          this.allExercisePrograms
        );
        this.allAppointments = data.appointments;
        this.patientAppointments = this.allAppointments.filter(
          (a) => a.patient_id === this.patientId
        );

        this.adherence = {
          activeDays: data.activeDays,
          longestStreak: data.longestStreak,
        };
        this.dataLoaded = true;
      });
    } catch (error) {
      //console.log({ error });
    }
  }
  sub_events_listener: any;
  ngOnInit(): void {
    document.addEventListener('click', this.closeListenerCallBack.bind(this));

    // if (this.role == 'physiotherapist') {
    //   this.initPhysiotherapistData()
    // } else {
    //   this.initPatientData()
    // }
    try {
      this.sub_events_listener.unsubscribe();
    } catch (error) { }
    this.sub_events_listener = this.pm.all_events$.subscribe((events) => {
      this.singleProgramEvents = [];

      this.singleProgramEvents = events;

    });

    this.unsub = this.storeService.patients_dashboards_data.subscribe((data) => {
      if (!this.patientId) return;
      this.onDataReceived(data);
      this.ngZone.run(() => {
        this.updated = true;

        this.dataLoaded = true;

        setTimeout(() => {
          this.programsSection.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          this.storeService.displaySpinner.next(false);
        }, 100);
      });
    });
    this.generalService.getAllGifs().subscribe((urls) => {
      this.gifUrls = urls;
      this.gifUrls.forEach((urlPromise) => {
        urlPromise.then((url) => {
          this.dataArr.push(url);
        });
      });
    });
    this.currentRoute = this.router.url;
    const originalDateMoment = this.currentDate;
    const originalDate = originalDateMoment.toDate();
    this.formattedDate = moment(originalDate).format('D.M.YYYY');
  }

  getGifLinks(exerciseName: string): string | undefined {
    if (exerciseName === 'sit2stand_test') {
      exerciseName = 'squat.gif';
    }
    if (exerciseName === 'diaphragm') {
      exerciseName = 'breath.gif';
    }
    if (exerciseName === 'lip') {
      exerciseName = 'breath.gif';
    }
    return this.dataArr.find((link) => link.includes(exerciseName));
  }

  closeListenerCallBack() {
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;
      this.selectedExerciseOption = null;
    }
  }

  quickView() {
    this.route.queryParams.subscribe((params) => {
      this.paramValue = params['param'];
      if (this.paramValue === 'survey') {
        this.goToSurvey();
      } else if (this.paramValue === 'activity') {
        this.goToActivity();
      } else if (this.paramValue === 'performanceOverview') {
        this.goToPerfOverview();
      } else if (this.paramValue === 'exercisePrograms') {
        this.scrollToPrograms();
      }
    });
  }

  inputValidator(event: any, maxValue: number) {
    const pattern = /^[0-9]*$/;
    
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    
    }
    if (event.target.value > maxValue) {
      event.target.value = Math.floor(event.target.value / 10);
    }
  }

  removeLeadingZero(event) {
    if (event.target.value.startsWith('0')) {
      event.target.value = event.target.value.replace('0', '');
      return;
    }
  }

  checkEmptyValue(event) {
    if (event.target.value === '') event.target.value = 0;
  }

  confirmUpdateExercise(content, program?, component?) {
    for (const e of program.exercises) {
      e.sets = e.sets ?? 1;
      e.repetitions = e.repetitions ?? 1;
      for (const a of e.actions) {
        if ('duration' in a && a.name != "hold") {
          a.duration = a.duration ? a.duration : 1;
        }
      }
    }
    let programValid = true;
    for (const e of this.selectedProgramExercises) {
      const exerciseValid = this.checkExerciseInputValidation(e);
      if (!exerciseValid) {
        programValid = false;
        this.translate
          .get('toastr.exerciseProgramValidationError')
          .subscribe((res: string) => {
            if (
              e.display_name &&
              e.display_name[this.language]
            ) {
              this.toastr.error(
                e.display_name[this.language] +
                ' ' +
                res.substring(res.indexOf(' ') + 1)
              );
            } else {
              this.toastr.error(res);
            }
          });
      }
    }
    if (programValid) {
      this.openModal(content, program, component);
    }
  }

  checkExerciseInputValidation(exercise) {
    let valid = true;
    if (
      (exercise.sets || exercise.sets === 0) &&
      (exercise.sets <= 0 || exercise.sets > 10)
    ) {
      return false;
    }
    if (exercise.set_break?.duration && exercise.set_break.duration < 25) {
      return false;
    }
    if (exercise.category == 'breathing') {
      valid = this.checkBreathingInputValidation(exercise);
    }
    if (exercise.category == 'stretch') {
      valid = this.checkStretchInputValidation(exercise);
    }
    if (exercise.category == 'lower_body') {
      valid = this.checkLowerBodyInputValidation(exercise);
    }
    if (exercise.category == 'breathing_time') {
      valid = exercise.actions[1].duration >= 15;
    }
    if (exercise.category == 'relaxation') {
      valid = exercise.actions[0].duration >= 15;
    }
    return valid;
  }

  checkBreathingInputValidation(exercise) {
    if (exercise.repetitions < 0 || exercise.repetitions > 9) {
      return false;
    }
    var valid = true;
    exercise.actions.forEach((action) => {
      if (
        action.duration === undefined ||
        parseInt(action.duration) < 0 ||
        parseInt(action.duration) > 10
      ) {
        valid = false;
      }
    });

    return valid;
  }

  checkStretchInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.count) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }

  checkLowerBodyInputValidation(exercise) {
    if (exercise.repetitions <= 0 || exercise.repetitions > 9) {
      return false;
    }
    let valid = true;
    exercise.actions.forEach((action) => {
      if ('count' in action) {
        if (
          !action.count ||
          parseInt(action.count) <= 0 ||
          parseInt(action.count) > 30
        ) {
          valid = false;
        }
      }
      if ('duration' in action) {
        if (
          !action.duration ||
          parseInt(action.duration) <= 0 ||
          parseInt(action.duration) > 600
        ) {
          valid = false;
        }
      }
    });
    return valid;
  }

  triggerUpdateExercise(source?: boolean) {
    setTimeout(() => {
      this.triggerUpdateExercise(source);
    }, 1500);
  }

  getAllTasks() {
    const requestData = {
      patient_uid: this.patientId,
    };
    this.userService.getUserTasks(requestData).subscribe((data) => {
      this.myTasks = data['data'];
      this.intializeCompletedTasks();
    });
  }

  intializeCompletedTasks() {
    this.completedTasks = 0;
    this.myTasks.forEach((task) => {
      if (task.completed) {
        this.completedTasks++;
      }
    });
  }

  // CRUD functions

  public initializeCollapsableArray() {
    for (let i = 0; i < this.exercisePrograms.length; i++) {
      this.collapsed[i] = true;
    }
  }

  processPatientDetails(details: any, exercises: any) {

    this.initializePersonalInfoForm();
    if (details.disease_history?.previous_illness) {
      if (details.disease_history.previous_treatment === 'Clinic') {
        this.clinic = true;
      } else if (
        details.disease_history.previous_treatment === 'Rehabilitation Center'
      ) {
        this.rehabilitationCenter = true;
      } else if (
        details.disease_history.previous_treatment === 'Previous Operation'
      ) {
        this.previousOperation = true;
      }
    }
    this.events = [];
    this.exercisePrograms = [];
    this.allExercisePrograms = [];
    this.assignedDates = [];

    this.viewDate.setHours(0, 0, 0, 0);

    this.allExercisePrograms = exercises;
    this.changeProgramTab(0);

    for (const program of this.allExercisePrograms) {
      for (const exercise of program.exercises) {
        buildCalendarEvents(
          exercise,
          this.locale,
          this.viewDate,
          this.remainingPreviousExercises,
          this.events,
          this.todayAllExercises,
          this.todayExercises,
          program.deleted
        );
        const r = addAlldates(
          exercise.assigned_dates,
          this.allExercisesDates,
          this.todayExercises,
          this.translate,
          this.activeTreatmentSummary
        );
        for (const k of Object.keys(r)) {
          this[k] = r[k];
        }
      }
    }
    this.processAverageDuration(this.todayExercises);
    this.processAverageDuration(this.remainingPreviousExercises);
    this.calendarEvents = this.events;
    if (this.role === 'patient') {
      this.createCollapsableExercises();
    }
    this.ngZone.run(() => {
      this.dataLoaded = true;
    });
  }

  processAverageDuration(exerciseList) {
    exerciseList.forEach((ex) => {
      if (
        ex.exercise.name !== 'sit2stand_test' &&
        ex.exercise.name !== 'squat'
      ) {
        let tempDuration = 0;
        let breakTime = 0;
        ex.exercise.actions.forEach((action) => {
          if (action.duration) {
            if (action.name === 'break') {
              if (action.display_half) {
                breakTime =
                  action.duration + action.audio_length_milliseconds / 1000;
              } else {
                breakTime =
                  ex.exercise.repetitions *
                  (action.duration + action.audio_length_milliseconds / 1000);
              }
            } else {
              tempDuration += action.audio_length_milliseconds / 1000;
              tempDuration += action.duration;
            }
          } else {
            tempDuration += 4;
          }
          tempDuration += 1;
        });
        const avgDuration =
          Math.round((tempDuration * ex.exercise.repetitions + breakTime) / 5) *
          5;
        ex.avgDuration =
          avgDuration > 59
            ? Math.floor(avgDuration / 60) + 'm ' + (avgDuration % 60) + 's'
            : (avgDuration % 60) + 's';
      }
    });
  }

  public exetractPatientDetails() {

    this.initializePersonalInfoForm();
    if (this.patientDetails.disease_history?.previous_illness) {
      if (this.patientDetails.disease_history.previous_treatment === 'Clinic') {
        this.clinic = true;
      } else if (
        this.patientDetails.disease_history.previous_treatment ===
        'Rehabilitation Center'
      ) {
        this.rehabilitationCenter = true;
      } else if (
        this.patientDetails.disease_history.previous_treatment ===
        'Previous Operation'
      ) {
        this.previousOperation = true;
      }
    }
  }

  public getAllExercisePrograms() {
    this.events = [];
    this.exercisePrograms = [];
    this.allExercisePrograms = [];
    this.assignedDates = [];

    this.viewDate.setHours(0, 0, 0, 0);

    this.storeService.getPrograms(this.patientId, true).subscribe(
      (data) => {
        this.pm.setPrograms(data);
        this.allExercisePrograms = data;
        this.changeProgramTab(0);
      },
      (error) => { },
      () => {
        this.calendarEvents = this.events;
        if (this.role === 'patient') {
          this.createCollapsableExercises();
        }
        this.storeService.refreshData$.next(true);
        (
          this.storeService.getPatientDetailsDashboardDataResolver(
            this.patientId
          ) as Observable<any>
        ).subscribe((_) => {
          this.sub_events_listener = this.pm.all_events$.subscribe((list) => {
            this.singleProgramEvents = [];
            this.singleProgramEvents = list;
          });
        }, err => {
          this.translate
            .get('toastr.getPatientDetailsError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
          this.router.navigate(['dashboard']);
        });
        this.ngZone.run(() => {
          this.dataLoaded = true;
        });

        setTimeout(() => {
          this.programsSection.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 750);
      }
    );
  }

  initializeEditableMode(programs: any[]) {
    programs.forEach(() => {
      this.editMode.push(false);
    });
  }

  addAssignedDatesPerProgram(programs) {
    let programAssignedDates = [];
    programs.forEach((program) => {
      program.exercises[0].assigned_dates.forEach((exerciseDate) => {
        const date = exerciseDate.date.split('.');
        const assignDate = new Date(date[2], date[1] - 1, date[0]);
        if (!programAssignedDates.includes(assignDate)) {
          programAssignedDates.push(assignDate);
        }
      });
      this.assignedDates.push(programAssignedDates);
      programAssignedDates = [];
    });
  }
  findSmallestAndLargestDatePerProgram(index) {
    let date: string;
    let smallestDate: Date;
    let largestDate: Date;

    this.exercisePrograms[index].exercises.forEach((exercise) => {
      if (exercise.assigned_dates.length > 0) {
        const dates = exercise.assigned_dates.map((date) => {
          let tempDate = date.date.split('.');
          return new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
        });
        if (!largestDate) {
          largestDate = new Date(Math.max.apply(null, dates));
        } else {
          let tempLargestDate = new Date(Math.max.apply(null, dates));
          if (tempLargestDate > largestDate) {
            largestDate = tempLargestDate;
          }
        }
        if (!smallestDate) {
          smallestDate = new Date(Math.min.apply(null, dates));
        } else {
          let tempSmallestDate = new Date(Math.min.apply(null, dates));
          if (tempSmallestDate < smallestDate) {
            smallestDate = tempSmallestDate;
          }
        }
      }
    });
    this.programSmallestDate[index] = smallestDate;
    this.programLargestDate[index] = largestDate;
  }

  public getExercises() {
    this.exercisesList = [];
    const requestData = {
      patient_uid: this.patientId,
    };

    this.exerciseService.getExercises(requestData).subscribe(
      (data) => {
        this.exercisesList = data['data'];
        this.patient.exerciseList = this.exercisesList;
        // this.createCalendarEvents();
      },
      (error) => { },
      () => {
        // this.dataLoaded = true;
      }
    );
  }

  removeExercise() {
    const p = this.selectedPeriod[0] ? 0 : (this.selectedPeriod[1] ? 1 : 2)
    if (p !== undefined)
      this.pm.removeExercise((this.chosenExercise as any).exercise_id, parseInt(p.toString()));
    return;
  }


  exercise_options: any = {
    sets_count: 1,
    repetitions_count: 1,
    inhale_duration: 4,
    exhale_duration: 4,
    duration: 60,
  };

  updated = true;
  temp_new_added_exercises = [];

  closeExercisesList() {
    this.selectedExerciseOption = null;
    this.isDropdownOpen = false;
  }

  isExerciseInList(exercise: any, period: number): boolean {
    return this.separetedProgramExercises[period]?.some(
      (ex) => ex.exercise_id === exercise.exercise_id
    );
  }

  selectExerciseOption(option, index, event: MouseEvent) {
    const e = this.pm.getSelectedProgram().getExercisesList().find(e => e.exercise_id == option._id)
    this.active_exercise_periods = [false, false, false]
    if (e) {
      e.assigned_dates.forEach(d => {
        if (d.period != undefined)
          this.active_exercise_periods[d.period] = true
      })
    }
    
    if (this.selectedExerciseOption === option) {
      this.selectedExerciseOption = null;
    } else {
      this.selectedExerciseOption = option;
    }

    const itemElement = document.querySelector(`#exerciseItem-${index}`);
    const listElement = document.querySelector('#exerciseList');

    if (itemElement && listElement) {
      const rect = itemElement.getBoundingClientRect();
      const rect2 = listElement.getBoundingClientRect();

      this.dropdownTop = rect.y - rect2.y + 70;
      this.dropdownLeft = rect.width - 18;
    } else {
      console.error('Element not found');
    }
  }

  togglePeriodDropdown(index: number) {
    this.periodDropdownStates[index] = !this.periodDropdownStates[index];
  }

  isPeriodsDropdownOpen(index: number): boolean {
    return this.periodDropdownStates[index] || false;
  }

  isExerciseInPeriod(exercise: any, period: number): boolean {
    for (const date of exercise.assigned_dates) {
      if (date.period == period) {
        return true;
      }
    }

    return false;
  }

  toggleExerciseForPeriod(selectedExercise: any, period: number) {
    if (!this.isExerciseInPeriod(selectedExercise, period)) {
      this.pm.addExercise({
        ...selectedExercise,
        _id: selectedExercise.exercise_id,
        exercise_id: selectedExercise.exercise_id,
        period: period,
      });
    } else {
      this.pm.removeExercise(selectedExercise.exercise_id, period);
    }
  }

  assignExercise() {
    const newAddedExercise: any = JSON.parse(
      JSON.stringify({
        ...this.chosenExercise,
        exercise_id: this.chosenExercise.exercise_id,
        program_id: this.chosenProgram.program_id,
        patient: this.patientId,
        physiotherapist: this.authService.getAuthenticatedUser().id,
        //_id: v4()
      })
    );
    delete newAddedExercise.id;
    if (newAddedExercise.category == 'breathing') {
      newAddedExercise.actions[0].duration =
        this.exercise_options.inhale_duration;
      newAddedExercise.actions[1].duration =
        this.exercise_options.exhale_duration;
      newAddedExercise.repetitions = this.exercise_options.repetitions_count;
      newAddedExercise.sets = this.exercise_options.sets_count;
    }
    if (newAddedExercise.category == 'breathing_time') {
      newAddedExercise.repetitions = this.exercise_options.repetitions_count;
      newAddedExercise.sets = this.exercise_options.sets_count;
      newAddedExercise.duration = this.exercise_options.duration;
    }
    if (newAddedExercise.category == 'relaxation') {
      newAddedExercise.repetitions = this.exercise_options.repetitions_count;
      newAddedExercise.sets = this.exercise_options.sets_count;
      newAddedExercise.duration = this.exercise_options.duration;
    }
    if (
      newAddedExercise.category == 'stretch' ||
      newAddedExercise.category == 'lower_body'
    ) {
      newAddedExercise.actions[0].duration = this.exercise_options.duration;
      newAddedExercise.repetitions = this.exercise_options.repetitions_count;
      newAddedExercise.sets = this.exercise_options.sets_count;
    }
    newAddedExercise.assigned_dates = this.getBookedDates(
      this.chosenProgram
    ).map((d) => ({ date: d, performed: false }));
    this.temp_new_added_exercises.push(newAddedExercise);

    this.chosenProgram.exercises.push(newAddedExercise);

    const founded = this.allExercisePrograms.find(
      (p) => p.program_id == this.chosenProgram.program_id
    );

    if (founded) {
      founded.exercises = [...this.chosenProgram.exercises];
    }
    this.events = [];
    this.todayAllExercises = [];
    this.todayExercises = [];
    this.remainingPreviousExercises = [];
    for (const program of this.allExercisePrograms) {
      for (const exercise of program.exercises) {
        buildCalendarEvents(
          exercise,
          this.locale,
          this.viewDate,
          this.remainingPreviousExercises,
          this.events,
          this.todayAllExercises,
          this.todayExercises,
          program.deleted
        );
        const r = addAlldates(
          exercise.assigned_dates,
          this.allExercisesDates,
          this.todayExercises,
          this.translate,
          this.activeTreatmentSummary
        );
        for (const k of Object.keys(r)) {
          this[k] = r[k];
        }
      }
      //}
    }
    this.calendarEvents = this.events;
    this.programCalendarDates = [];
    this.exercisePrograms.forEach((program, index) => {
      this.createProgramCalendarEvents(program, index);
      if (program.program_id == this.chosenProgram.program_id) {
        this.ngZone.run(() => {
          this.updated = false;
        });
        const tempEvents = this.calendarEvents;
        this.singleProgramEvents = [];
        this.singleProgramEvents = tempEvents;
        this.programCalendarDates[index].forEach((element) => {
          this.singleProgramEvents.forEach((value) => {
            if (value.start.toDateString() === element.start.toDateString()) {
              if (
                element.title === value.title ||
                element.title[this.locale] === value.title ||
                element.title === value.title[this.locale] ||
                element.title[this.locale] === value.title[this.locale]
              ) {
                if (
                  value.color !== colors.green &&
                  value.color !== colors.red
                ) {
                  value.color = colors.blue;
                }
              }
            }
          });
        });
        this.ngZone.run(() => {
          setTimeout(() => {
            this.updated = true;
          }, 100);
        });
      }
    });
    this.singleProgramEvents = [
      ...this.singleProgramEvents,
      ...this.hidden_events,
    ];
    this.hidden_events = [];
  }

  navigateTo(path) {
    this.createProgramMode = true;
    this.router.navigate([path], { relativeTo: this.route });
  }

  getExerciseDetails(exercise, content, fromDay?) {
    this.exerciseResultsLoaded = false;
    this.exerciseResultsError = false;
    this.breathingPlotError = false;
    if (this.role === 'physiotherapist') {
      this.exerciseFromDay = fromDay;
      this.activeResultTab = 1;
      this.exercise = exercise;
      const day = exercise.start.getDate();
      const month = exercise.start.getMonth() + 1;
      const year = exercise.start.getFullYear();
      const stringDate: string = day + '.' + month + '.' + year;
      this.exerciseDetailsDate = stringDate;

      this.titleDate = stringDate;

      const requestData = {
        patient_uid: this.patientId,
        exercise_id: exercise.id,
        date: stringDate,
      };

      if (exercise.performed) {
        this.exerciseResults = 'exists';

        if (
          [
            'wJTIAquFtSryWBbV3LaJ',
            'B3Pkfgx9LoKRCJQ1ZuiV',
            'y4Gm5oUwtFnbKCoj86is',
            'rJeasDwcokziaE2GOnrv',
          ].includes(exercise.id)
        ) {
          this.exerciseCategory = 'breathingTime';
          this.physicalExercise = false;
        } else if (exercise.id === 'MeXF3gb9PRJg9befzQDl') {
          this.exerciseCategory = 'abdominal';
          this.physicalExercise = true;
        } else if (
          exercise.id === 'DHUvBfErdmlpqrK93eQj' ||
          exercise.id === 'v7el9zWmmflMksUoogEd'
        ) {
          this.exerciseCategory = 'breathing';
          this.physicalExercise = false;
        } else {
          this.exerciseCategory = 'physical';
          this.physicalExercise = true;
        }

        this.exerciseService.getSessionResults(requestData).subscribe(
          (returnData) => {
            this.handleSessionResults(returnData);
          },
          (error) => {
            this.exerciseResultsError = true;
            this.exerciseResultsLoaded = true;
          },
          () => {
            this.exerciseResultsLoaded = true;
          }
        );
        if (
          ['breathing', 'abdominal', 'breathingTime'].includes(
            this.exerciseCategory
          )
        ) {
          this.exerciseService.getBreathingPlot(requestData).subscribe(
            (data) => {
              const obj = JSON.parse(data['data']);
              // obj.timestamp = this.removeBreakGap(obj.timestamp)
              this.breathingPlotData = obj;
              this.breathingPlotError = false;
            },
            (error) => {
              this.breathingPlotData = null;
              this.breathingPlotError = true;
            }
          );
        } else {
          this.breathingPlotData = null;
        }
      } else if (exercise.color === colors.red) {
        this.exerciseResults = 'not-performed';
      } else if (exercise.color === colors.yellow) {
        if (exercise.start > new Date()) {
          this.exerciseResults = 'future-date';
        } else {
          this.exerciseResults = 'today-not-performed';
        }
      }

      this.modalService.open(content, { size: 'lg' });
    }
  }

  handleSessionResults(returnData: any) {
    try {
      // if (exercise.title === 'Bauchatmung' || exercise.title === 'Lippenbremse') {
      this.exerciseResultsError = false;
      this.sets = 1;
      this.setBreak = 0;
      this.repetitions = -1;
      this.count = -1;

      if (returnData['data'].NotEnoughData) {
        this.exerciseResultsError = true;
        return;
      }

      if (returnData['data'].sets) {
        this.sets = returnData['data'].sets;
      }
      if (returnData['data'].set_break) {
        this.setBreak = returnData['data'].set_break;
      }
      if (returnData['data'].repetitions) {
        this.repetitions = returnData['data'].repetitions;
      }
      if (returnData['data'].squat) {
        this.count = returnData['data'].squat.total;
      }

      this.emotion = returnData['data']['emotion'];

      if (
        ['breathing', 'abdominal', 'breathingTime'].includes(
          this.exerciseCategory
        )
      ) {
        const sessionData = returnData['data'];
        if (
          this.exerciseCategory === 'breathing' ||
          this.exerciseCategory === 'abdominal'
        ) {
          this.inhale =
            sessionData.breathing_analysis.percz_analy_inhale.value;
          this.exhale =
            sessionData.breathing_analysis.percz_analy_exhale.value;
          this.overall =
            sessionData.breathing_analysis.percz_analy_combined.value;
        }
        this.posture = null;
        this.shoulder = null;
        if (
          this.exerciseCategory == 'breathing' ||
          this.exerciseCategory == 'breathingTime'
        ) {
          this.posture = sessionData.posture_analysis.percz_analy.value;
          if (this.exerciseCategory == 'breathing') {
            this.shoulder = sessionData.shoulder_analysis?.value ?? 0;
          }
          const angleData =
            sessionData?.posture_analysis?.interval_count?.value ?? [];
          const totalAngleInstances: number = Object.values(
            angleData
          ).reduce((t: number, n: number) => t + n) as number;
          const angleRanges = Object.keys(angleData).sort((a, b) => {
            if (Number(a.split('-')[0]) < Number(b.split('-')[0]))
              return -1;
            if (Number(a.split('-')[0]) > Number(b.split('-')[0]))
              return 1;
            return 0;
          });
          this.breathingBarChartData = {
            type: 'bar',
            data: {
              labels: angleRanges,
              datasets: [
                {
                  label: 'Posture Histogram',
                  data: angleRanges.map((range) =>
                    Math.round(
                      (angleData[range] / totalAngleInstances) * 100
                    )
                  ),
                },
              ],
            },
            options: {
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: '%',
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Angle Range (°)',
                    },
                  },
                ],
              },
            },
          };
          this.translate
            .get('patientDetails.postureHistogram')
            .subscribe((res: string) => {
              this.breathingBarChartData.data.datasets[0].label = res;
            });
          this.translate
            .get('patientDetails.angleRange')
            .subscribe((res: string) => {
              this.breathingBarChartData.options.scales.xAxes[0].scaleLabel.labelString =
                res;
            });
        }

        if ('confidence_mean' in sessionData) {
          this.countUnitMeanValue =
            sessionData.confidence_mean.substring(1).split(',')[0] * 100;
          this.countUnitMeanValue = Math.round(this.countUnitMeanValue);
        }

        if (
          sessionData.inhale_duration &&
          sessionData.exhale_duration &&
          sessionData.sets
        ) {
          this.exerciseDetails.inhale_duration =
            sessionData.inhale_duration;
          this.exerciseDetails.exhale_duration =
            sessionData.exhale_duration;
          this.exerciseDetails.sets = sessionData.sets;
          this.exerciseDetails.display = true;
        } else {
          this.exerciseDetails.display = false;
        }
      }

      if (this.physicalExercise) {
        this.aiTrainerDisabled = false;
        this.countDisplayNames = [];
        this.countTotal = [];
        this.countValues = [];
        this.countUnitDisplayNames = [];
        this.countUnitValues = [];
        this.countUnitMeanValue = 0;
        this.degreeDisplayName = [];
        this.degreeMaxValue = [];
        this.degreeMeanValue = [];
        this.dataSources = [];
        this.percentDisplayName = [];
        this.percentValue = [];
        this.problemCountValue = [];
        this.problemDisplayName = [];
        this.problemTotalValue = [];
        this.problemCountValueNotNull = [];
        this.problemDisplayNameNotNull = [];
        this.problemTotalValueNotNull = [];
        this.pieChartLabels = [];
        this.pieChartData = [];
        this.physicalExercise = true;
        Object.entries(returnData['data']).forEach(([key, value]) => {
          if (key === 'problem') {
            Object.entries(value).forEach(([key2, value2]) => {
              this.problemCountValue.push(value2.value);
              this.problemDisplayName.push(value2.display_name);
              this.problemTotalValue.push(value2.total);
              this.pieChartLabels.push(value2.display_name);
              this.pieChartData.push(value2.value);
              if (value2.value > 0) {
                this.problemCountValueNotNull.push(value2.value);
                this.problemDisplayNameNotNull.push(value2.display_name);
                this.problemTotalValueNotNull.push(value2.total);
              }
            });
          }
        });
        for (let key in returnData['data']) {
          let value = returnData['data'][key];
          if (value.unit === 'count' && value.total) {
            this.countDisplayNames.push(value.display_name);
            this.countTotal.push(value.total);
            this.countValues.push(value.value);
          }
          if (key === 'confidence_mean') {
            this.countUnitMeanValue =
              value.substring(1).split(',')[0] * 100;
            this.countUnitMeanValue = Math.round(this.countUnitMeanValue);
          }
          if (value.unit === 'count' && !value.total) {
            this.countUnitDisplayNames.push(value.display_name);
            this.countUnitValues.push(value.value);
          }
          if (value.unit === 'degree') {
            this.degreeDisplayName.push(value.display_name);
            this.degreeMaxValue.push(value.max);
            this.degreeMeanValue.push(value.avg);
            this.preprocessData();
            this.evaluatingText();
            this.preprocessData2();
          }
          if (value.unit === 'percent') {
            this.percentDisplayName.push(value.display_name);
            this.percentValue.push(Math.round(value.value));
          }
        }
        if (
          returnData['data'] &&
          (returnData['data']['ai_enabled'] !== true && returnData['data']['ai_enabled'] !== 'true')
        ) {
          this.aiTrainerDisabled = true;
          this.aiTrainerDisabledReason = returnData['data']['ai_enabled_reason'] ?? 'aiTrainerWasDisabled';
        }
      }

      this.exerciseResultsError = false;
    } catch (error) {
      this.exerciseResultsError = true;
      this.exerciseResultsLoaded = true;
    }
  }

  groupExercisesByPeriod(exercises) {
    let exercisesForDayGrouped = {
      0: [],
      1: [],
      2: [],
    };

    for (const exercise of exercises) {
      const period = exercise.meta.period.toString();
      if (!exercisesForDayGrouped[period]) {
        exercisesForDayGrouped[period] = [];
      }
      exercisesForDayGrouped[period].push(exercise);
    }

    for (const period in exercisesForDayGrouped) {
      exercisesForDayGrouped[period].sort((a, b) => {
        if (a.performed && !b.performed) return -1;
        if (!a.performed && b.performed) return 1;
        return 0;
      });
    }

    return exercisesForDayGrouped;
  }

  exerciseColor(performed, selectedDateStr, todayDateStr) {
    const selectedDate = new Date(selectedDateStr.split('.').reverse().join('-'));
    const todayDate = new Date(todayDateStr.split('.').reverse().join('-'));

    if (performed) {
      return { 'background-color': '#DEF1D6' };
    } else {
      if (selectedDate < todayDate) {
        return { 'background-color': '#FFD1DB' };
      } else if (selectedDate >= todayDate) {
        return { 'background-color': '#FFF1C8' };
      }
    }
  }

  test(param) {
    //console.log("test", param);
  }

  setMorningPeriod(exercises) {
    if (exercises[0].length) {
      this.setSelectedPeriod[0];
    } else {
      if (exercises[1].length) {
        this.setSelectedPeriod[1];
      } else {
        if (exercises[2].length) {
          this.setSelectedPeriod[2];
        } else {
          this.setSelectedPeriod[-1];
        }
      }
    }
  }

  setSelectedPeriod(period) {
    if (this.selectedPeriod[period]) {
      this.selectedPeriod[period] = false;
      this.periodDropdownStates = {}
    } else {
      this.periodDropdownStates = {};
      this.selectedPeriod = { 0: false, 1: false, 2: false };
      this.selectedPeriod[period] = true;
    }
  }

  getDayDetails({ date, events }, content) {
    if (this.role !== 'physiotherapist') return;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const stringDate: string = day + '.' + month + '.' + year;
    this.titleDate = stringDate;

    if (events && events.length > 0) {
      this.exercisesForDayEmpty = false;
      this.exercisesForDay = this.groupExercisesByPeriod(events);
    } else {
      this.exercisesForDayEmpty = true;
    }
    this.modalService.open(content, { size: 'lg' });
  }


  handleExerciseFromDay(exercise, exerciseContent, dayContent) {
    dayContent.dismiss('exercise click');
    this.getExerciseDetails(exercise, exerciseContent, true);
  }

  handleBackToDay(exercise, exerciseContent, dayContent) {
    exerciseContent.dismiss('back to day');
    this.getDayDetails(exercise.assigned_date, dayContent);
  }

  removeBreakGap(ts: number[]): number[] {
    let gapSize = 2;
    let started = false;
    return ts.reduce((acc: number[], current: number) => {
      if (
        Math.floor(current) - Math.floor(acc[acc.length - 1]) ===
        gapSize + 1
      ) {
        gapSize++;
      }
      if (
        acc.length > 0 &&
        (Math.floor(current) - Math.floor(acc[acc.length - 1]) == gapSize ||
          started)
      ) {
        acc.push(current - (gapSize - 1));
        started = true;
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
  }
  onDayClicked(clickedDate: Date) {

    this.pm.toggleClickedDateEvents(clickedDate);
  }

  validateValues(program) {
    for (const e of program.exercises) {
      e.sets = e.sets ?? 1;
      e.repetitions = e.repetitions ?? 1;
      for (const a of e.actions) {
        if ('duration' in a && a.name != 'hold') {
          a.duration = a.duration ? a.duration : 1;
        }
      }
    }
  }

  updateExerciseProgramWhithPeriods(fromDelete?: boolean) {
    try {
      if (this.sub_events_listener) this.sub_events_listener.unsubscribe();
    } catch (error) { }

    const edited = this.pm.getEditedProgram();

    for (let i in edited) {
      for (let exercise of edited[i]) {
        exercise.assigned_dates = [];
        for (let j = 0; j < this.chosenProgram.dates.length; j++) {
          exercise.assigned_dates.push({
            date: this.chosenProgram.dates[j],
            performed: false,
            period: exercise.period,
          });
        }
        delete exercise.period;
        delete exercise.performed;
        delete exercise.locked;
        delete exercise.exercise_tag;

        exercise.patient = this.patientId;
        exercise.physiotherapist = this.me.id;
      }
    }

    let uniqueExercisesMap = {};

    for (let period in edited) {
      for (let exercise of edited[period]) {
        let id = exercise.exercise_id;

        if (uniqueExercisesMap[id]) {
          uniqueExercisesMap[id].assigned_dates = uniqueExercisesMap[
            id
          ].assigned_dates.concat(exercise.assigned_dates);
        } else {
          uniqueExercisesMap[id] = { ...exercise };
        }
      }
    }

    let uniqueAssignedExercises = Object.values(uniqueExercisesMap);
    const requestData = {
      patient_uid: this.patientId,
      program_id: this.chosenProgram.program_id,
      exercises: uniqueAssignedExercises,
    };

    this.storeService.displaySpinner.next(true);
    this.exerciseService.updateExerciseProgram(requestData).subscribe(
      (data) => { },
      (error) => {
        this.storeService.displaySpinner.next(false);
        if (!fromDelete) {
          this.translate
            .get('toastr.updateExerciseProgramError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        } else {
          this.translate
            .get('toastr.deleteExerciseProgramError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        }
      },
      () => {
        this.editMode.forEach((value, index) => {
          this.editMode[index] = false;
        });
        if (!fromDelete) {
          this.translate
            .get('toastr.updateExerciseProgramSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        } else {
          this.translate
            .get('toastr.deleteExerciseProgramSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        }

        this.ngZone.run(() => {
          this.dataLoaded = false;
        });
        this.storeService.refreshData$.next(true);
        (
          this.storeService.getPatientDetailsDashboardDataResolver(
            this.patientId
          ) as Observable<any>
        ).subscribe(
          (_) => {
            this.sub_events_listener = this.pm.all_events$.subscribe(
              (list) => {
                this.singleProgramEvents = [];

                this.singleProgramEvents = list;
              }
            );
          },
          (err) => {
            this.translate
              .get('toastr.getPatientDetailsError')
              .subscribe((res: string) => {
                this.toastr.error(res);
              });
            this.router.navigate(['dashboard']);
          }
        );
        //this.clearVariables();
      }
    );
  }

  updateExerciseProgram(fromDelete?: boolean) {
    try {
      if (this.sub_events_listener) this.sub_events_listener.unsubscribe();
    } catch (error) { }
    const edited = this.pm.getEditedProgram();

    this.validateValues(edited);

    const edited_prog_exercises = structuredClone(edited.exercises);
    console.log({edited_prog_exercises})
    const requestData = {
      patient_uid: this.patientId,
      program_id: edited.program_id,
      exercises: edited_prog_exercises.map((e: any) => {
        e.patient = this.patientId;
        e.physiotherapist = this.me.id;
        e.assigned_dates = e.assigned_dates
          .filter((ad) => {
            if (fromDelete) {
              //if (ad.date.getTime() >= this.viewDate.getTime()) {
              if (!ad.performed) {
                e.deleted = true;
                return false;
              }
              //}
            }
            return true;
          })
          .map((ad) => {
            console.log(ad)
            return {
              date: getDateAsString(ad.date),
              performed: ad.performed,
              period: ad.period
            }
          })
          .reduce((p, c) => {
            if (!p.find((d) => d.date == c.date && c.period==d.period)) {
              p.push(c);
            }
            return p;
          }, []);
        return e;
      }),
    };
    console.log({requestData})
    requestData.exercises = requestData.exercises.map((e) => {
      if (
        !this.pm.orginal_copy.exercises.find(
          (ex) => ex.exercise_id == e.exercise_id
        )
      ) {
        delete e._id;
      }
      return e;
    });
    console.log({requestData})
    this.storeService.displaySpinner.next(true);
    this.exerciseService.updateExerciseProgram(requestData).subscribe(
      (data) => { },
      (error) => {
        this.storeService.displaySpinner.next(false);
        if (!fromDelete) {
          this.translate
            .get('toastr.updateExerciseProgramError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        } else {
          this.translate
            .get('toastr.deleteExerciseProgramError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        }
      },
      () => {
        this.editMode.forEach((value, index) => {
          this.editMode[index] = false;
        });
        if (!fromDelete) {
          this.translate
            .get('toastr.updateExerciseProgramSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        } else {
          this.translate
            .get('toastr.deleteExerciseProgramSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        }

        this.ngZone.run(() => {
          this.dataLoaded = false;
        });
        this.storeService.refreshData$.next(true);
        (
          this.storeService.getPatientDetailsDashboardDataResolver(
            this.patientId
          ) as Observable<any>
        ).subscribe((_) => {
          this.sub_events_listener = this.pm.all_events$.subscribe((list) => {
            this.singleProgramEvents = [];

            this.singleProgramEvents = list;
          });
        }, err => {
          this.translate
            .get('toastr.getPatientDetailsError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
          this.router.navigate(['dashboard']);
        });
        //this.clearVariables();
      }
    );
  }

  checkIfExerciseAlreadyAssignedInOtherProgs(
    exercise: any,
    d1: string
  ): boolean {
    for (const program of this.allExercisePrograms) {
      if (program.active) {
        for (const e of program.exercises) {
          if (!e.deleted && e.exercise_id == exercise.exercise_id) {
            const index_of_the_exercise = e.assigned_dates.find((as_date) => {
              const d2 = as_date.date;
              return d2 == d1;
            });
            if (index_of_the_exercise) return true;
          }
        }
      }
    }
    return false;
  }

  deleteExerciseProgram() {
    let deleteProgram = true;
    this.chosenProgram.exercises = this.chosenProgram.exercises.map((e) => ({
      ...e,
      deleted: true,
    }));

    const dates = this.chosenProgram.exercises[0].assigned_dates.map((d) => {
      return { ...d, date: new Date(d.date.split('.').reverse().join('.')) };
    });
    const sortedDates = dates.sort((a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });

    if (sortedDates?.length > 0) {
      if (sortedDates[0].date < this.viewDate) {
        deleteProgram = false;
      } else if (
        sortedDates[0].date.toDateString() === this.viewDate.toDateString()
      ) {
        this.chosenProgram.exercises.forEach((exercise) => {
          const date = exercise.assigned_dates.find(
            (d) =>
              new Date(d.date.split('.').reverse().join('.')).toDateString() ===
              this.viewDate.toDateString()
          );
          if (date && date.performed) {
            deleteProgram = false;
          }
        });
      }
    }

    if (deleteProgram) {
      const onError = (error) => {
        this.storeService.displaySpinner.next(false);
        this.translate
          .get('toastr.deleteExerciseProgramError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      };
      const onCompleted = () => {
        this.storeService.displaySpinner.next(false);
        this.translate
          .get('toastr.deleteExerciseProgramSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.ngZone.run(() => {
          this.dataLoaded = false;
        });
        this.clearVariables();
        this.getAllExercisePrograms();
      };
      this.storeService.refreshData$.next(true);
      this.storeService.displaySpinner.next(true);
      this.exerciseService
        .deleteExerciseProgram({
          patient_uid: this.patientId,
          program_id: this.chosenProgram.program_id,
          current_date: DateTime.now().toFormat('dd.MM.y')
        })
        .subscribe((data) => { }, onError, onCompleted);
    } else {
      this.calendarComponent.selectedDates = [];
      this.updateExerciseProgram(true);
    }
    this.todayExercises = [];
    this.activeTreatmentSummary.days_left = 0;
  }

  // Helper functions

  goBack(exercise?) {
    this.router.navigate(['dashboard']);

    //this.router.ba
    // if (exercise) {
    //   if (
    //     exercise.date === this.smallestDate &&
    //     this.todayExercises.length === this.todayAllExercises.length
    //   ) {
    //     exercise.first = true;
    //   } else {
    //     exercise.first = false;
    //   }
    //   this.goBackEvent.emit(exercise);
    // } else {
    //   this.goBackEvent.emit();
    // }
    // this.router.navigate(['dashboard', this.me.role]);
  }

  openRemoveExerciseModal(content, exerciseProgram, exercise) {
    this.chosenProgram = exerciseProgram;
    this.chosenExercise = exercise;

    this.modalService.open(content);
  }

  openEditExerciseModal(content, exercise) {
    this.chosenExercise = exercise;
    if (this.chosenExercise.category === 'diaphragm') {
      this.inhaleDuration =
        this.patient.exerciseParameters.diaphragmaticBreathing.inhaleDuration;
      this.exhaleDuration =
        this.patient.exerciseParameters.diaphragmaticBreathing.exhaleDuration;
    } else if (this.chosenExercise.category === 'lip') {
      this.inhaleDuration =
        this.patient.exerciseParameters.lipBreathing.inhaleDuration;
      this.exhaleDuration =
        this.patient.exerciseParameters.lipBreathing.exhaleDuration;
    }
    this.modalService.open(content);
  }
  getBookedDates(editedProgram) {
    const booked_days = [];

    for (const ex of editedProgram.exercises) {
      for (const ad of ex.assigned_dates) {
        if (booked_days.indexOf(ad.date) < 0) {
          const adAsDate = new Date(ad.date.split('.').reverse().join('.'));
          if (adAsDate >= this.viewDate) booked_days.push(ad.date);
        }
      }
    }
    //----
    return booked_days;
  }

  clearChosenExercise() {
    this.chosenExercise = {} as Exercise;
    this.availableExercises = [];
    this.assignedExercisesExist = false;
    this.inhaleDuration = null;
    this.exhaleDuration = null;
  }

  createProgramCalendarEvents(exerciseProgram: any, index: number = -1) {
    const results = [];
    exerciseProgram.exercises.forEach((exercise) => {
      exercise.assigned_dates.forEach((assigned) => {
        try {
          const stringDay = assigned.date.split('.')[0];
          const stringMonth = assigned.date.split('.')[1];
          const stringYear = assigned.date.split('.')[2];

          const date = new Date(
            stringYear + '/' + stringMonth + '/' + stringDay
          );
          let currentTime = new Date();
          date.setHours(0, 0, 0, 0);
          currentTime.setHours(0, 0, 0, 0);
          let color = colors.yellow;
          let performed: boolean;

          if (date <= currentTime) {
            if (assigned.performed) {
              color = colors.green;
              performed = true;
            } else {
              if (date < currentTime) {
                color = colors.red;
                performed = false;
              }
            }
          }

          const exerciseEvent = {
            start: date,
            title: exercise.display_name
              ? exercise.display_name
              : exercise.name,
            color: color,
            allDay: true,
            id: exercise.exercise_id,
            meta: {
              exercise_id: exercise.exercise_id,
              program_id: exercise.program_id,
              isProgramDeleted: exerciseProgram.deleted,
              isExerciseDeleted: exercise.deleted,
            },

            performed: performed,
          };
          results.push(exerciseEvent);
        } catch (err) { }
      });
    });
    if (index > -1) {
      this.programCalendarDates[index] = results;
    } else this.programCalendarDates.push(results);
  }

  handleEvent(content, action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modalService.open(content, { size: 'lg' });
    this.chosenEvent = this.patient.exercisePerformance.find(
      (ex) => ex.date.toDate().toDateString() === event.start.toDateString()
    );
    if (
      event.title === 'Lippenbremse' ||
      event.title === 'Büzük Dudak Nefesi Egzersizi'
    ) {
      this.eventType = 'lip';
    }
    if (event.title === 'Bauchatmung' || event.title === 'Diyafram Egzersizi') {
      this.eventType = 'diaphragm';
    }
    this.titleDate = this.chosenEvent.date.toDate().toDateString().slice(0, 10);

    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  customEvaluationClass(condition) {
    if (condition < 60) {
      return 'badge badge-success';
    } else if (60 <= condition && condition < 80) {
      return 'badge badge-warning';
    } else if (condition >= 80) {
      return 'badge badge-danger';
    }
  }

  // input should be month + 1 (real number of month)
  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  openModal(content, program?, component?) {
    if (program) {
      this.chosenProgram = program;
    }
    if (component) {
      this.calendarComponent = component;
      // this.newDates = this.tempExercises;
    }
    return this.modalService.open(content, { size: 'lg' });
  }

  closeCreateProgramMode() {
    this.createProgramMode = false;
    this.ngZone.run(() => {
      this.dataLoaded = false;
    });
    this.exetractPatientDetails();
  }

  startExerciseClicked(content, exercise) {
    this.exerciseToStart = exercise;
    this.chosenExercise = exercise.exercise;
    this.openModal(content);
    // this.exerciseMode = true;
  }

  checkTodayExercises(parentCall?) {
    let result = true;
    if (this.todayExercises.length > 0) {
      this.todayExercises.forEach((exercise) => {
        if (!exercise.performed) {
          result = false;
        }
      });
    }

    //Emit the results from Today Exercises
    this.remainingExercise.emit(result);
    if (parentCall) {
      return result;
    }

    if (this.remainingPreviousExercises.length > 0) {
      result = false;
    }
    return result;
  }

  deleteExercise(programIndex, exerciseIndex) {
    this.deletedExercisesList.push(
      this.exercisePrograms[programIndex].exercises[exerciseIndex]
    );
    this.exercisePrograms[programIndex].exercises.splice(exerciseIndex, 1);
  }

  activeEditMode(index, content?) {
    if (this.editMode[index]) {
      this.openModal(content);
    } else {
      this.editMode[index] = true;
    }
    this.pm.highLightProgramEvents();
  }


  cancelEditProgram() {
    this.editMode.forEach((value, index) => {
      this.editMode[index] = false;
    });
    this.pm.onCancelEditing();
    this.pm.highLightProgramEvents();
  }
  deleteProgramModal(content, program) {
    this.chosenProgram = program;
    this.modalService.open(content, { size: 'lg' });
  }

  getProgressBarColor(value: number) {
    if (value < 30) {
      return '#833234';
    } else if (value < 70) {
      return '#E19F20';
    } else {
      return '#008A5D';
    }
  }

  clearVariables() {
    this.chosenProgram = null;
    this.programCalendarDates = [];
    this.newDates = [];
    this.tempExercises = [];
    this.deletedExercisesList = [];
    this.editMode.forEach((value, index) => {
      this.editMode[index] = false;
    });
  }

  changeProgramTab(num: number) {
    this.activeProgramTab = num;
    if (this.activeProgramTab === 0) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog) => prog.active === true
      );
    } else if (this.activeProgramTab === 1) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog) => !prog.active && !prog.deleted
      );
    } else if (this.activeProgramTab === 2) {
      this.exercisePrograms = this.allExercisePrograms.filter(
        (prog) => prog.deleted
      );
    }

    this.initializeCollapsableArray();
    this.initializeEditableMode(this.exercisePrograms);
    this.addAssignedDatesPerProgram(this.exercisePrograms);
    this.exercisePrograms.forEach((program, index) => {
      this.findSmallestAndLargestDatePerProgram(index);
      this.createProgramCalendarEvents(program);
    });
    // setTimeout(() => {
    //   this.programsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }, 10);
    this.exerciseProgramsCopy = structuredClone(this.exercisePrograms);
  }

  editModeOn(activeTab) {
    this.patientDetails = JSON.parse(
      JSON.stringify(this.orginalPatientDetails)
    );
    this.exetractPatientDetails();
    this.processPatientDetails(this.patientDetails, this.allExercisePrograms);
    //this.transformDetails(this.patientDetails);

    // if (typeof this.patientDetails.personal_information.age == 'string') {
    //   this.age = this.changeDateFormat(
    //     this.patientDetails.personal_information.age
    //   );
    //   this.personalInformationGroup.get('age').setValue(this.age);
    // }
    setTimeout(() => {
      this.edit = true;
    }, 200);
  }

  initializePersonalInfoForm() {

  }
  openSaveModal(content, data) {
    this.modifiedPatientInfo = { ...this.modifiedPatientInfo, ...data };
    if (this.modifiedPatientInfo['personal_information.email']) {
      const userEmail = this.modifiedPatientInfo['personal_information.email'];
      this.userService.isEmailActive({ email: userEmail }).subscribe(data => {
        if (data['data']) {
          this.translate.get('toastr.userExists').subscribe((res: string) => {
            this.toastr.error(res);
          });
        } else {
          this.modalService.open(content, { size: 'lg' });
        }
      })
    } else {
      this.modalService.open(content, { size: 'lg' });
    }
  }
  modifiedPatientInfo: any;
  updatePatientDetails() {
    const data = this.modifiedPatientInfo;
    const personalInfo: any = {}
    Object.keys(data).forEach(k => {
      if (k.startsWith('personal_information')) {
        const prop = k.split('.')[1]
        personalInfo[prop] = data[k]
      }
    })
    delete data['personal_information.email'];
    delete data['personal_information.first_name']
    delete data['personal_information.last_name']
    delete data['personal_information.gender']
    delete data['personal_information.contact_reason']
    delete data['personal_information.reason_explanation']
    delete data['personal_information.dob']
    delete data['personal_information.phone']
    const patient: any = {
      personal_information: personalInfo,
      other_info: { ...data },
      about_copd: {}
    };
    const requestData = {
      ...patient,
      patient_uid: this.patientId,
    };

    this.savingPatientDetails = true;
    this.userService
      .editPersonalInfo(requestData)
      .subscribe(
        (dd) => {


        },
        (err) => {
          this.translate
            .get('toastr.patientDetailsError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
          this.savingPatientDetails = false;

        },
        () => {

          Object.assign(
            this.patientDetails.personal_information,
            requestData.personal_information
          );
          Object.assign(
            this.patientDetails.other_info,
            requestData.other_info
          );
          this.edit = false;
          this.savingPatientDetails = false;
          this.detailsEdited = false;

          transformOnboardingFormData(JSON.parse(JSON.stringify(this.orginalCards)), this.locale, this.translate, this.patientDetails)
            .then(cards => {
              this.allCards = cards
              this.getPatientVersions();
            })
        }
      );
  }

  updateEditPersonalFormControls() {

  }

  cancelEditPersonalInfo() {
    this.edit = false;
    this.detailsEdited = false;
    this.activeTab = this.tempActiveTab;

  }

  changeDateFormatToBackend(date) {
    // from 1990-12-12 to 12.12.1990
    var splittedDate = date.split('-');
    var newDate =
      parseInt(splittedDate[2]) +
      '.' +
      parseInt(splittedDate[1]) +
      '.' +
      parseInt(splittedDate[0]);
    return newDate;
  }

  changeDateFormat(date) {
    //2020-01-11 year-month-day
    // from 12.12.1990 to 1990-12-12
    var splittedDate = date.split('.');
    var monthInt = parseInt(splittedDate[1]);
    var DayInt = parseInt(splittedDate[0]);
    var monthString;
    var DayString;
    if (monthInt < 10) {
      monthString = '0' + monthInt;
    } else {
      monthString = '' + monthInt;
    }
    if (DayInt < 10) {
      DayString = '0' + DayInt;
    } else {
      DayString = '' + DayInt;
    }
    var newDate = splittedDate[2] + '-' + monthString + '-' + DayString;
    return newDate;
  }

  changeActiveTab(content, activeTab) {
    //console.log({ activeTab });
    this.tempActiveTab = activeTab;
    if (this.edit) {
      this.modalService.open(content, { size: 'lg' });
    } else {
      this.activeTab = activeTab;
    }
  }

  convertToPercentage(number, total) {
    var percentage = (number * 100) / total;
    return this.getDotColor(percentage);
  }

  getDotColor(percentage) {
    if (percentage >= 0 && percentage < 40) {
      return '#823335';
    }
    if (percentage >= 40 && percentage < 70) {
      return '#f58602';
    } else {
      return '#2FC19A';
    }
  }

  preprocessData2() {
    this.dataSources = [];
    for (let i = 0; i < this.degreeDisplayName.length; i++) {
      var color = [];
      var dial = [];
      if (!this.checkIfItIsRight(this.degreeDisplayName[i])) {
        color = [
          {
            minValue: '-90',
            maxValue: -this.degreeMaxValue[i],
            code: '#384f6b',
          },
          {
            minValue: -this.degreeMaxValue[i],
            maxValue: '0',
            code: '#2FC19A',
          },
          {
            minValue: '0',
            maxValue: '90',
            code: '#384f6b',
          },
        ];
        dial = [
          {
            value: 0 - this.degreeMeanValue[i],
            showValue: '0',
            valueX: '260',
            valueY: '220',
            tooltext: this.averageDegree + ' :' + this.degreeMeanValue[i],
            rearExtension: '15',
          },
        ];
      } else {
        color = [
          {
            minValue: '-90',
            maxValue: '0',
            code: '#384f6b',
          },
          {
            minValue: '0',
            maxValue: this.degreeMaxValue[i],
            code: '#2FC19A',
          },
          {
            minValue: this.degreeMaxValue[i],
            maxValue: '90',
            code: '#384f6b',
          },
        ];
        dial = [
          {
            value: this.degreeMeanValue[i],
            showValue: '0',
            valueX: '260',
            valueY: '220',
            tooltext: this.averageDegree + ' : $value',
            rearExtension: '15',
          },
        ];
      }

      var dataSource = {
        chart: {
          lowerLimit: '-90',
          upperLimit: '90',
          numberSuffix: '\xB0',
          theme: 'fusion',
          majorTMColor: '#384f6b',
        },
        colorRange: {
          color: color,
        },
        annotations: {
          origw: '450',
          origh: '300',
          autoscale: '1',
          showBelow: '0',
          groups: [
            {
              id: 'arcs',
              items: [
                {
                  id: 'national-cs-text',
                  type: 'Text',
                  color: '#000000',
                  label:
                    this.averageDegree +
                    ': ' +
                    Math.round(this.degreeMeanValue[i] * 10) / 10 +
                    '\xB0',
                  fontSize: '16',
                  align: 'left',
                  x: '$chartCenterX + 7',
                  y: '$chartEndY - 35',
                },
                {
                  id: 'state-cs-text',
                  type: 'Text',
                  color: '#2FC19A',
                  label:
                    this.maximumDegree +
                    ': ' +
                    Math.round(this.degreeMaxValue[i] * 10) / 10 +
                    '\xB0',
                  fontSize: '16',
                  align: 'right',
                  x: '$chartCenterX - 7',
                  y: '$chartEndY - 35',
                },
              ],
            },
          ],
        },
        dials: {
          dial: dial,
        },
      };
      this.dataSources.push(dataSource);
    }
  }

  preprocessData() {
    this.single = [];
    this.DegreesArrays = [];
    for (let i = 0; i < this.degreeDisplayName.length; i++) {
      var avgDegree = {
        name:
          this.removeFirstWord(this.degreeDisplayName[i]) + ' Average degree',
        value: this.degreeMeanValue[i],
      };
      var maxDegree = {
        name: this.removeFirstWord(this.degreeDisplayName[i]) + ' Max degree',
        value: this.degreeMaxValue[i],
      };

      this.single.push(avgDegree);
      this.single.push(maxDegree);
      this.DegreesArrays.push(this.single);
      this.single = [];
    }
  }

  evaluatingText() {
    this.labelsArray = [];
    for (var i = 0; i < this.degreeMaxValue.length; i++) {
      var averageLabel = 'Average degree: ' + this.degreeMeanValue[i];
      var maxLabel = 'Max degree: ' + this.degreeMaxValue[i];
      var label = averageLabel + ' / ' + maxLabel;
      this.labelsArray.push(label);
    }
  }

  removeFirstWord(str) {
    const indexOfSpace = str.indexOf(' ');

    if (indexOfSpace === -1) {
      return '';
    }

    return str.substring(indexOfSpace + 1);
  }

  valueFormating(value) {
    return value;
  }

  checkIfItIsRight(text) {
    if (text.includes('right')) {
      return true;
    } else {
      return false;
    }
  }

  getExerciseGif(event, exercise) {
    // if (exercise.category === 'breathing' || exercise.category === 'Breathing' || exercise.exercise?.category === 'breathing' || exercise.exercise?.category === 'Breathing') {
    //   event.target.src = '../../../assets/gifs/breath.gif';
    // } else if (exercise.category === 'lower_body' || exercise.category === 'Lower body' || exercise.exercise?.category === 'lower_body' || exercise.exercise?.category === 'Lower body') {
    //   event.target.src = '../../../assets/gifs/squat.gif';
    // } else {
    event.target.src = '../../../assets/images/Breathment Logo_rev_white.png';
    // }
  }

  createCollapsableExercises() {
    for (let i = 0; i < this.todayExercises.length; i++) {
      this.todayExercisesCollapsed[i] = true;
    }
    for (let i = 0; i < this.remainingPreviousExercises.length; i++) {
      this.previousExercisesCollapsed[i] = true;
    }
  }

  getProgramLength(program) {
    let count: number = 0;
    if (this.activeProgramTab === 2) {
      return program.exercises.length;
    }
    program.exercises.forEach((exercise) => {
      if (!exercise.deleted && exercise.assigned_dates?.length > 0) {
        count++;
      }
    });
    return count;
  }
  addTask() {
    this.addNewTask = true;
  }
  cancelNewTask() {
    this.addNewTask = false;
    this.addNewTaskAction = false;
    this.newTaskFormGroup.reset();
  }
  checkIfDateIsEntered(control: UntypedFormControl): { [s: string]: boolean } {
    if (control.parent) {
      let timeControl = control.parent.get('time');
      let dateControl = control.parent.get('date');

      if (timeControl.value != null && dateControl.value === null) {
        dateControl.setErrors({ timeAndDateRequired: true });
      } else {
        dateControl.setErrors(null);
        dateControl.updateValueAndValidity();
      }
    }
    return null;
  }

  intializeNewTasksForm() {
    this.newTaskFormGroup = new UntypedFormGroup({
      title: new UntypedFormControl(this.newTaskModel.title, [
        Validators.required,
      ]),
      details: new UntypedFormControl(this.newTaskModel.details, []),
      date: new UntypedFormControl(this.newTaskModel.date, []),
      time: new UntypedFormControl(this.newTaskModel.time, [
        this.checkIfDateIsEntered.bind(this),
      ]),
      reminder: new UntypedFormControl(this.newTaskModel.reminder, []),
    });
  }

  addNewTaskIntoTaks() {
    this.addNewTaskAction = true;
    if (this.newTaskFormGroup.valid) {
      this.newTaskModel.title = this.newTaskFormGroup.get('title').value;
      this.newTaskModel.details = this.newTaskFormGroup.get('details').value;
      this.newTaskModel.date = this.newTaskFormGroup.get('date').value;
      this.newTaskModel.time = this.newTaskFormGroup.get('time').value;
      this.newTaskModel.reminder = this.newTaskFormGroup.get('reminder').value;

      var current_date = new Date();
      var current_date_stringify = this.datepipe.transform(
        current_date,
        'd.M.yyyy h:mm'
      );

      var transformedDate = this.newTaskModel.date
        ? this.datepipe
          .transform(this.newTaskModel.date, 'dd.MM.yyyy')
          .replace(/\b0/g, '')
        : this.newTaskModel.date;
      var transformedTime = this.newTaskModel.time
        ? this.unformatTime(this.newTaskModel.time)
        : this.newTaskModel.time;
      var reminder = this.newTaskModel.reminder
        ? this.newTaskModel.reminder
        : false;
      const requestData = {
        patient_uid: this.patientId,
        title: this.newTaskModel.title,
        details: this.newTaskModel.details ? this.newTaskModel.details : '',
        date: transformedDate ? transformedDate : '',
        time: transformedTime ? transformedTime : '',
        reminder: reminder,
        completed: false,
        creation_date: current_date_stringify,
      };

      this.userService.addNewTask(requestData).subscribe(
        (res) => {
          this.translate.get('toastr.taskAdded').subscribe((res: string) => {
            this.toastr.success(res);
          });
          this.getAllTasks();
          this.addNewTask = false;
          this.newTaskFormGroup.reset();
        },
        (error) => {
          this.translate.get('toastr.taskError').subscribe((res: string) => {
            this.toastr.error(res);
          });
          this.addNewTask = false;
          this.newTaskFormGroup.reset();
        }
      );
    }
  }
  changeTaskCompletness(task) {
    task.completed = !task.completed;
    const requestData = {
      task_id: task._id,
      title: task.title,
      details: task.details ? task.details : '',
      date: task.date ? task.date : '',
      time: task.time ? task.time : '',
      reminder: task.reminder,
      completed: task.completed,
    };
    this.userService.updateTask(requestData).subscribe(
      (res) => {
        this.translate
          .get('toastr.taskUpdatedSucc')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.getAllTasks();
      },
      (error) => {
        this.translate.get('toastr.taskUpdatedErr').subscribe((res: string) => {
          this.toastr.error(res);
        });
        this.getAllTasks();
      }
    );
  }

  deleteCertainTask(task, content) {
    this.deletedTask = task;
    this.modalService.open(content, { size: 'lg' });
  }

  deleteTask() {
    const requestData = {
      task_id: this.deletedTask._id,
    };
    this.userService.deleteTask(requestData).subscribe(
      (res) => {
        this.translate
          .get('toastr.taskDeletedSucc')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.getAllTasks();
      },
      (error) => {
        this.translate.get('toastr.taskDeletedErr').subscribe((res: string) => {
          this.toastr.error(res);
        });
        this.getAllTasks();
      }
    );
  }
  addNewExercise_m(period, selectedExercise) {
    // this.selectedExerciseOption.periods = this.selectedExerciseOption.periods || { 0: false, 1: false, 2: false }
    // this.selectedExerciseOption.periods[period] = !this.selectedExerciseOption.periods[period];

    //console.log({ ...selectedExercise, period })
    const e = this.pm.getSelectedProgram().getExerciseById(selectedExercise._id)
    let is_period_selected = false
    if (e) {
      for (const d of e.assigned_dates) {
        if (d.period == period) {
          is_period_selected = true
          break;
        }
      }
    }

    if (!is_period_selected) {
      this.pm.addExercise({
        ...selectedExercise,
        _id: selectedExercise._id,
        exercise_id: selectedExercise._id,
        period
      });
    } else {
      this.pm.removeExercise(selectedExercise._id, period);
    }
    this.active_exercise_periods = [false, false, false]

    const modified_e = this.pm.getSelectedProgram().getExerciseById(selectedExercise._id)
    if (modified_e) {
      modified_e.assigned_dates.forEach(d => {
        if (d.period != undefined)
          this.active_exercise_periods[d.period] = true
      })
    }
    return;

  }
  isExercisePeriodSelected(period: number) {
    return this.selectedExerciseOption.periods?.[period] == true
  }
  editTask(task) {
    task.edit = true;
    this.copyOfEditTask = new newTaskModel();
    this.copyOfEditTask.title = task.title;
    this.copyOfEditTask.details = task.details;
    this.copyOfEditTask.date = task.date;
    this.copyOfEditTask.time = task.time;
    this.copyOfEditTask.reminder = task.reminder;
    this.copyOfEditTask.completed = task.completed;

    if (task.date) {
      task.date = this.formateDate(task.date);
    }
    if (task.time) {
      task.time = this.formateTime(task.time);
    }
    this.updatedTask = task;
  }

  formateDate(date) {
    //1.1.2022
    //to "2019-07-22"
    var splittedDate = date.split('.');
    var formatedDate = new Date();
    formatedDate.setFullYear(splittedDate[2]);
    formatedDate.setMonth(parseInt(splittedDate[1]) - 1);
    formatedDate.setDate(splittedDate[0]);
    let newFormatedDate = this.datepipe.transform(formatedDate, 'yyyy-MM-dd');
    return newFormatedDate;
  }

  formateTime(time) {
    //4:30
    //04:30
    var splittedTime = time.split(':');
    var hours = splittedTime[0];
    var mins = splittedTime[1];
    if (parseInt(splittedTime[0]) < 10) {
      hours = '0' + hours;
    }

    var formattedTime = hours + ':' + mins;
    return formattedTime;
  }

  unformatTime(time) {
    var splittedTime = time.split(':');
    var hours = splittedTime[0];
    var mins = splittedTime[1];
    if (parseInt(splittedTime[0]) < 10) {
      hours = parseInt(splittedTime[0]);
    }

    var formattedTime = hours + ':' + mins;
    return formattedTime;
  }

  editCurrentTask() {
    var transformedDate = this.updatedTask.date
      ? this.datepipe
        .transform(this.updatedTask.date, 'dd.MM.yyyy')
        .replace(/\b0/g, '')
      : this.updatedTask.date;
    var transformedTime =
      this.updatedTask.time && this.updatedTask.date
        ? this.unformatTime(this.updatedTask.time)
        : null;

    const requestData = {
      task_id: this.updatedTask._id,
      title: this.updatedTask.title,
      details: this.updatedTask.details ? this.updatedTask.details : '',
      time: transformedTime ? transformedTime : '',
      date: transformedDate ? transformedDate : '',
      reminder: this.updatedTask.reminder,
      completed: this.updatedTask.completed,
    };

    this.userService.updateTask(requestData).subscribe(
      (res) => {
        this.translate
          .get('toastr.taskUpdatedSucc')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
        this.getAllTasks();
      },
      (error) => {
        this.translate.get('toastr.taskUpdatedErr').subscribe((res: string) => {
          this.toastr.error(res);
        });
        this.getAllTasks();
      }
    );
  }
  cancelEdit() {
    this.updatedTask.title = this.copyOfEditTask.title;
    this.updatedTask.details = this.copyOfEditTask.details;
    this.updatedTask.date = this.copyOfEditTask.date;
    this.updatedTask.time = this.copyOfEditTask.time;
    this.updatedTask.reminder = this.copyOfEditTask.reminder;
    this.updatedTask.completed = this.copyOfEditTask.completed;
    this.updatedTask.edit = false;
  }
  openEditModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  get availablesExercisesList() {
    const availablesExercisesList = this.exercisesList
    // .filter((ex) => {
    //   const i = this.selectedProgramExercises.findIndex(
    //     (e) => e.exercise_id == ex._id && !e.deleted
    //   );
    //   return i < 0;
    // });

    return availablesExercisesList;
  }
  get selectedProgramExercises(): IProgramExercise[] {
    return this.pm.getCurrentProgramExercises();
  }
  get selectedProgramExercises0(): IProgramExercise[] {
    return this.filterProgramExercises(0)
  }
  get selectedProgramExercises1(): IProgramExercise[] {
    return this.filterProgramExercises(1)
  }
  get selectedProgramExercises2(): IProgramExercise[] {
    return this.filterProgramExercises(2)
  }
  filterProgramExercises(period: number) {
    return this.selectedProgramExercises.filter(e => {
      const exists = e.assigned_dates.findIndex(d => d.period == period)

      return exists > -1
    })
  }
  onExercisePropertyChange(exercise) {
    for (let period in this.separetedProgramExercises) {
      this.separetedProgramExercises[period].forEach((ex) => {
        if (exercise.exercise_id == ex.exercise_id) {

          ex.sets = exercise.sets;
          ex.repetitions = exercise.repetitions;
          ex.actions = JSON.parse(JSON.stringify(exercise.actions));
          ex.set_break = JSON.parse(JSON.stringify(exercise.set_break));
        }
      });
    }
  }

  expandProgram(num: number, program: any) {
    this.chosenProgram = program;

    this.collapsed.forEach((card, i) => {
      if (!card) {
        this.shrinkProgram(i);
        this.collapsed[i] = true;
      }
    });
    const today = new Date().setHours(0, 0, 0, 0);
    const tempEvents = this.calendarEvents;
    this.singleProgramEvents = [];
    this.singleProgramEvents = tempEvents;
    const diffExercisesInProgram: any = {};
    this.programCalendarDates[num].forEach((element) => {
      this.singleProgramEvents.forEach((value) => {
        if (value.start.toDateString() === element.start.toDateString()) {
          try {
            if (
              this.chosenProgram.program_id == element.meta.program_id &&
              element.meta.program_id == value.meta.program_id &&
              element.id == value.id
            ) {
              const eDate = value.start.setHours(0, 0, 0, 0);
              if (eDate >= today) {
                value.color = colors.blue;
              }
            }
          } catch (error) {
            //console.log(error);
          }
        }
      });
    });
    //const today = new Date().setHours(0, 0, 0, 0)
    //const tempEvents = this.calendarEvents;

    this.pm.setSelectedProgram(program.program_id);
    this.pm.highLightProgramEvents();

    // this.singleProgramEvents = [];
    // this.singleProgramEvents = this.pm.getAllEvents();
    //const diffExercisesInProgram: any = {}
    // this.programCalendarDates[num].forEach(element => {

    //   this.singleProgramEvents.forEach(value => {

    //     if (value.start.toDateString() === element.start.toDateString()) {
    //       if (this.chosenProgram.program_id == element.meta.program_id && element.meta.program_id == value.meta.program_id && element.id == value.id) {
    //         const eDate = value.start.setHours(0, 0, 0, 0)
    //         if (eDate >= today) {
    //           value.color = colors.blue;
    //         }
    //       }
    //     }
    //   });
    // });
    this.collapsed[num] = !this.collapsed[num];
  }

  shrinkProgram(num: number) {
    this.ngZone.run(() => {
      this.dataLoaded = false;
    });
    this.collapsed[num] = !this.collapsed[num];
    const tempEvents = this.calendarEvents;
    this.singleProgramEvents = [];
    this.singleProgramEvents = tempEvents;
    this.programCalendarDates[num]?.forEach((element) => {
      this.singleProgramEvents.forEach((value) => {
        if (value.start.toDateString() === element.start.toDateString()) {
          if (
            element.title === value.title ||
            element.title[this.locale] === value.title ||
            element.title === value.title[this.locale] ||
            element.title[this.locale] === value.title[this.locale]
          ) {
            if (value.color === colors.blue) {
              value.color = colors.yellow;
            }
          }
        }
      });
    });
    this.ngZone.run(() => {
      this.dataLoaded = true;
    });
  }

  activityWeekChanged() { }

  scrollToPrograms() {
    this.programsSection.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  goToSurvey() {
    this.perfOverviewComponent.openSurveyModal();
  }

  goToActivity() {
    this.perfOverviewComponent.openActivityModal();
  }

  goToPerfOverview() {
    this.perfOverviewComponent.openOverviewModal();
  }

  openCreateAppointmentModal(content) {
    this.appointmentDateSelected = false;
    this.selectedAppointmentSlot = null;
    this.mainCalendar.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      this.modalService.open(content, {
        size: 'lg',
        windowClass: 'bubble-modal',
        centered: true,
      });
    }, 600);
  }

  openCreateAppointmentModal2(content) {
    this.appointmentDateSelected = false;
    this.selectedAppointmentSlot = null;
    this.modalService.open(content, {
      size: 'lg',
      windowClass: 'bubble-modal',
      centered: true,
    });
  }

  appointmentSelect(selection) {
    this.appointmentDateSelected = true;
    this.selectedAppointmentSlot = selection;
  }

  getISOString(dateInput, timeInput) {
    const dateVals = dateInput.split('-').map((str) => Number(str));
    const timeVals = timeInput.split(':').map((str) => Number(str));

    const date = new Date();
    date.setFullYear(dateVals[0], dateVals[1] - 1, dateVals[2]);
    date.setHours(timeVals[0], timeVals[1]);

    return date.toISOString();
  }

  createAppointment() {
    const dto: any = {
      patient_uid: this.patientId,
      appointment_dt: this.getISOString(
        this.selectedAppointmentSlot.date,
        this.selectedAppointmentSlot.time
      ),
    };
    if (this.newAppointmentNote) {
      dto.note = this.newAppointmentNote;
    }
    this.userService.createAppoinment(dto).subscribe({
      next: (resp) => {
        this.userService
          .getAppointments({})
          .pipe(map((res) => res['data']))
          .subscribe((appointments_list) => {
            this.allAppointments = appointments_list
              ? structuredClone(
                appointments_list
                  .map((a) => { return { ...a, date: new Date(a.appointment_dt) } })
                  .map((a) => { return { ...a, time: this.storeService.getLocalTimeString(a.date) } })
                  .sort((a, b) => {
                    if (Number(a.time.slice(0, 2)) > Number(b.time.slice(0, 2))) return 1;
                    return -1;
                  })
              )
              : [];
            this.patientAppointments = this.allAppointments.filter(
              (a) => a.patient_id === this.patientId
            );
          });
        this.translate
          .get('patientDetails.appointment.createSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      error: (err) => {
        this.translate
          .get('patientDetails.appointment.createError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
    });
    this.selectedAppointmentSlot = null;
    this.newAppointmentNote = '';
    this.appointmentDateSelected = false;
  }

  openAppointmentModal(appointment, modal) {
    this.appointmentDateSelected = false;
    this.appointmentNoteEdited = false;
    this.selectedAppointment = {
      ...appointment,
      formated_date: DateTime.fromISO(appointment.appointment_dt).toFormat(
        'yyyy-MM-dd'
      ),
      formated_time: DateTime.fromISO(appointment.appointment_dt).toFormat(
        'HH:mm'
      ),
    };
    this.modalService.open(modal, { size: 'lg' });
  }
  patientVersions: any = [];
  selectedVersion: any;
  onSelectedVersionChanged(v) {
    this.selectedVersion = v.title;
    this.patientDetails = v;
    this.patientDetails.personal_information = { ...this.orginalPatientDetails.personal_information, ...this.patientDetails.personal_information }
    transformOnboardingFormData(JSON.parse(JSON.stringify(this.orginalCards)), this.locale, this.translate, v)
      .then(cards => {
        this.allCards = cards
      })
  }
  getPatientVersions() {
    this.storeService
      .getPatientVersions(this.patientId)
      .subscribe(({ data }) => {
        this.selectedVersion = '';
        this.patientVersions = []

        this.translate
          .get(['generalTerms.createdAt', 'generalTerms.editedAt', 'generalTerms.latestVersion'])
          .subscribe((t) => {
            this.patientVersions = []

            if (data.length > 1)
              data = data.sort((a, b) =>
                DateTime.fromISO(b.created_at).toMillis() - DateTime.fromISO(a.created_at).toMillis()
              );
            if (data.length > 0) {
              const initVersion = data.splice(-1, 1)[0]
              if (data.length > 0) {
                data.forEach(v => {
                  this.patientVersions.unshift(
                    {
                      ...v,
                      title:
                        t['generalTerms.editedAt'] + ' ' + DateTime.fromISO(v.updated_at).toFormat('dd-MM-yyyy'),
                    }
                  )
                })

              }
              let creational_date = initVersion.original_created_at;
              if (!creational_date)
                creational_date = initVersion.updated_at;
              const formated_creational_date = DateTime.fromISO(creational_date).toFormat('dd-MM-yyyy')
              this.patientVersions.push({
                ...initVersion, title: t['generalTerms.createdAt'] + ' ' + formated_creational_date
              })
            }
            this.patientVersions.unshift({
              ...this.orginalPatientDetails,
              title: t['generalTerms.latestVersion'],
            });
            this.selectedVersion = t['generalTerms.latestVersion']
          });
      });
  }
  onCancelEditing() {
    this.edit = !this.edit;
    this.cancelEditPersonalInfo();
    this.topSection.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
  saving: boolean = false;
  onSomethingChanged(change) {

    this.modifiedPatientInfo = { ...this.modifiedPatientInfo, ...change };
  }

  updateAppointment() {
    const dto: any = {
      appointment_id: this.selectedAppointment._id,
      update_data: {
        appointment_dt: this.getISOString(
          this.selectedAppointmentSlot.date,
          this.selectedAppointmentSlot.time
        ),
      },
    };
    if (this.appointmentNoteEdited) {
      dto.update_data.note = this.selectedAppointment.note;
    }
    this.userService.updateAppointment(dto).subscribe({
      next: () => {
        this.userService
          .getAppointments({})
          .pipe(map((res) => res['data']))
          .subscribe((appointments_list) => {
            this.allAppointments = appointments_list
              ? structuredClone(
                appointments_list
                  .map((a) => { return { ...a, date: new Date(a.appointment_dt) } })
                  .map((a) => { return { ...a, time: this.storeService.getLocalTimeString(a.date) } })
                  .sort((a, b) => {
                    if (Number(a.time.slice(0, 2)) > Number(b.time.slice(0, 2))) return 1;
                    return -1;
                  })
              )
              : [];
            this.patientAppointments = this.allAppointments.filter(
              (a) => a.patient_id === this.patientId
            );
          });
        this.translate
          .get('patientDetails.appointment.updateSuccess')
          .subscribe((res: string) => {
            this.toastr.success(res);
          });
      },
      error: () => {
        this.translate
          .get('patientDetails.appointment.updateError')
          .subscribe((res: string) => {
            this.toastr.error(res);
          });
      },
      complete: () => {
        this.selectedAppointmentSlot = null;
        this.newAppointmentNote = '';
      }
    });
  }

  cancelAppointment() {
    this.userService
      .deleteAppointment({ appointment_id: this.selectedAppointment._id })
      .subscribe({
        next: () => {
          this.patientAppointments = this.patientAppointments.filter(
            (a) => a._id !== this.selectedAppointment._id
          );
          this.allAppointments = this.allAppointments.filter(
            (a) => a._id !== this.selectedAppointment._id
          );
          this.translate
            .get('patientDetails.appointment.cancelSuccess')
            .subscribe((res: string) => {
              this.toastr.success(res);
            });
        },
        error: () => {
          this.translate
            .get('patientDetails.appointment.cancelError')
            .subscribe((res: string) => {
              this.toastr.error(res);
            });
        },
      });
  }

  patientResetPassword() {
    const requestData = {
      email: this.patientDetails.personal_information.email,
    };
    this.generalService.sendPasswordResetEmail(requestData).subscribe(() => { });
  }
  updateExerciseHoldDuration($event, exercise) {
    if (exercise.actions.length > 3) {
      exercise.actions[3].duration = Number($event.target.value);
    }
  }

  openChangeAccessModal(content) {
    this.newUserAccessLevel = this.patientDetails.user_access;
    this.modalService.open(content, { centered: true });
  }

  changeAccess() {
    if (this.newUserAccessLevel !== this.patientDetails.user_access) {
      const requestData = {
        patient_uid: this.patientId,
        user_access: this.newUserAccessLevel
      };
      this.storeService.changePatientAccessLevel(requestData).subscribe(() => {
        this.patientDetails.user_access = this.newUserAccessLevel;
      })
    }
  }
}
