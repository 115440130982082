import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, forkJoin, of, throwError } from 'rxjs';
import { IPatient } from '../types';
import { Http2Service } from './http2.service';
import {
  getDateInString,
  tryConvertBirthdateStringToDate,
} from '@helpers/helper';
import { catchError, map, tap } from 'rxjs/operators';
import {
  IPatientDashboardData,
  IPatientDetailsDashboardData,
  IPhysioDashboardData,
} from '../resolvers';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  searches$: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  updatesSearches(searches) {
    this.searches$.next(searches)
  }
  refreshPatientsList() {
    this.patients_list.next([]);
    (this.getPatientsList() as Observable<IPhysioDashboardData>).subscribe(
      () => {
        (this.getAllPatientsProgramSummary() as Observable<IPhysioDashboardData>).subscribe(
          () => {
            this.mergePatientsWithProgramSummary();
            this._physio_dashboard_data$.next({
              ...this._physio_dashboard_data$.value,
              patients: this.patients_list.value,
              selectedExercise: null,
            });
          }
        )
      }
    );
  }

  displaySpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  createPatientProgram_DataResolver(patiendId: string): any {
    this.displaySpinner.next(true);
    this.refreshData$.next(true);
    return forkJoin({
      patientDetails: this.getUserDetails(patiendId),
      allExercisePrograms: this.getPrograms(patiendId),
      activeTreatmentDetails: this.getTreatmentProgress(patiendId),
      performanceOverview: this.getPatientExerciseOverview({
        patient_uid: patiendId,
      }),
      activeDays: this.getActiveDays(patiendId),
      longestStreak: this.getLongestStreak(patiendId),
      allExercises: this.getAllExercises(),
      programTemplates: this.getProgramTemplates(),
    }).pipe(
      tap((d) => {
        this.displaySpinner.next(false);
        this.refreshData$.next(false);
      })
    );
  }
  patients_dashboards_data: BehaviorSubject<any> = new BehaviorSubject<any>({});
  getPatientDetailsDashboardDataResolver(
    patientId: string
  ):
    | IPatientDetailsDashboardData
    | Observable<IPatientDetailsDashboardData>
    | Promise<IPatientDetailsDashboardData> {
    this.displaySpinner.next(true);
    let requests: any = {};
    if (this.auth.me.role == 'physiotherapist') {
      if (
        this.patients_dashboards_data.value[patientId] &&
        this.refreshData$.value == false
      ) {
        this.displaySpinner.next(false);
        return this.patients_dashboards_data.value[patientId];
      }
    }
    requests = {
      patientsList: this.getPatientsList(),
      patientDetails: this.getUserDetails(patientId),
      onBoardingForm: this.getPatientOnBoarding(patientId),
      exercisesList: this.getAllExercises(),
      allExercisePrograms: this.getPrograms(patientId),
      activeTreatmentSummary: this.getTreatmentProgress(patientId),
      appointments: this.getAppointments({}),
      performanceOverview: this.getPatientExerciseOverview({
        patient_uid: patientId,
      }),
      activeDays: this.getActiveDays(patientId),
      longestStreak: this.getLongestStreak(patientId),
    };

    return forkJoin(requests)
      .pipe(
        catchError(e => {
          if (e.error == "patientNotFound") this.router.navigate(["404"])
          return throwError(e)
        })
      )
      .pipe(
        tap((data) => {
          const updatedValue: any = { ...this.patients_dashboards_data.value };
          updatedValue[patientId] = data;
          this.patients_dashboards_data.next(updatedValue);
        })
      )
      .pipe(
        tap((data) => {
          this.displaySpinner.next(false);
          this.refreshData$.next(false);
        })
      );
  }
  getPhysioDashboardData(
    patientId: string | undefined,
    test_exercise_id: string | undefined
  ):
    | IPhysioDashboardData
    | Observable<IPhysioDashboardData>
    | Promise<IPhysioDashboardData> {
    this.displaySpinner.next(true);
    if (this._physio_dashboard_data$?.value) {
      const selectedExercise = test_exercise_id ? this._physio_dashboard_data$.value.exercises.find(e => e._id == test_exercise_id) : undefined;
      this._physio_dashboard_data$.next({ ...this._physio_dashboard_data$.value, selectedExercise })
      this.displaySpinner.next(false)
      return this._physio_dashboard_data$.value
    }
    return forkJoin({
      patients: this.getPatientsList(),
      summary: this.getAllPatientsProgramSummary(),
      exercises: this.getAllExercises(),
    })
      .pipe(
        map((data) => {
          this.mergePatientsWithProgramSummary();
          return {
            ...data,
            patients: this.patients_list.value,
            selectedExercise: test_exercise_id
              ? data.exercises.find((e) => e.id == test_exercise_id)
              : undefined,
          };
        })
      )
      .pipe(
        tap((data) => {
          setTimeout(() => {
            this.displaySpinner.next(false);
          }, 500);
          this._physio_dashboard_data$.next(data);
        })
      );
  }
  getExerciseById(id: string) {
    return this.http.fetch('get_exercise_by_id', { exercise_id: id });
  }
  getSurveyQuestions(survey_type: string) {
    return this.http.fetch('get_survey', { survey_type });
  }
  getPatientVersions(patient_uid: string) {
    return this.http.fetch('get_patient_versions', { patient_uid });
  }
  getPatientDashboardData():
    | IPatientDashboardData
    | Observable<IPatientDashboardData>
    | Promise<IPatientDashboardData> {
    const id = this.auth.me.id;
    this.displaySpinner.next(true);

    return forkJoin({
      allExercisePrograms: this.getPrograms(id),
      userDetails: of(this.auth.me),
      activeTreatmentDetails: this.getTreatmentProgress(id),
      beforeSurvey: this.getSurveyQuestions('before'),
      afterSurvey: this.getSurveyQuestions('after'),
      tasks: this.getUserTasks({ patient_uid: id }),
      appointments: this.getAppointments({ patient_uid: id }),
    }).pipe(
      tap((d) => {
        this.displaySpinner.next(false);
      })
    );
  }
  private _physio_dashboard_data$: BehaviorSubject<IPhysioDashboardData> =
    new BehaviorSubject<IPhysioDashboardData>(null);
  get physio_dashboard_data$() {
    return this._physio_dashboard_data$;
  }
  private patients_programs: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  private patients_active_days: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  private patients_longest_streak: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  private program_templates: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  private patients_list: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  private patients_programs_summaries: BehaviorSubject<any[]> =
    new BehaviorSubject<any[]>([]);
  private exercises_list: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  constructor(private http: Http2Service, private auth: AuthService, private router: Router) { }
  onLogout() {
    this.displaySpinner.next(true);
    this.patients_list.next([]);
    this.patients_programs_summaries.next([]);
    this.patients_programs.next({});
    this.patients_active_days.next({});
    this.patients_longest_streak.next({});
    this.program_templates.next([]);
    this.exercises_list.next([]);
    this._physio_dashboard_data$.next(null);

    setTimeout(() => {
      this.displaySpinner.next(false);
    }, 1000);
  }
  getUserDetails(id?: string) {
    if (!id) {
      return of(this.auth.authenticatedUser.getValue());
    }
    if (id) {
      try {
        const founded = this.patients_list.getValue()?.find((p) => p.id == id);
        if (founded && founded.details) return of(founded?.details);
      } catch (error) { }
    }
    const reqBody: any = id ? { patient_uid: id } : {};
    return this.http.fetch('get_user_details', reqBody).pipe(
      tap((val) => {
        const plist = this.patients_list?.getValue() ?? [];
        this.patients_list.next([
          ...(plist.map((p) => {
            if (p.id == id) {
              return { ...p, details: val };
            }
            return { ...p };
          }) as Array<IPatient>),
        ]);
      }), catchError(err => {
        return throwError(err)
      })
    )
  }
  getPatientsList() {
    const reqBody: any = {};
    if (this.patients_list.getValue()?.length > 0)
      return this.patients_list?.value;
    return this.http
      .fetch('get_patient_list', reqBody)
      .pipe(
        map((res) => res?.data),
        map((list: any) => {
          return list.map((p) => {
            p.id = (p as any)?.uid;
            delete p.uid;
            p.created_at =
              p?.created_at?.toString()?.length > 0
                ? p?.created_at
                : new Date();

            return {
              ...p,
              birthdate: tryConvertBirthdateStringToDate(p.birthdate),
              __birthdate: p.birthdate,
              created_at: new Date(p?.created_at),
            };
          });
        })
      )
      .pipe(
        tap((list) => {
          this.patients_list.next(list);
        })
      );
  }
  private mergePatientsWithProgramSummary() {
    if (
      this.patients_list.getValue()?.length > 0 &&
      this.patients_programs_summaries.getValue()?.length > 0
    ) {
      const plist = this.patients_list.getValue();
      this.patients_list.next(
        plist.map((p) => {
          const ps = this.patients_programs_summaries.value.find(
            (i) => i.patient_uid == p.id
          );

          if (ps) {

            return {
              ...p,
              program_summary: {
                days_left: ps.days_left,
                total_days: ps.total_days,
                done_percentage: ps.total_days > 0 ? (((ps.total_days - ps.days_left) / ps.total_days) * 100) : 100
              },
            };
          }
          return {
            ...p,
            program_summary: {
              days_left: 0,
              total_days: 0,
              done_percentage: 100,
            },
          };
        })
      );
      return this.patients_list.value;
    }
  }
  getTreatmentProgress(patientId: string) {
    const reqBody: any = {
      patient_uid: patientId,
      current_date: getDateInString(new Date()),
    };
    return this.http
      .fetch('get_exercise_program_completion_summary', reqBody)
      .pipe(map((res) => res?.data));
  }
  getAllPatientsProgramSummary() {
    if (this.patients_programs_summaries?.getValue()?.length > 0) {
      return of(this.patients_programs_summaries);
    }
    const reqBody = {
      current_date: getDateInString(new Date()),
    };
    return this.http
      .fetch('get_all_patients_exercise_program_completion_summaries', reqBody)
      .pipe(map((res) => res?.data))
      .pipe(
        map((list) => {
          return list.map((s) => {
            return { ...s };
          });
        })
      )
      .pipe(
        tap((list) => {
          this.patients_programs_summaries.next(list);
        })
      );
  }
  setUserDetails(reqBody: any) {
    return this.http.fetch('set_user_details', reqBody).pipe();
  }
  getPrograms(patientId: any, force: boolean = false) {
    const all = this.patients_programs.getValue();
    if (all[patientId] && !force && this.refreshData$.value == false) {
      return of(all[patientId]);
    }
    return this.http
      .fetch('get_exercise_program', {
        current_date: getDateInString(new Date()),
        patient_uid: patientId,
      })
      .pipe(map((res) => res?.data))
      .pipe(
        tap((p) => {
          const all = this.patients_programs.getValue();
          all[patientId] = p;
          if (p.length > 0) {
            p.map(pro => {
              pro.exercises = pro.exercises.map(e => {
                return {
                  ...e,
                  initial_pose: {
                    ...e.initial_pose,
                    check: e.initial_pose.check == true || typeof e.initial_pose.check == 'undefined'
                  }
                }
              })
              return pro;
            })
          }
          this.patients_programs.next({ ...all });
        })
      );
  }
  getProgramTemplates() {
    if (
      this.program_templates.getValue()?.length > 0 &&
      this.refreshData$.value == false
    ) {
      return of([...this.program_templates.value]);
    }
    return this.http
      .fetch('get_exercise_program_template', {})
      .pipe(map((res) => res?.data))
      .pipe(
        tap((data) => {
          const list = data.map((t) => {
            return { ...t, expanded: false, edited: false };
          });
          this.program_templates.next(list);
        })
      );
  }
  getAllExercises(short_version: boolean = false) {
    if (this.exercises_list.getValue()?.length > 0) {
      return of(this.exercises_list.getValue());
    }
    return this.http
      .fetch(short_version ? 'get_all_exercises_names_and_categories' : 'get_all_exercises', {})
      .pipe(map((res) => res?.data))
      .pipe(
        map((list) => {
          return list.filter((e) => !e.disabled);
        })
      )
      .pipe(
        map((list) => {
          return list.map((e: any) => {
            if (e.initial_pose)
              return {
                ...e, initial_pose: {
                  ...e.initial_pose,
                  check: e.initial_pose.check == true || typeof e.initial_pose.check == 'undefined'
                }
              };

            return { ...e, };
          });
        })
      )
      .pipe(
        tap((list) => {
          list.sort((a, b) => {
            const aInitialPose = a.initial_pose.pose.split('_')[1];
            const bInitialPose = b.initial_pose.pose.split('_')[1];
            if (['breathing', 'breathing_time', 'relaxation'].includes(a.category) || ['breathing', 'breathing_time', 'relaxation'].includes(b.category)) {
              if (!['breathing', 'breathing_time', 'relaxation'].includes(a.category)) {
                return 1;
              }
              if (!['breathing', 'breathing_time', 'relaxation'].includes(b.category)) {
                return -1;
              }
              if (bInitialPose === 'sit' && aInitialPose !== 'sit') {
                return 1;
              }
              if (bInitialPose !== 'sit' && aInitialPose === 'sit') {
                return -1;
              }
              return 0;
            }
            if (aInitialPose === 'sit' || bInitialPose === 'sit') {
              if (aInitialPose !== 'sit') {
                return 1;
              }
              if (bInitialPose !== 'sit') {
                return -1;
              }
              return 0;
            }
            return 0;
          });
        })
      )
      .pipe(
        tap((list) => {
          if (short_version == false)
            this.exercises_list.next(list);
        })
      );
  }
  updatePersonalInfo(reqBody: any) {
    return this.http.fetch('update_user_details', reqBody).pipe();
  }
  deleteUser(reqBody) {
    return this.http.fetch('delete_user', reqBody).pipe();
  }
  getUserTasks(reqBody) {
    return this.http.fetch('get_user_tasks', reqBody).pipe();
  }
  createTask(reqBody) {
    return this.http.fetch('add_new_task', reqBody).pipe();
  }
  updateTask(reqBody) {
    return this.http.fetch('update_task', reqBody).pipe();
  }
  deleteTask(reqBody) {
    return this.http.fetch('delete_task', reqBody).pipe();
  }
  changeUserLanguage(reqBody) {
    return this.http.fetch('change_language', reqBody).pipe();
  }
  changePatientAccessLevel(reqBody) {
    return this.http.fetch('switch_access_level', reqBody).pipe();
  }
  getSurveyResultsOverview(reqBody) {
    return this.http.fetch('get_survey_results_by_time', reqBody).pipe();
  }
  getStepCountsByRange(reqBody) {
    return this.http
      .fetch('get_patient_step_count_by_date_range', reqBody)
      .pipe();
  }
  getOnBoarding() {
    return this.http
      .fetch('get_onboarding', { })
      .pipe(map(res => res.data));
  }
  getPatientOnBoarding(id: string = null) {
    return this.http
      .fetch('get_patient_onboarding_form', { patient_uid: id })
      .pipe(map(res => res.data));
  }

  getPatientExerciseOverview(reqBody) {
    return this.http
      .fetch('get_patient_exercise_performance_overview', reqBody)
      .pipe(
        map((res) => res?.data),
        catchError((err) => {
          return of({});
        })
      );
  }
  getActiveDays(patientId: string) {
    const all = this.patients_active_days.getValue();
    if (all[patientId]) {
      return of(all[patientId]);
    }
    const reqBody = {
      patient_uid: patientId,
      current_date: getDateInString(new Date()),
    };
    return this.http
      .fetch('get_active_days', reqBody)
      .pipe(map((res) => res?.data))
      .pipe(
        tap((data) => {
          const all = this.patients_active_days.getValue();
          all[patientId] = data;
          this.patients_active_days.next({ ...all });
        })
      );
  }
  getLongestStreak(patientId: string) {
    const all = this.patients_longest_streak.getValue();
    if (all[patientId]) {
      return of(all[patientId]);
    }
    const reqBody = {
      patient_uid: patientId,
      current_date: getDateInString(new Date()),
    };
    return this.http
      .fetch('get_streak', reqBody)
      .pipe(map((res) => res?.data))
      .pipe(
        tap((data) => {
          all[patientId] = data;
          this.patients_longest_streak.next({ ...all });
        })
      );
  }
  getAppointments(reqBody) {
    return this.http.fetch('retrieve_appointment_data', reqBody)
      .pipe(map(res => res?.data))
      .pipe(map(aps => {
        return aps
          ? aps
            .map((a) => { return { ...a, date: new Date(a.appointment_dt) } })
            .map((a) => { return { ...a, time: this.getLocalTimeString(a.date) } })
            .sort((a, b) => {
              if (Number(a.time.slice(0, 2)) > Number(b.time.slice(0, 2))) return 1;
              return -1;
            })
          : [];
      }));
  }

  getLocalTimeString(date) {
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    return hours + ':' + minutes;
  }

  createAppoinment(reqBody) {
    return this.http.fetch('create_appointment', reqBody).pipe();
  }

  updateAppointment(reqBody) {
    return this.http.fetch('update_appointment', reqBody).pipe();
  }

  deleteAppointment(reqBody) {
    return this.http.fetch('delete_appointment', reqBody).pipe();
  }

  clearSelectedExercise() {
    this._physio_dashboard_data$.next({
      ...this._physio_dashboard_data$.value,
      selectedExercise: null,
    });
  }

  getExerciseCategory(exerciseId: string) {
    if (this.exercises_list.getValue()?.length > 0) {
      const exercises = this.exercises_list.getValue();
      for (let i = 0; i < this.exercises_list.getValue().length; i++) {
        if (exercises[i]._id === exerciseId) {
          return exercises[i].category;
        }
      }
      return '';
    }
  }
}
